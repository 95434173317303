/**
 * Тип события чата.
 */
type ChatEventType = 'start' | 'close' | 'send';

/**
 * Обработчик события чата.
 */
type ChatEventHandler = () => void;

/**
 * Содержит методы для управления со встроенным чатом.
 */
export class ChatApi {
  /**
   * Единственный экземпляр синглтона.
   */
  protected static instance: ChatApi | undefined;

  /**
   * Возвращает экземпляр синглтона.
   */
  public static getInstance() {
    return this.instance || (this.instance = new this());
  }

  /**
   * Текст ошибки, возникающей, когда виджет чата не найден.
   */
  static readonly WIDGET_NOT_EXISTS =
    'У вас установлен блокировщик рекламы, который не позволяет отображать чат службы поддержки. Пожалуйста, отключите данный сервис, либо добавьте эту страницу в исключения.';
  
  /**
   * Экземпляр API.
   */
  protected webim: any;

  /**
   * Создает экземпляр класса.
   */
  protected constructor() {
    if (typeof window === 'undefined') {
      return this;
    }

    // @ts-ignore
    this.webim = window.webim;

    if (this.webim == null) {
      return this;
    }

    window.addEventListener('click', this.handleClick);
  }

  /**
   * Открывает диалог чата.
   */
  public start() {
    if (typeof window === 'undefined') {
      return;
    }

    if (this.webim == null) {
      throw new Error(ChatApi.WIDGET_NOT_EXISTS);
    }

    this.webim.api.chat.start();
    this.dispatch('start');
  }

  /**
   * Закрывает диалог чата.
   */
  public close() {
    if (typeof window === 'undefined') {
      return;
    }

    if (this.webim == null) {
      throw new Error(ChatApi.WIDGET_NOT_EXISTS);
    }

    this.webim.api.chat.close();
    this.dispatch('close');
  }

  /**
   * Коллекция обработчиков событий чата.
   */
  protected handlers = new Map<ChatEventType, ChatEventHandler[]>();

  /**
   * Добавляет обработчик событию чата.
   * @param event Тип события чата.
   * @param handler Обработчик события чата.
   */
  public on(event: ChatEventType, handler: ChatEventHandler) {
    if (!this.handlers.has(event)) {
      this.handlers.set(event, []);
    }

    const list = this.handlers.get(event);
    
    if (list.includes(handler)) {
      return;
    }

    list.push(handler);
  }

  /**
   * Удаляет обработчик события чата.
   * @param event Тип события чата.
   * @param handler Обработчик события чата.
   */
  public off(event: ChatEventType, handler: ChatEventHandler) {
    if (!this.handlers.has(event)) {
      return;
    }

    const list = this.handlers.get(event);
    const index = list.indexOf(handler);

    if (index < 0) {
      return;
    }

    list.splice(index, 1);
  }

  /**
   * Вызывает обработчики события чата.
   * @param event Тип события.
   */
  protected dispatch(event: ChatEventType) {
    if (!this.handlers.has(event)) {
      return;
    }

    const handlers = this.handlers.get(event);
    handlers.forEach(handler => handler());
  }

  /**
   * Обрабатывает нажатие на кнопку чата.
   */
  protected handleStartButtonClick() {
    this.dispatch('start');
  }

  /**
   * Обрабатывает нажатие на кнопку закрытия чата.
   */
  protected handleCloseButtonClick() {
    this.dispatch('close');
  }

  /**
   * Обрабатывает нажатие на кнопку отправки сообщения чата.
   */
  protected handleSendButtonClick() {
    this.dispatch('send');
  }

  /**
   * Обрабатывает нажатия на все элементы сайта.
   */
  protected handleClick = (event: MouseEvent) => {
    if (typeof window === 'undefined') {
      return;
    }

    let element: HTMLElement = event.target as HTMLElement;

    while (element && element !== window.document.body) {
      if (element.classList.contains('webim-html-button-main')) {
        this.handleStartButtonClick();
        return;
      }

      if (
        element.hasAttribute('data-webim-request')
        && element.getAttribute('data-webim-request') === 'service:chat:close'
      ) {
        this.handleCloseButtonClick();
        return;
      }

      if (element.classList.contains('webim-btn-send')) {
        this.handleSendButtonClick();
        return;
      }

      if (element.classList.contains('webim-send-button')) {
        this.handleSendButtonClick();
        return;
      }

      element = element.parentElement;
    }
  };
}