import {
  MetricService,
  CookieKey,
  SessionEndedValues,
  EventType,
  StatusChangedValues,
} from '@frontend/metric-service';
import Cookie from 'utils/Cookie';
import ClientStore from 'store/Client';

import { getCurrentUserGroup, ABTestNameClickhouse } from 'store/ABTest';

/**
 * Сервис для работы с API MetricService.
 * @see https://confluence.devim.team/pages/viewpage.action?pageId=85108531
 */
export class Service {
  /**
   * Последний роут пользователя.
   */
  private static lastRout: string = undefined;

  /**
   * Инициализация сервиса.
   * @param config Параметры инициализации сервиса.
   */
  public static initialize(config) {
    MetricService.init({ ...config, verbose: process.env.NODE_ENV !== 'production' });
    this.setUserTypeCookie();
  }

  /**
   * Устанавливает адрес страницы с которой пользователь перешел на текущую
   * страницу.
   * @param pathname текущий адрес страницы.
   */
  public static setPageFromCookie(pathname: string) {
    if (!pathname) {
      return;
    }

    const rout = this.lastRout;

    if (rout) {
      Cookie.set(CookieKey.PAGE_FROM, rout);
    }

    this.lastRout = pathname;
  }

  /**
   * Удаляет куки 'User Type'.
   */
  public static deleteUserTypeCookie() {
    Cookie.delete(CookieKey.USER_TYPE);
  }

  /**
   * Устанавливает куки 'User Type'.
   * @param forsed Принудительно установить куки.
   */
  public static setUserTypeCookie(forsed?: boolean) {
    const type = Cookie.get(CookieKey.USER_TYPE);

    if (type && !forsed) {
      return;
    }

    Cookie.set(CookieKey.USER_TYPE, ClientStore.clientType);
  }

  /**
   * Удаляет куки для AB теста.
   */
  public static deleteABTestCookie() {
    Cookie.delete(CookieKey.AB_TEST_NAME);
    Cookie.delete(CookieKey.AB_TEST_GROUP);
  }

  /**
   * Устанавливает куки для AB теста.
   */
  public static setABTestCookie() {
    const userType = Cookie.get(CookieKey.USER_TYPE) as 'first' | 'repeat';
    const group = getCurrentUserGroup();

    if (group && userType && ABTestNameClickhouse) {
      Cookie.set(CookieKey.AB_TEST_GROUP, group);
      Cookie.set(CookieKey.AB_TEST_NAME, ABTestNameClickhouse);
    }
  }

  /**
   * Отправляет событие перехода на страницу.
   * @param pathname текущий адрес страницы.
   */
  public static onPageEnter(pathname: string) {
    this.setUserTypeCookie(true);
    MetricService.onPageEnter(pathname);
  }

  /**
   * Отправляет cобытие нажатия на кнопку.
   * @param title название кнопки.
   */
  public static onButtonPressed(title: string) {
    this.setUserTypeCookie(true);
    MetricService.onButtonPressed(title);
  }

  /**
   * Отправляет cобытие показанной на экране ошибки.
   * @param text текст сообщения.
   */
  public static onErrorShown(text: string) {
    this.setUserTypeCookie(true);
    MetricService.onErrorShown(text);
  }

  /**
   * Отправляет cобытие ошибки бекенда.
   * @param code код ошибки.
   */
  public static onErrorBackend(code: string | number) {
    this.setUserTypeCookie(true);
    MetricService.onErrorBackend(String(code));
  }

  /**
   * Отправляет cобытие показа документа.
   * @param title название документа.
   */
  public static onDocumentShown(title: string) {
    this.setUserTypeCookie(true);
    MetricService.onDocumentShown(title);
  }

  /**
   * Отправляет cобытие открытия документа.
   * @param title название документа.
   */
  public static onDocumentOpened(title: string) {
    this.setUserTypeCookie(true);
    MetricService.onDocumentOpened(title);
  }

  /**
   * Отправляет cобытие загрузки файла.
   * @param title название файла.
   */
  public static onDocumentUploaded(title: string) {
    this.setUserTypeCookie(true);
    MetricService.onDocumentUploaded(title);
  }

  /**
   * Отправляет cобытие показа всплывающего окна.
   * @param title название окна.
   */
  public static onPopupShown(title: string) {
    this.setUserTypeCookie(true);
    MetricService.onPopupShown(title);
  }

  /** Отправляет cобытие показа банера.
   * @param title название баннера.
   */
  public static onBannerShown(title: string) {
    this.setUserTypeCookie(true);
    MetricService.onBannerShown(title);
  }

  /**
   * Отправляет cобытие заполнения поля.
   * @param title название поля.
   * @param type как поле было заполнено.
   */
  public static onFieldFilled(title: string, type?: EventType) {
    this.setUserTypeCookie(true);
    MetricService.onFieldFilled(title, type);
  }

  /*
   * Отправляет cобытие завершенной сессии.
   * @param type тип завершенной сессии.
   */
  public static async onSessionEnded(type: SessionEndedValues) {
    await MetricService.onSessionEnded(type);
  }

  /**
   * Отправляет cобытие включения чекбокса.
   * @param title название поля.
   * @param type как поле было заполнено.
   */
  public static onCheckboxChecked(title: string, type?: EventType) {
    this.setUserTypeCookie(true);
    MetricService.onCheckboxChecked(title, type);
  }

  /**
   * Отправляет cобытие выключения чекбокса.
   * @param title название поля.
   */
  public static onCheckboxUnchecked(title: string) {
    this.setUserTypeCookie(true);
    MetricService.onCheckboxUnchecked(title);
  }

  /**
   * Отправляет cобытие изменения статуса сущности.
   * @param title название статуса.
   */
  public static onStatusChanged(title: StatusChangedValues) {
    this.setUserTypeCookie(true);
    MetricService.onStatusChanged(title);
  }
}
