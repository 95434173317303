import React from 'react';

const Coins = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" {...props}>
      <circle fill="#F2F2FB" cx="24" cy="24" r="24" />
      <path
        d="M20 23c-3.046 0-6-1.112-6-3.003C14 18.107 16.954 17 20 17c.017 0-.027 6 0 6zm7 2c-3.046 0-6-1.229-6-2.745V20c.521 1.246 3.341 2 6 2v3zm.002 0v-3c2.658 0 5.479-.754 6-2v2.255c0 1.516-2.954 2.745-6 2.745zM27 29c-3.046 0-6-1.229-6-2.745V24c.521 1.246 3.341 2 6 2v3zm.002 0v-3c2.658 0 5.479-.754 6-2v2.255c0 1.516-2.954 2.745-6 2.745zM27 33c-3.046 0-6-1.229-6-2.745V28c.521 1.246 3.341 2 6 2v3zm.002 0v-3c2.658 0 5.479-.754 6-2v2.255c0 1.516-2.954 2.745-6 2.745zM20 31c-3.046 0-6-1.229-6-2.745V26c.521 1.246 3.341 2.202 6 2.202.004 0-.017 2.798 0 2.798zm0-4c-3.046 0-6-1.229-6-2.745V22c.521 1.246 3.341 2.202 6 2.202.004 0-.017 2.798 0 2.798zm7-6c-3.314 0-6-1.343-6-3s2.686-3 6-3 6 1.343 6 3-2.686 3-6 3z"
        fill="#A2A2B2"
      />
    </svg>
  );
};

export default Coins;
