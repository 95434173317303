import React from 'react';

const Info = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <path
        fill="#787891"
        d="M24 42c9.9411255 0 18-8.0588745 18-18S33.9411255 6 24 6 6 14.0588745 6 24s8.0588745 18 18 18zm0 4C11.8497355 46 2 36.1502645 2 24S11.8497355 2 24 2s22 9.8497355 22 22-9.8497355 22-22 22zm2-11v-4h-4v4h4zm0-7V13h-4v15h4z"
      />
    </svg>
  );
};

export default Info;
