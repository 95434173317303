import React from 'react';

const Events = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 17v1a3 3 0 0 1-6 0v-1H3c2-2.899 3-4.89 3-5.973V9a6 6 0 1 1 12 0v2.027C18 12.11 19 14.1 21 17h-6zm2.368-2C16.455 13.362 16 12.077 16 11.027V9a4 4 0 1 0-8 0v2.027c0 1.05-.455 2.335-1.368 3.973h10.736zM11 18a1 1 0 0 0 2 0v-1h-2v1z" />
    </svg>
  );
};

export default Events;
