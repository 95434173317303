import Model from './Model';

export class CreditCardModel extends Model<CreditCardModel> {
  public backgroundColorFirst = '';

  public backgroundColorSecond = '';

  public backgroundLightness = '';

  public expirationMonth = 0;

  public expirationYear = 0;

  public id = 0;

  public logo = '';

  public logoPath = '';

  public logoStyle = '';

  public nameEng = '';

  public nameRus = '';

  public number = '';

  public operationType = 0;

  public textColor = '';

  public url = '';

  public payoutBlockedBy = '';

  public constructor(data?: Partial<CreditCardModel>) {
    super();
    this.init(data);
  }
}
