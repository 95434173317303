/**
 * Runs locally
 */
export const IS_LOCAL = process.env.LOCAL === '1';
/**
 * Runs on test server
 */

export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
/**
 * Runs on production server
 */

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
/**
 * Runs locally or on test server
 */

export const IS_UNDER_DEVELOPMENT = IS_LOCAL || IS_DEVELOPMENT;
/**
 * Remote API host(https://example.com)
 */

export const BACKEND_HOST = IS_LOCAL ? process.env.BACKEND_HOST : import.meta.env.BACKEND_HOST;
/**
 * Public host FQDN(example.com)
 */

export const PUBLIC_DOMAIN = IS_LOCAL ? process.env.PUBLIC_DOMAIN : import.meta.env.PUBLIC_DOMAIN;
/**
 * Set-Cookie domain FQDN(example.com)
 */

export const COOKIE_DOMAIN = IS_LOCAL ? process.env.COOKIE_DOMAIN : import.meta.env.COOKIE_DOMAIN;
/**
 * Clickhouse digest path(https://example.com/api/v1.0/events/web)
 */

export const CLICKHOUSE_URL = IS_LOCAL ? process.env.CLICKHOUSE_URL : import.meta.env.CLICKHOUSE_URL;
/**
 * Clickhouse v2 digest path(https://example.com/api/v2.0/events/web)
 */

export const CLICKHOUSE_URL_V2 = IS_LOCAL ? process.env.CLICKHOUSE_URL_V2 : import.meta.env.CLICKHOUSE_URL_V2;
/**
 * Clickhouse identifier(UUID)
 */

export const CLICKHOUSE_ID = IS_LOCAL ? process.env.CLICKHOUSE_ID : import.meta.env.CLICKHOUSE_ID;
/**
 * Clickhouse client identifier(UUID)
 */

export const CLICKHOUSE_CLIENT_ID = IS_LOCAL ? process.env.CLICKHOUSE_CLIENT_ID : import.meta.env.CLICKHOUSE_CLIENT_ID;
/**
 * TIDA authentication host(https://example.com/api)
 */

export const TIDA_HOST = IS_LOCAL ? process.env.TIDA_HOST : import.meta.env.TIDA_HOST;
/**
 * TIDA authentication token(UUID)
 */

export const TIDA_TOKEN = IS_LOCAL ? process.env.TIDA_TOKEN : import.meta.env.TIDA_TOKEN;
/**
 * ESIDA authentication host(https://example.com/api)
 */

export const ESIDA_HOST = IS_LOCAL ? process.env.ESIDA_HOST : import.meta.env.ESIDA_HOST;
/**
 * ESIDA authentication token(UUID)
 */

export const ESIDA_TOKEN = IS_LOCAL ? process.env.ESIDA_TOKEN : import.meta.env.ESIDA_TOKEN;
/**
 * DADATA authentication token(UUID)
 */

export const DADATA_TOKEN = IS_LOCAL ? process.env.DADATA_TOKEN : import.meta.env.DADATA_TOKEN;
export const RECAPTCHA_KEY = IS_LOCAL ? process.env.RECAPTCHA_KEY : import.meta.env.RECAPTCHA_KEY;
export const GOOGLE_GTAG = process.env.GOOGLE_GA_MEASUREMENT_ID || undefined;
export const GOOGLE_GTM = process.env.GOOGLE_GTM_XXXX || undefined;
export const MYTARGET_ID = process.env.MYTARGET_ID ? Number(process.env.MYTARGET_ID) : undefined;
/**
 * Yandex metrika counter name
 */

export const YM_COUNTER = 'yaCounter25885646';
/**
 * Yandex metrika counter number
 */

export const YM_COUNTER_NUMBER = '25885646';
export const FIREBASE_PUBLIC_VAPID_KEY = IS_LOCAL ? process.env.FIREBASE_PUBLIC_VAPID_KEY : import.meta.env.FIREBASE_PUBLIC_VAPID_KEY;