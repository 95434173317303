import React, { Component } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import Helpers from 'utils/Helpers';

import { Wrap, Button, Placeholder, Value, InputFile } from './styled';

type Props = InferProps<typeof InputFileSimple['propTypes']> & React.ComponentProps<typeof Wrap>;

export default class InputFileSimple extends Component<Props> {
  static propTypes = {
    onLoadedEnd: PropTypes.func,
    onError: PropTypes.func,
    fileName: PropTypes.string,
    maxSize: PropTypes.number,
    accept: PropTypes.array,
  };

  static defaultProps = {
    fileName: '',
    accept: [],
  };

  handlerChange = event => {
    const { onLoadedEnd, onError, maxSize, accept } = this.props;

    const target = event.target;
    const files = target.files;
    const reader = new FileReader();

    if (files && files.length > 0) {
      const file = files[0];
      const fileType = file.name.split(/\.(?=[^\.]+$)/)[1];
      const possibleExtensions = accept.map(item => item.split('/')[1]);

      if (maxSize && file.size > Helpers.convertSizeMbToBytes(maxSize)) {
        onError && onError(`Размер файла не может быть больше ${maxSize} MB`);
        return;
      }

      if (possibleExtensions.indexOf(fileType) === -1) {
        onError && onError(`Поддерживаются файлы с расширениями: ${possibleExtensions.join(', ')}.`);
        return;
      }

      reader.onloadend = () => {
        onLoadedEnd && onLoadedEnd({
          target: target,
          src: reader.result,
          name: file.name,
          size: file.size
        });
      };

      reader.readAsDataURL(file);
    }
  };

  render() {
    const {
      onError,
      children,
      fileName,
      accept,
      ...rest
    } = this.props;

    return (
      <Wrap {...rest}>
        {fileName ? <Value>{fileName}</Value> : <Placeholder>Выберите файл</Placeholder>}
        <Button>
          {children}
          <InputFile type="file" onChange={this.handlerChange} onError={onError} accept={accept.join(',')}/>
        </Button>
      </Wrap>
    );
  }
}
