import React from 'react';
import classNames from 'classnames';
import { Service as MetricService, getChildrenText } from 'modules/common/MetricService';

type PropsWithForwardRef<T extends keyof JSX.IntrinsicElements> = Omit<
  JSX.IntrinsicElements[T],
  'ref'
> & {
  /** @deprecated FC has no ref, use forwardRef instead */
  ref?: never;
  forwardRef?: JSX.IntrinsicElements[T]['ref'];
};

export function createStyledBase<T extends keyof JSX.IntrinsicElements>(
  tag: T,
  className: string,
): React.FC<
  PropsWithForwardRef<T> & {
    /**
     * Название метрики. Если невозможно взять название метрики из
     * `children`, то рекомендуется указывать это свойство.
     */
    msTitle?: string;

    /**
     * Флаг отключает отправку метрики.
     */
    msDisabled?: boolean;
  }
> {
  return ({
    className: innerClassName,
    forwardRef,
    children,
    msTitle,
    msDisabled,
    onClick,
    ...props
  }) => {
    const metricServiceClick = (e) => {
      if (onClick) {
        if (!msDisabled) {
          const text = getChildrenText({ children, msTitle });

          MetricService.onButtonPressed(text as string);
        }
        onClick(e);
      }
    };

    return React.createElement(
      tag,
      {
        className: classNames(className, innerClassName),
        ref: forwardRef,
        onClick: metricServiceClick,
        ...props,
      },
      children,
    );
  };
}

/**
 * @param tag
 * @param className
 * @param conditional {имяФлага: названиеКласса}
 */
export function createStyledBaseConditional<
  T extends keyof JSX.IntrinsicElements,
  P extends { [key: string]: string }
>(
  tag: T,
  className: string,
  conditional: P,
): React.FC<
  PropsWithForwardRef<T> &
    Partial<{ [key in keyof P]: boolean }> & {
      /**
       * Название метрики. Если невозможно взять название метрики из
       * `children`, то рекомендуется указывать это свойство.
       */
      msTitle?: string;

      /**
       * Флаг отключает отправку метрики.
       */
      msDisabled?: boolean;
    }
> {
  return ({
    className: innerClassName,
    forwardRef,
    onClick,
    children,
    msTitle,
    msDisabled,
    ...props
  }) => {
    const metricServiceClick = (e) => {
      if (onClick) {
        if (!msDisabled) {
          const text = getChildrenText({ children, msTitle });

          MetricService.onButtonPressed(text as string);
        }
        onClick(e);
      }
    };

    const conditionalProps: Partial<{ [key in keyof P]: boolean }> = {};
    const otherProps: JSX.IntrinsicElements[T] = {};
    Object.keys(props).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(conditional, key)) {
        conditionalProps[key as keyof P] = props[key];
      } else {
        otherProps[key] = props[key];
      }
    });
    const conditionalStyles = Object.keys(conditionalProps).map((key) =>
      conditionalProps[key] ? conditional[key] : '',
    );

    return React.createElement(
      tag,
      {
        ref: forwardRef,
        className: classNames(className, innerClassName, ...conditionalStyles),
        onClick: metricServiceClick,
        ...otherProps,
      },
      children,
    );
  };
}
