import { observable, computed, action } from 'mobx';
import { StatusChangedValues } from '@frontend/metric-service';

import { TakeLoanMetricStore } from 'store/TakeLoanMetricStore';
import ApplicationApi from 'services/Application';
import LoanApi from 'services/Loan';
import Marketing from 'services/Marketing';
import Step1 from 'models/LoanApplication/Step1';
import Step2 from 'models/LoanApplication/Step2';
import Step3 from 'models/LoanApplication/Step3';
import Step1GroupBModel from 'models/LoanApplication/Step1GroupB';
import Step2GroupBModel from 'models/LoanApplication/Step2GroupB';
import Step3GroupBModel from 'models/LoanApplication/Step3GroupB';
import { SelectedLoanModel } from 'models/SelectedLoan';
import * as rpc from 'const/rpc';
import { Service as MetricService } from 'modules/common/MetricService';

import { fetch as fetchOuterLoan } from '../services/backend/TakeOuterLoan';
import Cookie from '../utils/Cookie';
import * as cookies from '../const/cookies';

class LoanApplication {
  @observable
  isDataLoaded = false;

  @observable
  isSelectedLoanLoaded = false;

  @observable
  step1 = new Step1();

  @observable
  step2 = new Step2();

  @observable
  step3 = new Step3();

  @observable
  step1B = new Step1GroupBModel();

  @observable
  step2B = new Step2GroupBModel();

  @observable
  step3B = new Step3GroupBModel();

  @observable
  esiaHighlight = false;

  @observable
  selectedLoan = new SelectedLoanModel();

  @action
  saveStep1 = (data) => {
    this.step1 = new Step1(data);
  };

  @action
  saveStep2 = (data) => {
    this.step2 = new Step2(data);
  };

  @action
  saveStep3 = (data) => {
    this.step3 = new Step3(data);
  };

  @action
  saveStep1B = (data) => {
    this.step1B = new Step1GroupBModel(data);
  };

  @action
  saveStep2B = (data) => {
    this.step2B = new Step2GroupBModel(data);
  };

  @action
  saveStep3B = (data) => {
    this.step3B = new Step3GroupBModel(data);
  };

  @action
  getValue(step: number, param: string) {
    return computed(() => {
      return this[`step${step}`].getValue(param);
    });
  }

  @action
  getValueStepB(step: number, param: string) {
    return computed(() => {
      return this[`step${step}B`].getValue(param);
    });
  }

  @action
  setSelectedLoanValue(param, value) {
    this.selectedLoan.setValue(param, value);
  }

  @action
  getSelectedLoanValue(param) {
    return computed(() => {
      return this.selectedLoan.getValue(param);
    });
  }

  @action
  getEsiaHighlight() {
    return this.esiaHighlight;
  }

  @action
  setEsiaHighlight(value: boolean) {
    this.esiaHighlight = value;
  }

  @action
  fetch = () => {
    if (!Cookie.get(cookies.OUTER_LOAN).length) {
      return ApplicationApi.getApplicationStepsRepeatD().then((response) => {
        this.step1 = new Step1(response.step1);
        this.step2 = new Step2(response.step2);
        this.step3 = new Step3(response.step3);
        // if (response && response.step3 && response.step3.creditLoad >= 1) {
        //   this.step3.isCreditLoadDisabled = true;
        // }
        this.isDataLoaded = true;
      });
    }
    return fetchOuterLoan(Cookie.get(cookies.OUTER_LOAN)).then((response) => {
      const draft = response;
      const firstStepValues = {
        lastName: draft.passportLastName,
        firstName: draft.passportFirstName,
        patronymic: draft.passportPatronymicName,
        gender: Number(draft.passportGender),
        email: draft.clientEmail,
        birthDate: draft.passportBirthDate,
        passport: `${draft.passportSeries}${draft.passportNumber}`,
        subCode: draft.passportDepartmentCode,
        issueDate: draft.passportIssueDate,
        placeOfBirth: draft.passportPlaceOfBirth,
        issueAuthority: draft.passportIssueAuthority,
      };
      const secondStepValues = {
        addressRegistration: {
          locality: draft.addressCity,
          street: draft.addressStreet,
          house: draft.addressHouse,
          flat: draft.addressApartment,
        },
        noWork: Number(draft.workNoWork) === 1,
        experience: Number(draft.workExperience),
        salaryOfficial: Number(draft.workSalaryOfficial),
        maritalStatus: draft.martialStatusStats ? Number(draft.martialStatusStats) : -1,
        familyCount: draft.martialFamilyCount ? Number(draft.martialFamilyCount) : -1,
      };
      const thirdStepValues = {
        noWork: Number(draft.workNoWork) === 1,
        experience: Number(draft.workExperience),
        salaryOfficial: Number(draft.workSalaryOfficial),
        employer: draft.workEmployer,
        employerPhone: draft.workEmployerPhone,
        position: draft.workPosition,
        salaryDate: draft.workSalaryDate ? Number(draft.workSalaryDate) : -1,
      };
      this.step1 = new Step1(firstStepValues);
      this.step2 = new Step2(secondStepValues);
      this.step3 = new Step3(thirdStepValues);
      this.isDataLoaded = true;
    });
  };

  @action
  fetchB = () => {
    return ApplicationApi.getApplicationStepsGroupD().then((response) => {
      this.step1B = new Step1GroupBModel(response.step1);
      this.step2B = new Step2GroupBModel(response.step2);
      this.step3B = new Step3GroupBModel(response.step3);
      // if (response && response.step2 && response.step2.creditLoad >= 1) {
      //   this.step2B.isCreditLoadDisabled = true;
      // }
      this.isDataLoaded = true;
    });
  };

  createLoan = (
    agreements: Record<string, boolean>,
    confirmationCode: string,
    draftId?: string,
  ) => {
    const offset = new Date().getTimezoneOffset();

    if (draftId) {
      return LoanApi.createBySelectedLoan({
        agreements,
        offset,
        confirmationCode,
        loanApplicationDraftId: draftId,
      });
    }

    return LoanApi.createBySelectedLoan({ agreements, offset, confirmationCode });
  };

  createOrUpdateDraft = (id, fields) => {
    return LoanApi.createOrUpdateDraft(id, '9961602c-2214-44d6-ae0a-59d539d70081', fields);
  };

  createSelectedLoan = async ({ period, amount, tariffId, fpsUserFp, fpsSessionId }) => {
    const ga = Marketing.getGACookies();
    const utm = Marketing.getUTMCookies();
    const utm2 = Marketing.getUTMCookies(cookies.UTM_NEW);
    const referrer = Marketing.getReferrer();
    const referrer2 = Marketing.getReferrer(cookies.REFERRER_NEW);

    let isUpdate: boolean = true;

    try {
      await LoanApi.getSelectedLoan();
    } catch (error) {
      if (error.code !== -32080) {
        throw error;
      }

      isUpdate = false;
    }

    await LoanApi.createSelectedLoan({
      period,
      amount,
      // promoCode,
      tariffId,
      ga,
      utm,
      referrer,
      fpsUserFp,
      fpsSessionId,
      utm2,
      referrer2,
    });

    if (isUpdate) {
      MetricService.onStatusChanged(StatusChangedValues.APPLICATION_CHANGED);
      TakeLoanMetricStore.instance.dispatchChanged();
    } else {
      MetricService.onStatusChanged(StatusChangedValues.APPLICATION_CREATED);
      TakeLoanMetricStore.instance.dispatchCreated();
    }
  };

  @action
  fetchSelectedLoan = async () => {
    try {
      const response = await LoanApi.getSelectedLoan();
      this.selectedLoan = new SelectedLoanModel(response);
    } catch (e) {
      if (e.code !== rpc.NO_SELECTED_LOAN) {
        throw e;
      }
    } finally {
      this.isSelectedLoanLoaded = true;
    }
  };
}

// eslint-disable-next-line import/no-default-export
export default new LoanApplication();
