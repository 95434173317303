import { GENDER } from 'const/loan';
import Model from '../Model';

// eslint-disable-next-line import/no-default-export
export default class Step1GroupBModel extends Model<Step1GroupBModel> {
  public lastName = '';

  public firstName = '';

  public patronymic = '';

  public gender: GENDER = GENDER.UNKNOWN;

  public birthDate = '';

  public email = '';

  public subCode = '';

  // public promoCode = '';

  public esiaLock = false;

  public esiaTrusted = false;

  public constructor(data?: Partial<Step1GroupBModel>) {
    super();
    this.init(data);
  }
}
