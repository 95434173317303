import Cookie from 'js-cookie';
import URL from 'utils/Url';
import * as c from 'const/cookies';
import * as env from 'const/env';

const COOKIE_DOMAIN = env.IS_LOCAL ? window.location.hostname : env.COOKIE_DOMAIN;
const EXPIRES_DATE = new Date(Date.now() + 2592e6); //2592e6 - количество миллисекунд в 30 днях

/**
 * Утилита для работы с UTM метками.
 */
export class UTM {
  private static getFromCookie(cookieValue = c.UTM) {
    const value = Cookie.get(cookieValue);

    try {
      return value ? JSON.parse(value) : {};
    } catch (e) {
      return {};
    }
  }

  /**
   * Устанавливаем UTM метки.
   */
  static save() {
    const utm = this.getFromCookie();
    const queryParams = URL.getQueryParameters(window.location.search);

    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== utm[key]) {
        utm[key] = value;
      }
    });

    Cookie.set(c.UTM, JSON.stringify(utm), {
      expires: EXPIRES_DATE,
      domain: COOKIE_DOMAIN,
    });
  }

  /**
   * Устанавливаем UTM метки в рамках задач
   * https://jira.devim.team/browse/DWN-11279
   * https://jira.devim.team/browse/DWN-11668
   */
  static saveNew() {
    const queryParams = URL.getQueryParameters(window.location.search);

    if (!queryParams[Object.keys(queryParams)[0]]) {
      return;
    }

    Cookie.remove(c.UTM_NEW, {
      domain: COOKIE_DOMAIN,
    });

    Cookie.set(c.UTM_NEW, JSON.stringify(queryParams), {
      expires: EXPIRES_DATE,
      domain: COOKIE_DOMAIN,
    });
  }

  static getSingleFromCookie(key) {
    const cookie = this.getFromCookie();

    return cookie[key];
  }
}
