import Api from 'services/Api';

type Response = {
  name: string;
  fullName: string;
  governmentRegistryNumber: string;
  inn: string;
  ogrn: string;
  address: string;
  phone: string;
  freePhone: string;
  promoPhone: string;
  documentList: {
    // eslint-disable-next-line camelcase
    recurrent_payments_agreement: string;
  };
};

export function get(): Promise<Response> {
  return Api.getInstance().post('company.get', undefined, true);
}
