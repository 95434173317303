import React, { FC } from 'react';

import { Wrap } from './styled';

export const CircleLoading: FC = () => (
  <Wrap>
    <svg className="dzp-circular" viewBox="25 25 50 50">
      <circle
        className="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke="#F89D1B"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </svg>
  </Wrap>
);
