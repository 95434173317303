import Api from 'services/Api';

type Response = {
  status: number;
  interval: number;
};

export function getLoanStatus(): Promise<Response> {
  return Api.getInstance().post('client.getLoanStatus');
}
