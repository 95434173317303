import React from 'react';

const Home = (props) => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M21.309 13.289l1.337-1.58-10.5-8.885-10.5 8.885 1.337 1.58 9.163-7.754z" />
      <path d="M7 9.556H5v9.436C5 20.1 5.895 21 7 21h10c1.105 0 2-.9 2-2.008V9.556h-2v9.436H7V9.556z" />
      <path d="M11 14v5h2v-5h-2zm4-2v9H9v-9h6z" />
    </svg>
  );
};

export default Home;
