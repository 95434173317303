import { observable, action } from 'mobx';
import * as toasts from 'const/toasts';
import { Service as MetricService } from 'modules/common/MetricService';

interface Toast {
  id: number;
  text: string;
  type: number;
}

class ToastsStore {
  @observable
  toasts: Array<Toast> = [];

  @observable
  increment = 0;

  @action
  add({
    text = '',
    type = toasts.TYPE_NOTICE,
    timeout = toasts.DEFAULT_TIMEOUT,
    onClose = Function.prototype,
  }) {
    const id = this.increment + 1;
    const toast = {
      id,
      type,
      text,
      timeout,
      onClose,
    };

    if (type === toasts.TYPE_ERROR) {
      MetricService.onErrorShown(text);
    }

    MetricService.onPopupShown(text);

    this.toasts.push(toast);

    setTimeout(() => {
      this.remove(id);
    }, timeout);

    this.increment += 1;
  }

  @action
  addError({ text = '', timeout = toasts.DEFAULT_TIMEOUT, onClose = Function.prototype }) {
    this.add({
      text,
      timeout,
      onClose,
      type: toasts.TYPE_ERROR,
    });
  }

  @action
  addSuccess({ text = '', timeout = toasts.DEFAULT_TIMEOUT, onClose = Function.prototype }) {
    this.add({
      text,
      timeout,
      onClose,
      type: toasts.TYPE_SUCCESS,
    });
  }

  @action
  addNotice({ text = '', timeout = toasts.DEFAULT_TIMEOUT, onClose = Function.prototype }) {
    this.add({
      text,
      timeout,
      onClose,
      type: toasts.TYPE_NOTICE,
    });
  }

  @action
  remove(id) {
    this.toasts = this.toasts.filter((toast) => toast.id !== id);
  }

  catchError = (error: Error) => {
    this.addError({ text: error.message });
  };
}

// eslint-disable-next-line import/no-default-export
export default new ToastsStore();
