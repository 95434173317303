import React from 'react';
import standardFavicon from 'static/favicon.ico';
import activeFavicon from 'assets/images/favicons/ic_noti.png';
import Favicon from 'react-favicon';

type Props = {
  active: boolean;
};

export class ActiveFavicon extends React.Component<Props> {
  private testLinks = (link: HTMLLinkElement) => {
    return link.getAttribute('type').toLowerCase() !== 'image/x-icon';
  };

  public render() {
    const { active } = this.props;
    const favicons = [standardFavicon];
    if (active) {
      favicons.push(activeFavicon);
    }
    return (
      // --- method keepIconLink has error in react-favicon typings (no arguments)
      <Favicon url={favicons} animationDelay={1000} keepIconLink={this.testLinks as any} />
    )
  }

}
