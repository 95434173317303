import Api from 'services/Api';

type Response = {
  id: number;
  type: number;
  loanType: number;
  name: string;
  identificationStatus: number;
  showProlongationButton: boolean;
  amount: number;
  period: number;
  periodClaimed: number;
  creationDate: string; // --- ISODate
  agreementNumber: string;
  status: number;
  issueDate: string;
  showRecalculationButton: boolean;
  showPayoutButton: boolean;
  preOrders: Array<any>;
  isTransactionInProgress: boolean;
  claimAmount: number;
  isWorkingHours: boolean;
  underwritingDeadline: string; // --- ISODate
  dueToDate: string; // --- ISODate
  isExpired: boolean;
  expiredAmount: number;
  lastExpiredAmount: number;
  expiredDate: string;
  servicePrice: number;
  minimalPayment: number;
  monthlyPayment: number;
  paymentDate: string; // --- ISODate
  paymentsCount: number;
  interestRate: number;
  changedLoanTerms: number;
  closeLoanAmount: number;
  recalculateAmount: number;
  requisitesDocumentUrl?: string;
  activeDays: number;
  freeDays: number;
  isRestructureAvailable: boolean;
  restructureAvailableTill: null | string; // --- ISODate
  discounts?: Array<{ type: string; [key: string]: any }>;
  approveDate?: string;
  isRestructureAllowed?: boolean;
};

export function getDashboardInfo(forceUpdate = false): Promise<Response> {
  return Api.getInstance().post('client.getDashboardInfo', { forceUpdate });
}
