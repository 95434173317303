import { observable, computed, action } from 'mobx';

import PaidService from 'services/PaidService';
import PaidServiceModel from 'models/PaidService';

import RouteManager from 'routes/manager';
import { ADDITIONAL_SERVICES } from 'const/queryStrings';
import { SERVICE_TYPE, SERVICE_STATUS, serviceConfig } from 'const/paidService';
import ToastsStore from './Toasts';

class PaidServicesStore {
  @observable
  inProcess = false;

  @observable
  services: Array<PaidServiceModel> = [];

  @observable
  selectedServices: Array<PaidServiceModel> = []; // service models

  @observable
  isDataLoaded = false;

  private processing = false;

  private fetching: ReturnType<typeof PaidService.get> = null;

  // возвращает услуги для клиента
  @action
  public fetch = async () => {
    if (this.inProcess) {
      return this.fetching;
    }
    this.reset();
    this.inProcess = true;
    this.fetching = PaidService.get();
    try {
      const response = await this.fetching;
      this.selectedServices.splice(0);
      this.services = [
        ...response.map((paidService) => {
          const config = serviceConfig.find((n) => n.type === paidService.type);
          return new PaidServiceModel({ ...paidService, ...config });
        }),
      ];
      this.readyToAddServices.forEach((service) => this.addSelectedService(service.type));
      this.isDataLoaded = true;
      return this.fetching;
    } finally {
      this.inProcess = false;
    }
  };

  @action
  public reset() {
    this.isDataLoaded = false;
    this.inProcess = false;
  }

  @action
  toggleSelectService(serviceType) {
    if (this.processing) {
      return;
    }

    if (this.selectedServices.some((service) => service.type === serviceType)) {
      this.removeSelectedService(serviceType);
    } else {
      this.addSelectedService(serviceType);
    }
  }

  @action
  addSelectedService(serviceType: SERVICE_TYPE) {
    const service = this.services.find((needle) => needle.type === serviceType);

    if (
      service &&
      !this.selectedServices.includes(service) &&
      !this.selectedServicesIncludes.includes(serviceType) &&
      !this.purchasedServicesIncludes.includes(serviceType)
    ) {
      this.selectedServices.push(service);
    }
  }

  @action
  removeSelectedService(serviceType: SERVICE_TYPE) {
    const service = this.services.find((needle) => needle.type === serviceType);

    if (service && this.selectedServices.includes(service)) {
      this.selectedServices.splice(this.selectedServices.indexOf(service), 1);

      /** >>> user have to select at least one required service (DWN-4142) */
      if (
        service.required &&
        this.selectedServices.filter((selected) => selected.required).length < 1
      ) {
        this.processing = true;
        setTimeout(() => {
          this.addSelectedService(serviceType);
          this.processing = false;

          ToastsStore.addError({
            text: '406 - Not Acceptable',
          });
        }, 1000);
      }
      /** <<< */
    }
  }

  purchaseSelectedServices() {
    RouteManager.goToAdditionalServicesSign(false, {
      [ADDITIONAL_SERVICES]: this.selectedServices
        .filter((service) => !this.selectedServicesIncludes.includes(service.type))
        .map((service) => service.type),
    });
  }

  getService(type: SERVICE_TYPE) {
    return this.services.find((service) => service.type === type);
  }

  isChecked(type: SERVICE_TYPE) {
    const service = this.services.find((needle) => needle.type === type);

    return Boolean(
      service &&
        (this.selectedServices.includes(service) ||
          this.selectedServicesIncludes.includes(service.type) ||
          this.purchasedServicesIncludes.includes(service.type)),
    );
  }

  isPurchased(type: SERVICE_TYPE) {
    const service = this.services.find((needle) => needle.type === type);
    return Boolean(service && service.purchased);
  }

  @computed
  get selectedServicesIncludes() {
    return this.servicesIncludes(this.selectedServices);
  }

  @computed
  get purchasedServicesIncludes() {
    return this.servicesIncludes(this.purchasedServices);
  }

  @computed
  get hasAvailableServices() {
    return this.services.some((service) => service.status !== SERVICE_STATUS.NOT_AVAILABLE);
  }

  @computed
  get hasAvailableServicesForCurrentLoan() {
    return this.services.some(
      (service) =>
        service.status !== SERVICE_STATUS.NOT_AVAILABLE &&
        service.type !== SERVICE_TYPE.REASON_OF_REFUSAL,
    );
  }

  @computed
  get hasAvailableServicesForRejectedLoan() {
    return this.services.some(
      (service) =>
        service.status !== SERVICE_STATUS.NOT_AVAILABLE &&
        service.type === SERVICE_TYPE.REASON_OF_REFUSAL,
    );
  }

  @computed
  get amountOfAvailableServices() {
    return this.services.filter((service) => service.status !== SERVICE_STATUS.NOT_AVAILABLE)
      .length;
  }

  /** @deprecated use getService() instead */
  @computed
  get creditInfoData() {
    return this.services.find((service) => service.type === SERVICE_TYPE.CREDIT_HISTORY);
  }

  /** @deprecated use getService() instead */
  @computed
  get reasonOfRefusalInfoData() {
    return this.services.find((service) => service.type === SERVICE_TYPE.REASON_OF_REFUSAL);
  }

  @computed
  get purchasedServices() {
    return this.services.filter(
      (service) =>
        service.status === SERVICE_STATUS.PURCHASED ||
        (service.type === SERVICE_TYPE.REASON_OF_REFUSAL && service.documentUrl !== ''),
    );
  }

  @computed
  get purchasedServicesDocs() {
    // Простите меня все, нет времени объяснять

    if (!this.purchasedServices.length) return [];

    const compareUrl = this.purchasedServices[0].documentUrl;

    const isEqual = this.purchasedServices.every((service) => {
      return service.documentUrl === compareUrl;
    });

    return isEqual ? [this.purchasedServices[0]] : this.purchasedServices;
  }

  @computed
  get readyToAddServices() {
    return this.services
      .filter(
        (service) =>
          ![SERVICE_STATUS.NOT_AVAILABLE, SERVICE_STATUS.PURCHASED].includes(service.status),
      )
      .filter((service) => !this.purchasedServicesIncludes.includes(service.type));
  }

  @computed
  get availableServices() {
    return this.services.filter((service) => service.status !== SERVICE_STATUS.NOT_AVAILABLE);
  }

  @computed
  get selectedTotalPrice() {
    return this.selectedServices.reduce((totalPrice, service) => {
      return (
        totalPrice + (this.selectedServicesIncludes.includes(service.type) ? 0 : service.price)
      );
    }, 0);
  }

  @computed
  get selectedOldTotalPrice() {
    return this.selectedServices.reduce((totalPrice, service) => {
      return (
        totalPrice + (this.selectedServicesIncludes.includes(service.type) ? 0 : service.oldPrice)
      );
    }, 0);
  }

  @computed
  get submitServicesDisabled() {
    return (
      (this.readyToAddServices.some((service) => service.required) &&
        !this.selectedServices.some((service) => service.required)) ||
      !this.selectedServices.length
    );
  }

  servicesIncludes(services: Array<PaidServiceModel>) {
    const result = new Set<SERVICE_TYPE>();

    services.forEach((service) => {
      if (service.includes) {
        service.includes.forEach((type) => result.add(type));
      }
    });

    return Array.from(result.values());
  }
}

// eslint-disable-next-line import/no-default-export
export default new PaidServicesStore();
