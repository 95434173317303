import React, { ReactNode } from 'react';
import { ButtonLink } from 'ui/ButtonLink';

const parser = new DOMParser();

/**
 * Парсит строку с условиями взятия займа с бекенда.
 * Строка может включать в себя ссылку к которой нужно подключить метрику
 * открытия документа `onDocumentOpened`.
 */
export const parseStringToCheckboxLable = ({
  children,
  documentType,
  onClick,
}: {
  children: ReactNode;
  documentType?: string;
  onClick?: (e, docType: string) => void;
}) => {
  if (Array.isArray(children)) {
    return children;
  }

  const template = [];
  const docHtml = parser.parseFromString(children.toString(), 'text/html');

  docHtml.body.childNodes.forEach((item: HTMLElement) => {
    if (item.nodeType === Node.TEXT_NODE) {
      template.push(<span key={item.textContent}>{item.textContent}</span>);
    }

    if (item.nodeName === 'A' && item.nodeType === Node.ELEMENT_NODE) {
      const link = (
        <ButtonLink key={item.textContent} onClick={(e) => onClick(e, documentType)} type="button">
          {item.textContent}
        </ButtonLink>
      );

      template.push(link);
    }
  });

  return template;
};
