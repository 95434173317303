import Api from 'services/Api';

type Request = {
  interviewId;
  questionId;
  answerId;
};

type Response = true;

export function answerQuestion(data: Request): Promise<Response> {
  return Api.getInstance().post('client.answerQuestion', data);
}
