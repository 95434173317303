import { ReactNode } from 'react';

/**
 * Возвращает текст из `children`.
 */
export function getChildrenText({
  children,
  msTitle,
}: {
  children: ReactNode;

  /**
   * Название  метрики. Если невозможно взять название метрики из
   * `children`, то рекомендуется указывать это свойство.
   */
  msTitle?: string;
}): string {
  if (msTitle) {
    return msTitle;
  }

  if (typeof children === 'string') {
    return String(children);
  }

  if (Array.isArray(children)) {
    return children.filter((item) => typeof item === 'string').join(' ');
  }

  return '';
}
