import React from 'react';

const Profile = (props) => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M12 2C6.47 2 2 6.48 2 12c0 2.7 1.059 5.193 2.941 7.092A9.946 9.946 0 0 0 12 22a9.946 9.946 0 0 0 7.059-2.908C20.97 17.222 22 14.7 22 12c0-5.52-4.47-10-10-10zm0 1.87c4.5 0 8.147 3.65 8.147 8.13 0 1.87-.618 3.62-1.765 5.045A9.195 9.195 0 0 0 12 14.463c-2.382 0-4.647.92-6.382 2.582A7.972 7.972 0 0 1 3.853 12C3.853 7.52 7.5 3.87 12 3.87zM6.941 18.35c1.383-1.305 3.177-2.047 5.059-2.047 1.882 0 3.676.742 5.059 2.047A8.184 8.184 0 0 1 12 20.1a7.839 7.839 0 0 1-5.059-1.75zm5.118-4.807a3.688 3.688 0 0 1-3.677-3.68 3.688 3.688 0 0 1 3.677-3.679 3.688 3.688 0 0 1 3.676 3.68 3.688 3.688 0 0 1-3.676 3.679zm0-5.49c-1 0-1.824.802-1.824 1.81 0 1.01.824 1.81 1.824 1.81s1.823-.83 1.823-1.81c0-1.008-.823-1.81-1.823-1.81z" />
    </svg>
  );
};

export default Profile;
