import Api from './Api';

class BrokeragePaidServiceApi extends Api {
  get = () => {
    return this.post('brokerage.get');
  };

  create = (amount: number) => {
    return this.post('brokerage.create', { amount });
  };

  /** получение кода для подписания договора */
  sendConfirmationCode = () => {
    return this.post('brokerage.sendConfirmationCode', {}, true, true);
  };

  /** отправка кода при подписании договора */
  confirmSmsCode = (smsCode) => {
    return this.post('brokerage.confirmSmsCode', { smsCode }, true);
  };

  /** возвращает ссылку на форму Тинька */
  getPaymentInfo = () => {
    return this.post('brokerage.getPaymentInfo', undefined, true, true);
  };
}

// eslint-disable-next-line import/no-default-export
export default new BrokeragePaidServiceApi();
