import { styled } from 'const/stitches';

export const Wrap = styled('div', {
  position: 'relative',
  margin: '0 auto',
  width: '56px',
  '&:before': {
    content: "''",
    display: 'block',
    paddingTop: '100%',
  },
});
