export class CustomError extends Error {
  constructor(message?: string) {
    super(message);

    this.name = this.constructor.name;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, Error);
    } else {
      this.stack = new Error().stack;
    }
  }
}
