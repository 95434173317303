import React from 'react';

type Props = React.HTMLProps<SVGSVGElement>;

const Small: React.FC<Props> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" {...props}>
      <g fill="none">
        <path
          fill="#F89C1C"
          d="M17.18 0C10.03 2.5 4.28 4.63.38 5.99l1.37.47c1.78.61 3.16 2 3.16 4.26v9.83l9.18-3.43c1.74-.62 3.1-2.17 3.1-4V0z"
          mask="url(#b)"
          transform="translate(20.82 12.12)"
        />
        <path
          fill="#F89C1C"
          d="M12.04 13.98c.22.74.83 1.15 1.27 1.3l2.82 1.03L38 8.37v-2.5c-.43-1.58-2.25-2.26-3.99-1.65-5.36 1.9-16.07 5.73-21.42 7.64l-.07.04c-.07.09-.8 1.05-.48 2.08"
        />
        <path
          fill="#EC1D24"
          d="M11.81 21.45c.78.07 1.42-.74 1.43-1.8.02-1.07-.6-1.98-1.37-2.05-.78-.07-1.42.74-1.43 1.8-.02 1.07.6 1.99 1.37 2.05m.75-14.36c1.8.6 3.35 2.28 3.35 4.16v18.13c0 1.5-1 2.76-2.43 3.08a1.95 1.95 0 0 1-.42.04 2.41 2.41 0 0 1-.81-.15c-3.16-1.1-6.34-2.11-9.46-3.33C1.02 28.17 0 26.94 0 25V3.75C0 1.86.92 1.19 2.54.38c0 0-.83 1.03-.5 2.1.22.74.83 1.15 1.27 1.31C6.35 4.9 9.5 6.05 12.56 7.1"
          mask="url(#d)"
          transform="translate(10 11.5)"
        />
        <path d="M0 0h48v48H0z" />
      </g>
    </svg>
  );
};

// eslint-disable-next-line import/no-default-export
export default Small;
