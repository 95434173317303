import React, { Component } from 'react';

import InfoIcon from 'ui/svg-icons/Toasts/Info';
import ErrorIcon from 'ui/svg-icons/Toasts/Error';
import SuccessIcon from 'ui/svg-icons/Toasts/Success';
import Cross from 'ui/svg-icons/Cross';
import * as toasts from 'const/toasts';
import { Element, Icon, Text, Close } from '../../styled';

type Props = {
  id: number;
  type: typeof toasts.TYPE_NOTICE | typeof toasts.TYPE_ERROR | typeof toasts.TYPE_SUCCESS | number;
  onClose?: (id: number) => void;
};

export class Toast extends Component<Props> {
  onClose = () => {
    const { onClose, id } = this.props;
    if (onClose) {
      onClose(id);
    }
  };

  render() {
    const { children, type } = this.props;
    const typeNotice = type === toasts.TYPE_NOTICE;
    const typeError = type === toasts.TYPE_ERROR;
    const typeSuccess = type === toasts.TYPE_SUCCESS;

    return (
      <Element typeNotice={typeNotice} typeError={typeError} typeSuccess={typeSuccess}>
        <Icon>
          {typeNotice && <InfoIcon />}
          {typeError && <ErrorIcon />}
          {typeSuccess && <SuccessIcon />}
        </Icon>
        <Text>{children}</Text>
        <Close
          onClick={this.onClose}
          type="button"
          data-qa="toast-close-button"
          msTitle="Закрыть всплывающее уведомление"
        >
          <Cross />
        </Close>
      </Element>
    );
  }
}
