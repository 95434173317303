import React from 'react';
import Helpers from 'utils/Helpers';

import { Title, Text, Subtitle } from 'modules/Modal/styled';

import LabelText from 'ui/LabelText';
import ArrowFrontIcon from 'ui/svg-icons/ArrowFront';
import SuccessIcon from 'ui/svg-icons/Success';

import { BONUSES_WARNING } from 'const/bonuses';
import { Wrapper, RepaymentRow, ArrowFront, ButtonsBlock, FlexCell, IconWrapper } from './styled';

type Props = {
  step: number;
  oldAmount: number;
  newAmount: number;
  recalculateButton: React.ReactElement;
  cancelButton: React.ReactElement;
  goToDashboardButton: React.ReactElement;
  isLoanClosed: boolean;
  success: boolean;
  bonusWarningType: BONUSES_WARNING;
  showBonusWarning: boolean;
  goToStep2Button: React.ReactElement;
  goToBonusProgramButton: React.ReactElement;
  appForRecalculation: React.ReactNode;
};

export const RecalculationModal: React.FC<Props> = ({
  step,
  oldAmount,
  newAmount,
  recalculateButton,
  cancelButton,
  goToDashboardButton,
  isLoanClosed,
  success,
  bonusWarningType,
  showBonusWarning,
  goToStep2Button,
  goToBonusProgramButton,
  appForRecalculation,
}) => (
  <Wrapper isLoanClosed={isLoanClosed}>
    {showBonusWarning && (
      <>
        <Title>Внимание!</Title>

        {bonusWarningType === BONUSES_WARNING.TYPE_1 && (
          <Text>
            В случае продолжения операции с вас будет списано 3 балла и текущий уровень бонусной
            программы может быть потерян.
          </Text>
        )}

        {bonusWarningType === BONUSES_WARNING.TYPE_2 && (
          <Text>
            В случае продолжения операции с вас будет списано 2 балла и текущий уровень бонусной
            программы может быть потерян.
          </Text>
        )}

        {bonusWarningType === BONUSES_WARNING.TYPE_3 && (
          <Text>
            В случае продолжения операции с вас будет списан 1 балл и текущий уровень бонусной
            программы может быть потерян.
          </Text>
        )}

        {bonusWarningType === BONUSES_WARNING.TYPE_4 && (
          <Text>
            С 01.04.2019 клиентам с уровнем «Эксперт» в рамках программы лояльности МКК «До
            Зарплаты» доступен моментальный перерасчет займа после 15 дней пользования. Оформляйте
            займы и пролонгации, копите бонусные звёзды, чтобы достигнуть уровня «Эксперт».
          </Text>
        )}

        <ButtonsBlock>
          {bonusWarningType !== BONUSES_WARNING.TYPE_4 && (
            <>
              {cancelButton}
              {goToStep2Button}
            </>
          )}

          {bonusWarningType === BONUSES_WARNING.TYPE_4 && goToBonusProgramButton}
        </ButtonsBlock>
      </>
    )}

    {appForRecalculation}

    {!showBonusWarning && !isLoanClosed && !success && step !== 2 && (
      <>
        <Title>Досрочное погашение</Title>

        {newAmount > 0 && (
          <>
            <Text>
              Для того чтобы воспользоваться услугой «Досрочное погашение», вам необходимо полностью
              оплатить заем <b>сегодня до 23:59</b>.
            </Text>
            <Text>
              По истечении данного времени, перерасчет автоматически отменится и будет отображена
              прежняя сумма к оплате.
            </Text>
            <Subtitle>Сумма к оплате сегодня:</Subtitle>
          </>
        )}

        {newAmount === 0 && (
          <Text>Ранее вы уже оплатили заем на сумму, достаточную для досрочного погашения.</Text>
        )}

        <RepaymentRow>
          <FlexCell>
            <LabelText
              label="До перерасчета:"
              value={Helpers.asMoney(oldAmount)}
              lineThrough
              dataQa="footer-recalculationOldAmount-label"
            />
          </FlexCell>
          <ArrowFront>
            <ArrowFrontIcon />
          </ArrowFront>
          <FlexCell>
            <LabelText
              label="После:"
              value={Helpers.asMoney(newAmount)}
              dataQa="footer-recalculationNewAmount-label"
            />
          </FlexCell>
        </RepaymentRow>

        <ButtonsBlock>
          {recalculateButton}
          {newAmount > 0 && cancelButton}
        </ButtonsBlock>
      </>
    )}

    {isLoanClosed && (
      <>
        <IconWrapper>
          <SuccessIcon />
        </IconWrapper>
        <Text centered>Ваш заем успешно закрыт!</Text>
        <ButtonsBlock centered>{goToDashboardButton}</ButtonsBlock>
      </>
    )}

    {success && (
      <>
        <IconWrapper>
          <SuccessIcon />
        </IconWrapper>
        <Text centered>
          Перерасчет вашего займа успешно произведен! Теперь вам доступна обновленная сумма к
          оплате.
        </Text>
        <ButtonsBlock centered>{goToDashboardButton}</ButtonsBlock>
      </>
    )}
  </Wrapper>
);
