import Model from '../Model';

// eslint-disable-next-line import/no-default-export
export default class Step3GroupBModel extends Model<Step3GroupBModel> {
  public photo = '';

  public passportScan = '';

  public subCode = '';

  public passport = '';

  public issueDate = '';

  public placeOfBirth = '';

  public issueAuthority = '';

  public esiaLock = false;

  public esiaTrusted = false;

  public addressRegistrationDadata = {};

  public addressRegistration = {
    locality: '',
    street: '',
    house: '',
    flat: '',
    registrationDate: '',
  };

  public inn = '';

  public constructor(data?: Partial<Step3GroupBModel>) {
    super();
    this.init(data);
  }
}
