import { MetricService } from '@frontend/metric-service';
import { getChildrenText } from 'modules/common/MetricService';
import React, { ComponentType, HTMLProps, PropsWithChildren } from 'react';

type MetricServiceProps = {
  /**
   * Название метрики. Если невозможно взять название метрики из
   * `children`, то рекомендуется указывать это свойство.
   */
  msTitle?: string;

  /**
   * Флаг отключает отправку метрики.
   */
  msDisabled?: boolean;
  /**
   * Обрабатывает клик добавляя отправку метрики
   */
  onClick?: (e: any) => void;
};

type WithMetricServiceClickProps<T> = PropsWithChildren<T & MetricServiceProps>;

export function withMetricServiceClick<T extends HTMLProps<any>>(
  Comp: ComponentType<WithMetricServiceClickProps<T>>,
) {
  return function InnerComponent({
    children,
    onClick,
    msDisabled,
    msTitle,
    ...props
  }: WithMetricServiceClickProps<T>) {
    const metricServiceClick = (e: Event) => {
      if (onClick) {
        if (!msDisabled) {
          const text = getChildrenText({ children, msTitle });

          MetricService.onButtonPressed(text as string);
        }
        onClick(e);
      }
    };

    return React.createElement(Comp, { ...(props as T), onClick: metricServiceClick }, children);
  };
}
