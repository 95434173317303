import { MetricStore } from './MetricStore';

/**
 * Предназначено для отправки событий метрики, связанного с процессом
 * аутентификации.
 */
export class AuthMetricStore {
  /**
   * Название события, которое происходит, когда пользователь входит в систему.
   */
  private static LOGIN = 'login';

  /**
   * Название события, которое происходит, когда пользователь выходит из
   * системы.
   */
  public static LOGOUT = 'logout';

  /**
   * Сохраненное значение свойства instance.
   */
  private static instanceValue: AuthMetricStore;

  /**
   * Экземпляр синглтона.
   */
  public static get instance() {
    if (this.instanceValue == null) {
      this.instanceValue = new this();
    }

    return this.instanceValue;
  }

  /**
   * Генерирует событие метрики, сигнализирующее о том, что пользователь
   * успешно вошел в систему.
   */
  public dispatchLogin() {
    MetricStore.instance.dispatch(AuthMetricStore.LOGIN);
  }

  /**
   * Генерирует событие метрики, сигнализирующее о том, что пользователь
   * вышел из системы.
   */
  public dispatchLogout() {
    MetricStore.instance.dispatch(AuthMetricStore.LOGOUT);
  }
}
