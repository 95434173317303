import { styled } from 'const/stitches';

export const Wrap = styled('footer', {
  background: '#fff',
  marginTop: 'auto',
  overflow: 'hidden',
});

export const Row = styled('div', {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '25% 50% 25%',
  justifyContent: 'space-between',
  padding: '1.5rem 1.5rem',

  '@mediaTabletAndMore': {
    padding: '1.5rem 2rem',
  },

  '@mediaTabletLAndMore': {
    padding: '1.5rem 2rem 0.75rem',
  },

  '@mediaLaptopAndMore': {
    gridTemplateColumns: '25% 40% 25%',
    gap: '2rem',
    padding: '2rem 3rem 0.5rem',
  },

  '@mediaTabletAndLess': {
    gridTemplateColumns: '30% 70%',
  },

  '@mediaMobileAndLess': {
    gridTemplateColumns: '1fr',
  },
});

export const ColLogo = styled('div', {
  width: '100%',

  '@mediaMobileAndLess': {
    display: 'flex',
    justifyContent: 'center',
  },
});

export const ColAdditionalInfo = styled('div', {
  width: '100%',

  '@mediaTabletAndLess': {
    gridColumn: '1 / 3',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '2rem',
    paddingTop: '2rem',
  },

  '@mediaMobileAndLess': {
    gridColumn: '1',

    flexDirection: 'column',
    justifyContent: 'center',
  },
});

export const NavRow = styled('div', {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',

  '@mediaTabletAndLess': {
    gridTemplateColumns: 'auto auto',
    justifyContent: 'flex-end',
  },

  '@mediaMobileAndLess': {
    justifyContent: 'center',
    paddingTop: '2rem',
  },
});

export const NavCol = styled('div', {
  padding: '0 0.5rem',

  '@mediaMobileAndMore': {
    padding: '0 1.25rem',
  },

  '@mediaTabletLAndMore': {
    padding: '0 2rem',
  },

  '@mediaTabletAndLess': {
    justifySelf: 'flex-end',
  },
});

export const LogoLink = styled('a', {
  display: 'inline-block',
  color: 'inherit',
});

export const GoogleTerms = styled('p', {
  margin: 0,
  padding: '0 11px',
  fontSize: '11px',
  textAlign: 'right',
  color: '$color-text-secondary',

  a: {
    color: 'inherit',
    fontStyle: 'italic',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  '@mediaMobileAndLess': {
    fontSize: '9px',
  },
});

export const Title = styled('h4', {
  display: 'block',
  fontSize: '13px',
  color: '$color-text-primary',
  textTransform: 'uppercase',
  fontWeight: 500,
  letterSpacing: '0.5px',
  padding: '0.5rem 0',
  margin: 0,

  '@mediaTabletLAndMore': {
    fontSize: '14px',
  },
});

export const TitleLink = styled('a', {
  color: 'inherit',
});

export const TitleButton = styled('button', {
  display: 'inline-block',
  textDecoration: 'underline',
  fontSize: '13px',
  color: '$color-text-primary',
  textTransform: 'uppercase',
  fontWeight: 500,
  letterSpacing: '0.5px',
  padding: '0.5rem 0',
  margin: 0,

  '@mediaTabletLAndMore': {
    fontSize: '14px',
  },
});

export const Menu = styled('ul', {
  margin: '0.5rem 0 0.85rem',
  padding: 0,
  listStyle: 'none',

  '@mediaTabletLAndMore': {
    marginTop: '0.25rem',
  },
});

export const MenuItem = styled('li', {
  padding: '0.4rem 0',

  '@mediaTabletLAndMore': {
    padding: '0.35rem 0',
  },
});

export const MenuLink = styled('a', {
  fontSize: '13px',
  color: '$color-grey-80',

  '@mediaTabletLAndMore': {
    fontSize: '14px',
  },
});

export const PhoneNumber = styled('a', {
  display: 'block',
  whiteSpace: 'nowrap',
  color: '$color-text-primary',
  fontSize: '18px',

  '@mediaTabletAndMore': {
    fontSize: '22px',
  },

  '@mediaTabletLAndMore': {
    fontSize: '27px',
  },
});

export const AppMenu = styled('ul', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1rem',
  margin: '2rem 0 0',
  padding: 0,
  listStyle: 'none',

  '@mediaTabletAndLess': {
    margin: 0,
    justifyContent: 'flex-end',
  },

  '@mediaMobileAndLess': {
    justifyContent: 'center',
  },
});

export const AppMenuItem = styled('li', {});

export const AppMenuLink = styled('a', {});

export const AppMenuImage = styled('img', {
  height: '40px',
  verticalAlign: 'top',
});
