import * as c from 'const/calculator';
import { PERIOD_TYPE } from 'const/calculator';
import CalculatorTariffModel from 'models/CalculatorTariff';

export function makeDefaultTariffs() {
  const initialTariff = {
    tariffId: c.TARIFF_PDL,
    title: 'заём до 10 000 ₽',
    subtitle: 'Одобрение за 15 минут!',
    periodType: PERIOD_TYPE.DAY,
    iconType: 1,
    range: [
      {
        maxAmount: 2000000,
        maxPeriod: 30,
        minAmount: 200000,
        minPeriod: 7,
        stepAmount: 100000,
        stepPeriod: 1,
      },
    ],
    defaultValues: {
      amount: 800000,
      period: 15,
    },
    description: [
      {
        content: 'Одобрение заявки за 15 минут',
        iconType: 1,
      },
      {
        content: 'Получение займа на банковскую карту круглосуточно',
        iconType: 1,
      },
    ],
  };
  const initialTariff3 = {
    tariffId: c.TARIFF_INSTALL,
    title: 'заём до 100 000 ₽',
    subtitle: 'Платёж раз в месяц!',
    periodType: PERIOD_TYPE.MONTH,
    iconType: 3,
    range: [
      {
        maxAmount: 10000000,
        maxPeriod: 12,
        minAmount: 2100000,
        minPeriod: 2,
        stepAmount: 100000,
        stepPeriod: 1,
      },
    ],
    defaultValues: {
      amount: 2100000,
      period: 2,
    },
    description: [
      {
        content: 'Размер займа до 100 000 рублей',
        iconType: 1,
      },
      {
        content: 'Минимальный ежемесячный платёж',
        iconType: 1,
      },
      {
        content: 'Удобный способ получения займа',
        iconType: 1,
      },
    ],
  };

  return [new CalculatorTariffModel(initialTariff), new CalculatorTariffModel(initialTariff3)];
}
