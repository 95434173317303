import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { RadioWrapper, RadioLabel, RadioMark } from './styled';

export default class RadioButton extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    errorText: PropTypes.string,
  };

  render() {
    const { label, errorText, ...other } = this.props;

    return (
      <RadioWrapper>
        <RadioLabel hasError={!!errorText} >
          <input {...other} type="radio" />
          <RadioMark hasError={!!errorText} />
          <span>{label}</span>
        </RadioLabel>
      </RadioWrapper>
    );
  }
}