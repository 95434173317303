/**
 * Токен авторизации.
 */
export const USER_TOKEN = 'token';

// auth sms confirmed
export const USER_TOKEN_CONFIRMED = 'confirm-phone';

/**
 * Флаг оператора. Выставляется при заходе с 0000.
 */
export const OPERATOR = 'operator';

/**
 * Сохраненные настройки калькулятора.
 */
export const CALC_KEY = '_d_calc';

/**
 * use old version
 */
export const USE_OLD_VERSION = '_d_old_version';

/**
 * welcome dialog is shown
 */
export const NEW_LK_DIALOG_SHOWN = '_d_dialog_1_shown';

/**
 * download android application
 */
export const DOWNLOAD_ANDROID_APP = '_d_download_android_app';

/**
 * shown esia modal
 */
export const ESIA_MODAL = '_d_esia_modal';

export const FAILED_METRICS = '_d_failed_metrics';

/**
 * shown webcam instruction
 */
export const WEBCAM_INSTRUCTION_SHOWN = '_d_webcam_instruction';

/**
 * take loan a/b
 */
export const LOGIN_TAKE_LOAN_AB = '_d_ltl_var';

/**
 * Кука гугл аналитики.
 */
export const GA = '_ga';

/**
 * Кука UTM.
 */
export const UTM = '_dzp_utm';

/**
 * Кука гугл referrer.
 */
export const REFERRER = '_dzp_referrer';

/**
 * Новая кука UTM.
 */
export const UTM_NEW = '_dzp_utm_2';

/**
 * Новая кука referrer.
 */
export const REFERRER_NEW = '_dzp_referrer_2';

/**
 * application client first name for ping
 */
export const APPLICATION_FIRST_NAME = '_d_app_first_name';

/**
 * application client last name for ping
 */
export const APPLICATION_LAST_NAME = '_d_app_last_name';

/**
 * application client patronymic for ping
 */
export const APPLICATION_PATRONYMIC = '_d_app_patronymic';

export const APPROVED_LOAN_CHANGE_INFO_SHOWN = 'd_lacis';
export const AB_TEST = '_d_ab_test';
export const METRICS_COOKIE_UID = 'dm_uid';
export const OUTER_LOAN = '_d_outer_loan';

/**
 * Идентификатор Яндекс Метрики `_ym_uid`.
 * https://yandex.ru/support/metrica/general/clientid-userid.html
 */
export const YANDEX_ID = '_ym_uid';

/**
 * Идентификатор сессии.
 */
export const SESSION_ID = 'dvmclcks';

export const STEP1_FILLED = 'step_1_filled';
export const STEP2_FILLED = 'step_2_filled';

/**
 *  ФИО юзера из вордпресс части
 */
export const WP_USER_LABEL = '_dzp_userlabel';

/**
 * Ссылка на сервис аутентификации.
 */
export const AUTHENTICATION_URL = '_dzp_authentication_url';

/**
 * Адрес, который задает сервис аутентификации при успешном редиректе в ЛК.
 * auth/complete?state=*&provider=*
 */
export const AUTHENTICATION_COMPLETE = '_dzp_authentication_complete';
