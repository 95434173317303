import React from 'react';

const Verified = (props) => {
  return (
    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="6" cy="6" r="6" />
      <path
        d="M8.834 3.667a.629.629 0 0 1 .886.892L5.8 8.48a.629.629 0 0 1-.51.18c-.189-.02-.358-.124-.403-.225a.632.632 0 0 1-.1-.08L2.356 5.92a.629.629 0 0 1 .895-.881l2.105 2.105 3.478-3.478z"
        fill="#FFF"
      />
    </svg>
  );
};

export default Verified;
