import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export class CaptchaStore {
  private static captchaProvider: GoogleReCaptchaProvider | null = null;

  public static setCaptchaProvider(elem: GoogleReCaptchaProvider) {
    this.captchaProvider = elem;
  }

  public static getCaptchaProvider() {
    return this.captchaProvider;
  }
}