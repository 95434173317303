import React from 'react';

const ArrowFront = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-4-4h24v24H-4z" />
        <path fill="#A2A2B2" d="M0 9h12.17l-5.59 5.59L8 16l8-8-8-8-1.41 1.41L12.17 7H0z" />
      </g>
    </svg>
  );
};

export default ArrowFront;
