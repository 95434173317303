import React from 'react';

const Complain = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.3787 2H10.6213C9.95828 2 9.32239 2.26339 8.85355 2.73223L2.73223 8.85355C2.26339 9.32239 2 9.95828 2 10.6213V17.3787C2 18.0417 2.26339 18.6776 2.73223 19.1464L8.85355 25.2678C9.32239 25.7366 9.95828 26 10.6213 26H17.3787C18.0417 26 18.6776 25.7366 19.1464 25.2678L25.2678 19.1464C25.7366 18.6776 26 18.0417 26 17.3787V10.6213C26 9.95828 25.7366 9.32239 25.2678 8.85355L19.1464 2.73223C18.6776 2.26339 18.0417 2 17.3787 2ZM10.2678 4.14645C10.3615 4.05268 10.4887 4 10.6213 4H17.3787C17.5113 4 17.6385 4.05268 17.7322 4.14645L23.8536 10.2678C23.9473 10.3615 24 10.4887 24 10.6213V17.3787C24 17.5113 23.9473 17.6385 23.8536 17.7322L17.7322 23.8536C17.6385 23.9473 17.5113 24 17.3787 24H10.6213C10.4887 24 10.3615 23.9473 10.2678 23.8536L4.14645 17.7322C4.05268 17.6385 4 17.5113 4 17.3787V10.6213C4 10.4887 4.05268 10.3615 4.14645 10.2678L10.2678 4.14645ZM14 8C13.4477 8 13 8.44772 13 9V14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14V9C15 8.44772 14.5523 8 14 8ZM12.8001 18C12.8001 17.3373 13.3374 16.8001 14 16.8001C14.6627 16.8001 15.2 17.3373 15.2 18C15.2 18.6627 14.6627 19.1999 14 19.1999C13.3374 19.1999 12.8001 18.6627 12.8001 18Z" />
    </svg>
  );
};

export default Complain;
