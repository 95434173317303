import React, { Component } from 'react';
import { observer } from 'mobx-react';

import LoanApi from 'services/Loan';
import CurrentLoanStore from 'store/CurrentLoan';
import ClientStore from 'store/Client';
import ToastsStore from 'store/Toasts';
import moment from 'moment';
import RouteManager from 'routes/manager';

import { Button } from 'ui/buttons/Button';
import Loading from 'ui/Loading';

import * as toasts from 'const/toasts';
import { BONUSES_WARNING } from 'const/bonuses';
import { RecalculationModal as Wrapper } from '../../components/RecalculationModal';
import { AppForRecalculation } from '../AppForRecalculation';

type Props = {
  onClose?: (needTrack?: boolean) => void;
};

@observer
export class RecalculationModal extends Component<Props> {
  static defaultProps = {
    onClose: Function.prototype,
  };

  private clientStore = ClientStore;

  private currentLoanStore = CurrentLoanStore;

  private closeTimerId = null;

  state = {
    step: 1, // bonus information step
    isDataLoaded: false,
    isAppRecalculationLoading: false,
    recalculationAmount: 0,
    isLoanClosed: false,
    success: false,
  };

  componentDidMount() {
    const { onClose } = this.props;

    LoanApi.getRecalculationAmount()
      .then((response) => {
        this.setState({
          recalculationAmount: response,
          isDataLoaded: true,
        });
      })
      .catch((error) => {
        ToastsStore.add({
          text: error.message,
          type: toasts.TYPE_ERROR,
        });

        // do not track close event in parent
        if (onClose) {
          onClose(false);
        }
      });
  }

  componentWillUnmount() {
    clearTimeout(this.closeTimerId);
  }

  recalculateLoan = () => {
    const { recalculationAmount } = this.state;
    const { onClose } = this.props;

    LoanApi.startRecalculation()
      .then(() => {
        if (recalculationAmount > 0) {
          this.setState({
            success: true,
          });
          this.closeTimerId = setTimeout(() => {
            // do not track close event in parent
            if (onClose) {
              onClose(false);
            }
          }, 3500);
        } else {
          this.setState({
            isLoanClosed: true,
          });
        }
      })
      .catch((error) => {
        ToastsStore.add({
          text: error.message,
          type: toasts.TYPE_ERROR,
        });
      });
  };

  goToDashboard = () => {
    const { onClose } = this.props;
    // do not track close event in parent
    if (onClose) {
      onClose(false);
    }
    RouteManager.goToDashboard();
  };

  goToStep2 = () => {
    this.setState({ step: 2 });
  };

  goToStep3 = (document) => {
    this.setState({
      isAppRecalculationLoading: true,
    });

    let error;

    Promise.all([
      LoanApi.setDocument(document).catch((e) => {
        error = e;
      }),
      new Promise((resolve) => setTimeout(resolve, 10000)),
    ]).then(() => {
      if (error == null) {
        this.setState({ step: 3, isAppRecalculationLoading: false });
        return;
      }

      ToastsStore.add({
        text: error.message,
        type: toasts.TYPE_ERROR,
      });

      this.goToDashboard();
    });
  };

  getBonusWarningType = () => {
    const issueDate = this.currentLoanStore.getValue('issueDate').get();
    const dueToDate = this.currentLoanStore.getValue('dueToDate').get();
    const bonuses = this.clientStore.getValue('bonuses').get();

    const issueDiff = moment().endOf('day').diff(moment(issueDate).endOf('day'), 'day');
    const dueToDiff = Math.abs(moment().endOf('day').diff(moment(dueToDate).endOf('day'), 'day'));

    if (issueDiff >= 15 && bonuses < 10) {
      return BONUSES_WARNING.TYPE_4;
    }

    if (issueDiff < 5) {
      return BONUSES_WARNING.TYPE_1;
    }

    if (issueDiff >= 5 && dueToDiff >= 3) {
      return BONUSES_WARNING.TYPE_2;
    }

    if (dueToDiff < 3) {
      return BONUSES_WARNING.TYPE_3;
    }

    return null;
  };

  render() {
    const {
      recalculationAmount,
      isAppRecalculationLoading,
      isDataLoaded,
      isLoanClosed,
      success,
      step,
    } = this.state;

    const { onClose } = this.props;

    const closeLoanAmount = this.currentLoanStore.getValue('closeLoanAmount').get();

    if (!isDataLoaded) {
      return <Loading />;
    }

    const warningType = this.getBonusWarningType();

    return (
      <Wrapper
        step={step}
        success={success}
        isLoanClosed={isLoanClosed}
        newAmount={recalculationAmount < 0 ? 0 : recalculationAmount}
        oldAmount={closeLoanAmount}
        recalculateButton={
          <Button
            uppercase
            onClick={this.recalculateLoan}
            data-qa="footer-recalculationAction-button"
          >
            {recalculationAmount <= 0 ? 'Погасить заем досрочно' : 'Пересчитать'}
          </Button>
        }
        cancelButton={
          <Button
            uppercase
            onClick={() => onClose(true)}
            data-qa="footer-recalculationCancel-button"
            noFill={step !== 1}
          >
            Отмена
          </Button>
        }
        goToDashboardButton={
          <Button
            uppercase
            onClick={this.goToDashboard}
            data-qa="footer-recalculationGoToDashboard-button"
          >
            OK
          </Button>
        }
        bonusWarningType={warningType}
        showBonusWarning={step === 1}
        appForRecalculation={
          step === 2 && (
            <AppForRecalculation
              isDataLoaded={!isAppRecalculationLoading}
              onClose={onClose}
              onContinue={this.goToStep3}
            />
          )
        }
        goToStep2Button={
          <Button onClick={this.goToStep2} data-qa="footer-recalculationGoToStep2-button">
            Потерять бонусы
          </Button>
        }
        goToBonusProgramButton={
          <Button
            uppercase
            onClick={RouteManager.goToBonusProgram}
            data-qa="footer-recalculationGoBonusProgram-button"
          >
            Подробнее
          </Button>
        }
      />
    );
  }
}
