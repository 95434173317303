import Cookie from 'js-cookie';

import * as m from 'const/marketing';
import * as c from 'const/cookies';

// eslint-disable-next-line import/no-default-export
export default class Marketing {
  // --- todo change static methods to different Metric Providers

  static pushUid(tokenValue = '') {
    const token = tokenValue || '';

    const data = this.parseJwt(token.replace(/^(\s)?Bearer(\s)?/, '')) || {};
    const UID = data.userId || '';

    this.GTMPush({ UID });
  }

  static parseJwt(token) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
    } catch (e) {
      return {};
    }
  }

  static GTMPush(object) {
    const counter = typeof window !== 'undefined' ? window[m.GTM_COUNTER] : null;

    if (counter && counter.push) {
      counter.push(object);
    }
  }

  static getGACookies() {
    return {
      _ga: Cookie.get(c.GA),
    };
  }

  static getUTMCookies(utmValue = c.UTM) {
    try {
      const cookie = Cookie.get(utmValue);
      return cookie ? JSON.parse(cookie) : {};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {};
    }
  }

  static getReferrer(referrerValue = c.REFERRER) {
    return Cookie.get(referrerValue) || '';
  }
}
