import Api from 'services/Api';

type Request = {
  photo: string;
  additionalPhoto?: string;
  marker: string;
};

type Response = true;

export function onlineVerification(data: Request): Promise<Response> {
  return Api.getInstance().post('client.onlineVerification', data);
}
