import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import Helpers from 'utils/Helpers';
import LogoLarge from 'ui/svg-icons/Logos/Large';
import {
  DZP_ABOUT,
  DZP_REVIEWS,
  DZP_DOCUMENTS,
  DZP_SECURITY,
  DZP_FAQ,
  DZP_GET,
  DZP_CARD,
  DZP_CASH,
} from 'const/urls';
import ClientStore from 'store/Client';
import { Loading } from 'ui/Loading/Loading';
import CurrentLoanStore from 'store/CurrentLoan';
import Modal from 'modules/Modal';
import * as env from 'const/env';

import { RecalculationModal } from './containers/RecalculationModal';
import { CompanyInfoStore } from './stores/CompanyInfoStore';
import appStore from './assets/appStore.svg';
import googlePlay from './assets/googlePlay.svg';
import huawei from './assets/huawei.svg';
import rustore from './assets/rustore.svg';

import {
  Wrap,
  Row,
  ColLogo,
  NavCol,
  NavRow,
  ColAdditionalInfo,
  LogoLink,
  GoogleTerms,
  Title,
  TitleLink,
  Menu,
  MenuItem,
  MenuLink,
  PhoneNumber,
  AppMenu,
  AppMenuItem,
  AppMenuLink,
  TitleButton,
  AppMenuImage,
} from './styled';

const PUBLIC_HOST = `https://${env.PUBLIC_DOMAIN}`;

/**
 * Компонент отображает футер на всех страницах.
 * @todo Убрать обертку ErrorBoundary и добавить @withErrorBoundary при миграции на NextJs.
 */
export const Footer: FC = observer(() => {
  const { pathname } = useLocation();

  const clientStore = ClientStore;
  const companyInfoStore = CompanyInfoStore.getInstance();
  const currentLoanStore = CurrentLoanStore;

  const [isRecalculationModalOpened, setIsRecalculationModalOpened] = useState(false);

  const { isLoggedIn } = clientStore;
  const {
    data: { phone },
    inProcess,
  } = companyInfoStore;

  const openRecalculateModal = () => {
    setIsRecalculationModalOpened(true);
  };

  const closeRecalculateModal = () => {
    setIsRecalculationModalOpened(false);
  };

  useEffect(() => {
    if (isLoggedIn) {
      companyInfoStore.fetch();
    }

    return () => {
      closeRecalculateModal();
      companyInfoStore.reset();
    };
  }, [isLoggedIn, companyInfoStore]);

  useEffect(() => {
    return () => {
      closeRecalculateModal();
    };
  }, [pathname]);

  const plainPhone = Helpers.onlyNumbers(phone);
  const showRecalculationButton = currentLoanStore.getValue('showRecalculationButton').get();

  return (
    <Wrap id="footer">
      {inProcess ? (
        <Loading />
      ) : (
        <>
          <Row>
            <ColLogo>
              <LogoLink href={`//${env.PUBLIC_DOMAIN}`}>
                <LogoLarge />
              </LogoLink>
            </ColLogo>
            <NavRow>
              <NavCol>
                <Title>
                  <TitleLink
                    href={`${PUBLIC_HOST}${DZP_ABOUT}`}
                    data-qa="footer-navigationAbout-link"
                  >
                    О компании
                  </TitleLink>
                </Title>
                <Menu>
                  <MenuItem>
                    <MenuLink
                      href={`${PUBLIC_HOST}${DZP_REVIEWS}`}
                      data-qa="footer-navigationReviews-link"
                    >
                      Отзывы
                    </MenuLink>
                  </MenuItem>
                  <MenuItem>
                    <MenuLink
                      href={`${PUBLIC_HOST}${DZP_DOCUMENTS}`}
                      data-qa="footer-navigationDocuments-link"
                    >
                      Документация
                    </MenuLink>
                  </MenuItem>
                  <MenuItem>
                    <MenuLink
                      href={`${PUBLIC_HOST}${DZP_SECURITY}`}
                      data-qa="footer-navigationReviews-link"
                    >
                      Безопасность
                    </MenuLink>
                  </MenuItem>
                </Menu>
                <Title>
                  <TitleLink href={`${PUBLIC_HOST}${DZP_FAQ}`} data-qa="footer-navigationFaq-link">
                    Вопросы и ответы
                  </TitleLink>
                </Title>
              </NavCol>
              <NavCol>
                <Title>
                  <TitleLink
                    href={`${PUBLIC_HOST}${DZP_GET}`}
                    data-qa="footer-navigationToGet-link"
                  >
                    Как получить
                  </TitleLink>
                </Title>
                <Menu>
                  <MenuItem>
                    <MenuLink
                      href={`${PUBLIC_HOST}${DZP_CARD}`}
                      data-qa="footer-navigationMap-link"
                    >
                      На карту
                    </MenuLink>
                  </MenuItem>
                  <MenuItem>
                    <MenuLink
                      href={`${PUBLIC_HOST}${DZP_CASH}`}
                      data-qa="footer-navigationCash-link"
                    >
                      Наличными
                    </MenuLink>
                  </MenuItem>
                  {!showRecalculationButton && (
                    <MenuItem>
                      <MenuLink
                        href={`${PUBLIC_HOST}${DZP_GET}`}
                        data-qa="footer-navigationHowToGet-link"
                      >
                        Все способы
                      </MenuLink>
                    </MenuItem>
                  )}
                </Menu>
                <Title>
                  <TitleLink href={`${PUBLIC_HOST}${DZP_FAQ}`} data-qa="footer-navigationFaq-link">
                    Как вернуть
                  </TitleLink>
                </Title>
                {showRecalculationButton && (
                  <TitleButton
                    onClick={openRecalculateModal}
                    data-qa="footer-recalculateOpenModal-button"
                  >
                    Перерасчет займа
                  </TitleButton>
                )}
              </NavCol>
            </NavRow>
            <ColAdditionalInfo>
              <PhoneNumber href={`tel:${plainPhone}`}>{phone}</PhoneNumber>
              <AppMenu>
                <AppMenuItem>
                  <AppMenuLink
                    /*href={urls.DZP_GOOGLE_PLAY}*/ href="https://trk.mail.ru/c/nqz7x0?mt_gaid=dozarplaticom"
                    data-qa="footer-googlePay-link"
                  >
                    <AppMenuImage src={googlePlay} alt="Google Play" />
                  </AppMenuLink>
                </AppMenuItem>
                <AppMenuItem>
                  <AppMenuLink
                    /*href={urls.DZP_APP_STORE}*/ href="https://trk.mail.ru/c/sxzhk4?mt_idfa=dozarplaticom"
                    data-qa="footer-appStore-link"
                  >
                    <AppMenuImage src={appStore} alt="App Store" />
                  </AppMenuLink>
                </AppMenuItem>
                <AppMenuItem>
                  <AppMenuLink
                    href="https://trk.mail.ru/c/b652w2?mt_gaid=lk_dozarplaticom"
                    data-qa="footer-appGallery-link"
                  >
                    <AppMenuImage src={huawei} alt="App Gallery" />
                  </AppMenuLink>
                </AppMenuItem>
                <AppMenuItem>
                  <AppMenuLink
                    href="https://trk.mail.ru/c/czopks8?mt_gaid=lk_dozarplaticom"
                    data-qa="footer-ruStore-link"
                  >
                    <AppMenuImage src={rustore} alt="RuStore" />
                  </AppMenuLink>
                </AppMenuItem>
              </AppMenu>
            </ColAdditionalInfo>
          </Row>
          <GoogleTerms>
            This site is protected by reCAPTCHA and the Google{' '}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </GoogleTerms>
          <Modal open={isRecalculationModalOpened} onRequestClose={closeRecalculateModal}>
            <RecalculationModal onClose={closeRecalculateModal} />
          </Modal>
        </>
      )}
    </Wrap>
  );
});
