import { styled } from 'const/stitches';

export const Wrap = styled('div', {
  width: '48px',
  height: '48px',
  margin: '0 auto',
  borderRadius: '50%',
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: '$color-icon-bg',
  svg: {
    width: '20px',
    fill: '$color-text-secondary',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
  },

  variants: {
    large: {
      true: {
        width: '120px',
        height: '120px',
        svg: {
          width: '40px',
        },
      },
    },
  },
});

export const Img = styled('div', {
  width: '100%',
  height: '100%',
});
