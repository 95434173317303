import React from 'react';

const Success = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <path
        fill="#22D22E"
        d="M24 42c9.9411255 0 18-8.0588745 18-18S33.9411255 6 24 6 6 14.0588745 6 24s8.0588745 18 18 18zm0 4C11.8497355 46 2 36.1502645 2 24S11.8497355 2 24 2s22 9.8497355 22 22-9.8497355 22-22 22zm-7.2656617-24.121423L14 24.6036555 22.6467355 33l1.3117041-1.307262L36 19.835633 33.1547315 17l-10.647449 10.484349-5.7729442-5.605772z"
      />
    </svg>
  );
};

export default Success;
