import React, { Component } from 'react';
import { Avatar } from 'ui/Avatar';
import Verified from 'ui/svg-icons/Verified';
import Star from 'ui/svg-icons/Star';
import * as urls from 'const/urls';
import Person from 'ui/svg-icons/Person';
import { Link } from '../../styled';
import { Wrap, Badge, Top, Name, Status, Code, Info } from './styled';

type Props = {
  img: string;
  badge: number;
  name: string;
  verified: boolean;
  clientId: string;
};

export class User extends Component<Props> {
  render() {
    const { img, badge, name, verified, clientId } = this.props;

    return (
      <Wrap>
        <Top>
          <Link to={urls.PROFILE} data-qa="leftPanelUser-profile-link">
            <Avatar large img={img} />
          </Link>
          <Link to={urls.BONUS_PROGRAM} data-qa="leftPanelUser-bonusProgram-link">
            <Badge>
              <Star width="12" />
              {badge}
            </Badge>
          </Link>
        </Top>

        <Name>{name}</Name>

        <Info>
          <Status verified={verified}>
            <Verified width="12" /> Личность {verified || 'не'} подтверждена
          </Status>
          {clientId && (
            <Code>
              <Person width="14" />
              Код клиента <span>{clientId}</span>
            </Code>
          )}
        </Info>
      </Wrap>
    );
  }
}
