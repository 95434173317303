import React, { Component } from 'react';

import * as color from 'const/colors';
import * as creditRating from 'const/creditRating';
import { Bar, Rate, RateValue, Row, Point } from './styled';

type Props = {
  rate: number;
  list?: boolean;
};

export class Rating extends Component<Props> {
  render() {
    const { rate, list } = this.props;
    const left = creditRating.getLeftPosition(rate);

    return (
      <div>
        <Bar>
          {rate > 0 && <RateValue rate={rate} left={left} />}
          <Rate color={rate ? color.RATE_TERRIBLE : '#717184'} />
          <Rate color={rate ? color.RATE_BAD : '#9595a3'} />
          <Rate color={rate ? color.PRIMARY : '#b8b8c1'} />
          <Rate color={rate ? color.RATE_NORMAL : '#c9c9d0'} />
          <Rate color={rate ? color.RATE_GOOD : '#dcdce0'} />
        </Bar>
        {list && (
          <Row>
            {creditRating.RANKS.map(({ max }) => (
              <Point key={max}>
                <span>{max}</span>
              </Point>
            ))}
          </Row>
        )}
      </div>
    );
  }
}
