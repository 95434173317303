export const RANKS = [
  {
    title: 'Новичок',
    minStars: 0,
    maxStars: 4,
  },
  {
    title: 'Умелец',
    minStars: 5,
    maxStars: 9,
  },
  {
    title: 'Эксперт',
    minStars: 10,
    maxStars: 34,
  },
  {
    title: 'Ценитель',
    minStars: 35,
    maxStars: 999,
  },
];

export enum BONUSES_WARNING {
  /**
   * Для займов, взятых от 1 до 5 дней назад с момента выдачи.
   */
  TYPE_1 = 1,

  /**
   * Для займов, взятых от 6 дня действия займа до 4 дней до окончания.
   */
  TYPE_2 = 2,

  /**
   * Для займов за 3 дня до окончания.
   */
  TYPE_3 = 3,

  /**
   * Для займов, взятых от 16 дней назад с момента выдачи и клиентов,
   * у кого накоплено < 10 бонусов.
   */
  TYPE_4 = 4,
}
