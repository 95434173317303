import { createStyled, createStyledBaseConditional } from 'utils/createStyled';
import styles from './RecalculationModal.scss';

export const Wrapper = createStyledBaseConditional('div', styles.wrapper, {
  isLoanClosed: styles.isLoanClosed,
});

export const RepaymentRow = createStyled('div', styles.repaymentRow);

export const FlexCell = createStyled('div', styles.flexCell);

export const ArrowFront = createStyled('div', styles.arrowFront);

export const ButtonsBlock = createStyledBaseConditional('div', styles.buttonBlock, {
  centered: styles.centered,
});

export const IconWrapper = createStyled('div', styles.iconWrapper);
