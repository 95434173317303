import React from 'react';

const MirPaymentSystem = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 20" {...props}>
      <defs>
        <linearGradient id="mir-a" x1=".008%" y1="50%" y2="50%">
          <stop offset="30%" stopColor="#00B4E6" />
          <stop offset="100%" stopColor="#088CCB" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(0 4)">
        <path
          fill="#4DB45E"
          d="M10.6911072,-2.41962038e-13 L10.6911072,0.00509461426 C10.686203,0.00509461426 9.14138704,-2.41962038e-13 8.72943612,1.53347889 C8.35181445,2.93959243 7.28760791,6.8216885 7.25818284,6.9286754 L6.96393219,6.9286754 C6.96393219,6.9286754 5.87520476,2.95997089 5.49267891,1.52838428 C5.08072799,-0.00509461427 3.53100787,-2.41962038e-13 3.53100787,-2.41962038e-13 L0,-2.41962038e-13 L0,11.6666667 L3.53100787,11.6666667 L3.53100787,4.73799127 L3.6781332,4.73799127 L3.82525852,4.73799127 L5.88501311,11.6666667 L8.33710191,11.6666667 L10.3968565,4.74308588 L10.6911072,4.74308588 L10.6911072,11.6666667 L14.222115,11.6666667 L14.222115,-2.41962038e-13 L10.6911072,-2.41962038e-13 Z"
        />
        <path
          fill="#4DB45E"
          d="M7.84668415,0 C7.84668415,0 6.81190268,0.096797671 6.3263891,1.22270742 L3.82525852,6.9286754 L3.53100787,6.9286754 L3.53100787,0 L0,0 L0,11.6666667 L3.33484076,11.6666667 L3.33484076,11.6666667 C3.33484076,11.6666667 4.41866401,11.5647744 4.9041776,10.4439592 L7.35626639,4.73799127 L7.65051705,4.73799127 L7.65051705,11.6666667 L11.1815249,11.6666667 L11.1815249,0 L7.84668415,0 Z"
          transform="translate(15.742)"
        />
        <path
          fill="#4DB45E"
          d="M0,0 L0,6.36826783 L3.53100787,6.36826783 L3.53100787,2.64919942 L7.35626639,2.64919942 C9.02368678,2.64919942 10.4360899,1.54366812 10.9608369,0 L0,0 Z"
          transform="translate(28.493 5.298)"
        />
        <path
          fill="url(#mir-a)"
          d="M7.89572593,0 L0.0441375984,0 C0.436471806,2.22125182 2.04013788,4.00436681 4.114605,4.58515284 L4.114605,4.58515284 C4.58540605,4.71761281 5.08072799,4.78893741 5.59076246,4.78893741 L11.6425176,4.78893741 C11.6964636,4.52401747 11.7209845,4.25400291 11.7209845,3.97379913 C11.7209845,1.77802038 10.0094265,0 7.89572593,0 Z"
          transform="translate(27.954)"
        />
      </g>
    </svg>
  );
};

export default MirPaymentSystem;
