import React from 'react';

const Success = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path
        fill="#22D22E"
        fillRule="nonzero"
        d="M23.999 0C37.252 0 48 10.746 48 23.999 48 37.252 37.252 48 23.999 48 10.746 48 0 37.252 0 23.999 0 10.746 10.746 0 23.999 0zm0 1.297C11.462 1.297 1.297 11.462 1.297 24c0 12.537 10.166 22.704 22.702 22.704 12.537 0 22.704-10.167 22.704-22.704 0-12.536-10.167-22.702-22.704-22.702zm-7.838 21.557l6.364 6.364 11.309-11.31 2.126 2.117L22.525 33.46l-8.485-8.485 2.121-2.121z"
      />
    </svg>
  );
};

export default Success;
