export const RANKS = [
  {
    title: 'Очень низкий',
    min: 1,
    max: 590,
  },
  {
    title: 'Низкий',
    min: 591,
    max: 696,
  },
  {
    title: 'Средний',
    min: 697,
    max: 810,
  },
  {
    title: 'Высокий',
    min: 811,
    max: 899,
  },
  {
    title: 'Очень высокий',
    min: 900,
    max: 999,
  },
];

export function getTitle(rating) {
  return RANKS.reduce((prev, current) => {
    if (rating >= current.min) {
      return current.title;
    }

    return prev;
  }, 'Отсутствует');
}

export function getIndex(rating) {
  return RANKS.reduce((prev, current, index) => {
    if (rating >= current.min) {
      return index;
    }

    return prev;
  }, 0);
}

export function getLeftPosition(rating) {
  const index = getIndex(rating);
  const itemWidthPercent = (1 / RANKS.length) * 100;

  const minRange = (index + 1) * itemWidthPercent - itemWidthPercent;
  const maxRange = (index + 1) * itemWidthPercent;

  const percentInRange =
    (rating - RANKS[index].min) / ((RANKS[index].max - RANKS[index].min) / 100);

  return minRange + (maxRange - minRange) * (percentInRange / 100);
}
