import React from 'react';

const Camera = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 16">
      <path d="M7.5 0h5a.766.766 0 0 1 .648.366l1.54 1.684h3.062A2.258 2.258 0 0 1 20 4.292v9.466A2.258 2.258 0 0 1 17.75 16H2.25A2.258 2.258 0 0 1 0 13.758V4.292A2.258 2.258 0 0 1 2.25 2.05h3.063L6.851.366A.78.78 0 0 1 7.5 0zm4.57 1.85H7.93L6.398 3.442a.766.766 0 0 1-.648.373H2.575c-.428 0-.75.32-.75.748v8.865c0 .427.322.748.75.748h14.718c.429 0 .75-.32.75-.748V4.564a.731.731 0 0 0-.75-.748H14.25a.766.766 0 0 1-.648-.373L12.07 1.849zM10 4.54c-2.338 0-4.25 1.905-4.25 4.235S7.662 13.01 10 13.01s4.25-1.905 4.25-4.235S12.338 4.54 10 4.54zm0 1.806a2.423 2.423 0 0 1 2.438 2.429A2.423 2.423 0 0 1 10 11.205a2.423 2.423 0 0 1-2.438-2.43A2.423 2.423 0 0 1 10 6.348z" />
    </svg>
  );
};

export default Camera;
