import { history } from 'routes';
import { toCamelCase } from 'utils/Helpers';

import { MetricStore } from './MetricStore';
import { Service as ClickhouseMetricService } from '../modules/common/ClickhouseMetric';

type Location = typeof history['location'];

/**
 * Хранилище состояния url
 */
export class LocationStore {
  /**
   * Сохраненное значение свойства instance.
   */
  private static instanceValue: LocationStore;

  /**
   * Экземпляр синглтона.
   */
  public static get instance() {
    if (this.instanceValue == null) {
      this.instanceValue = new this();
    }

    return this.instanceValue;
  }

  /**
   * Текущий url
   */
  protected currentLocation: string;

  /**
   * Создает экземпляр класса
   * Сохраняет точку входа на сайт в качестве текущего url
   */
  constructor() {
    if (typeof window !== 'undefined') {
      this.currentLocation = window.location.pathname;
    }
  }

  /**
   * Отправляет метрики при переходе между страницами
   */
  protected handleChange = (location: Location) => {
    if (this.currentLocation === location.pathname) {
      return;
    }
    const currentLocation = this.currentLocation.substr(1);
    const nextLocation = location.pathname.substr(1);

    if (currentLocation.indexOf('take-outer-loan') >= 0) {
      const arrCurrentLocation = currentLocation.split('/');
      const [url, id] = arrCurrentLocation;
      const prevPage = toCamelCase(url);
      const idPrevPage = toCamelCase(id);

      MetricStore.instance.send('page', 'leave', prevPage);
      ClickhouseMetricService.push(`${prevPage}_page_leave`, `${idPrevPage}`);
    } else {
      const prevPage = toCamelCase(currentLocation);
      MetricStore.instance.send('page', 'leave', prevPage);
      ClickhouseMetricService.push(`${prevPage}_page_leave`);
    }

    if (nextLocation.indexOf('take-outer-loan') >= 0) {
      const arrNextLocation = nextLocation.split('/');
      const [url, id] = arrNextLocation;
      const nextPage = toCamelCase(url);
      const idNextPage = toCamelCase(id);

      MetricStore.instance.send('page', 'enter', nextPage);
      ClickhouseMetricService.push(`${nextPage}_page_enter`, `${idNextPage}`);
    } else {
      const nextPage = toCamelCase(nextLocation);

      MetricStore.instance.send('page', 'enter', nextPage);
      ClickhouseMetricService.push(`${nextPage}_page_enter`);
    }

    this.currentLocation = location.pathname;
  };

  /**
   * Слушает переходы между страницами
   */
  public listen() {
    if (typeof window === 'undefined') {
      return;
    }

    history.listen(this.handleChange);
  }
}
