import React from 'react';

import Coins from 'ui/svg-icons/InCircle/Coins';
import Banknotes from 'ui/svg-icons/InCircle/Banknotes';
import SuitCase from 'ui/svg-icons/InCircle/SuitCase';

import * as calculator from 'const/calculator';
import * as loan from 'const/loan';

// eslint-disable-next-line import/no-default-export
export default (loanTariff) => {
  switch (loanTariff) {
    case calculator.TAB_NEW_CLIENT:
      return <Coins />;
    case calculator.TAB_REPEAT_CLIENT:
      return <Banknotes />;
    case loan.TYPE_FIRST_180_ADDITIONAL:
      return <Banknotes />;
    case calculator.TAB_PREMIUM_CLIENT:
      return <SuitCase />;
    default:
      return <Coins />;
  }
};

export const loanTitle = (loanTariff) => {
  switch (loanTariff) {
    case calculator.TAB_NEW_CLIENT:
      return 'Мини';
    case calculator.TAB_REPEAT_CLIENT:
      return 'Смарт';
    case calculator.TAB_PREMIUM_CLIENT:
      return 'Топ';
    default:
      return '';
  }
};
