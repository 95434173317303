import { createStyled, createStyledBaseConditional } from 'utils/createStyled';
import styles from './Layout.scss';

export * from './Container';

export const Wrap = createStyledBaseConditional('div', styles.wrap, {
  noPaddingTop: styles.noPaddingTop,
  isBlanked: styles.isBlanked,
});

export const Inner = createStyled('div', styles.inner);

export const Title = createStyled('h2', styles.title);

export const Text = createStyled('div', styles.text);

export const TextRow = createStyled('div', styles.textRow);

export const TextCell = createStyled('div', styles.textCell);

export const Top = createStyled('div', styles.top);

export const Paper = createStyledBaseConditional('div', styles.paper, {
  noGap: styles.noGap,
  withoutMT: styles.withoutMt,
  withoutMarginTop: styles.withoutMarginTop,
});

export const Content = createStyled('div', styles.content);

export const LoaderWrapper = createStyled('div', styles.loaderWrapper);

export const LoaderLogo = createStyled('div', styles.loaderLogo);
