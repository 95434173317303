import React, { Component, HTMLProps } from 'react';
import MobxFormStore from 'utils/MobxForm';

type InnerProps = {
  formState: MobxFormStore | null;
  passportState?: MobxFormStore | null;
  onSubmit?: (event?: React.FormEvent<HTMLFormElement & MobxForm>) => void;
};

type Props = InnerProps & Omit<HTMLProps<HTMLFormElement & MobxForm>, keyof InnerProps>;

export class MobxForm extends Component<Props> {
  static defaultProps = {
    autoComplete: 'on',
    onSubmit: () => null,
  };

  /**
   * Запуск валидации формы
   */
  onSubmit = async (event: React.FormEvent<HTMLFormElement & MobxForm>) => {
    event.preventDefault();

    const { formState, passportState, onSubmit } = this.props;
    if (formState) {
      await formState.validate({ showErrors: true });
    }

    if (passportState) {
      await passportState.validate({ showErrors: true });
    }

    if (onSubmit) {
      onSubmit(event);
    }
  };

  render() {
    const { autoComplete, children, onSubmit, formState, passportState, ...other } = this.props;

    return (
      <form {...other} autoComplete={autoComplete} onSubmit={this.onSubmit}>
        {children}
      </form>
    );
  }
}
