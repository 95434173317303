import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Laptop, OnlyTablet } from 'const/media';
import { UserBlank } from './components/User';
import { Rating } from './components/Rating';
import { LeftPanelTabletBlank } from './LeftPanelTabletBlank';
import { Aside, Block } from './styled';

@observer
export class LeftPanelBlank extends Component {
  render() {
    return (
      <>
        <OnlyTablet>
          <LeftPanelTabletBlank />
        </OnlyTablet>
        <Laptop>
          <Aside>
            <Block>
              <UserBlank />
            </Block>

            <Block>
              <Rating isEmpty />
            </Block>
          </Aside>
        </Laptop>
      </>
    );
  }
}
