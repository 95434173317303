import { createStyled, createStyledBaseConditional } from 'utils/createStyled';
import styles from './Modal.scss';

export const Overlay = createStyled('div', styles.overlay);

export const Box = createStyledBaseConditional('div', styles.box, {
  noGap: styles.noGap,
  positionTop: styles.positionTop,
});

export const Close = createStyledBaseConditional('button', styles.close, {
  withoutOpacity: styles.withoutOpacity,
});

export const DialogWrap = createStyled('div', styles.dialogWrap);

export const DialogButtons = createStyled('div', styles.dialogButtons);

export const Title = createStyled('p', styles.title);

export const Subtitle = createStyled('div', styles.subTitle);

export const Text = createStyledBaseConditional('p', styles.text, {
  centered: styles.centered,
});

export const FlexText = createStyledBaseConditional('p', styles.flexText, {
  centered: styles.centered,
});

export const PseudoLink = createStyled('a', styles.pseudoLink);
