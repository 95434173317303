import {
  createStyled,
  createStyledElementBase,
  createStyledBaseConditional,
} from 'utils/createStyled';
import { Badge as BadgeDefault } from 'ui/Common';
import styles from './User.scss';

export const Wrap = createStyled('div', styles.wrap);

export const Badge = createStyledElementBase(BadgeDefault, styles.badge);

export const Info = createStyled('div', styles.info);

export const Top = createStyled('div', styles.top);

export const Name = createStyled('div', styles.name);

export const Status = createStyledBaseConditional('div', styles.status, {
  verified: styles.verified,
});

export const Code = createStyled('div', styles.code);
