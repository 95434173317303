/**
 * Тип(основание) предоставления кредитных каникул
 */
export enum CreditHolidaysType {
  /**
   * Кредитные каникулы в связи с заболеванием коронавирусной инфекцией
   */
  Covid = 1,

  /**
   * Мобилизованный, пограничник, член семьи
   */
  Conscript = 2,

  /**
   * Контрактник, доброволец
   */
  Volunteer = 3,

  /**
   * Снижение дохода
   */
  IncomeReduction = 4,

  /**
   * Чрезвычайная ситуация
   */
  Emergency = 5,
}
