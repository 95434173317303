import React, { FC } from 'react';
import { observer } from 'mobx-react';
import ClientStore from 'store/Client';
import { CreditCardUtils } from 'utils/CreditCard';
import * as cardConst from 'const/creditCard';
import { CreditCard } from 'ui/CreditCard';
import * as color from 'const/colors';
import RouteManager from 'routes/manager';

interface Props {
  small?: boolean;
  hidden?: boolean;
}

export const CreditCardList: FC<Props> = observer((props) => (
  <>
    {ClientStore.creditCards.map((card) => {
      const bankTitle = card.getValue('nameRus') || card.getValue('nameEng') || '';
      const lastDigits = CreditCardUtils.getLastDigits(card.getValue('number'));

      const validThru = CreditCardUtils.getValidThru(
        card.getValue('expirationMonth'),
        card.getValue('expirationYear'),
      );

      const color1 = card.getValue('backgroundColorFirst') || cardConst.DEFAULT_CARD_COLOR_1;
      const color2 = card.getValue('backgroundColorSecond') || cardConst.DEFAULT_CARD_COLOR_2;

      const paymentSystem = CreditCardUtils.definePaymentSystem(card.getValue('number'));

      const logoPath = card.getValue('logoPath');

      return (
        <CreditCard
          key={card.id}
          logoUrl={logoPath}
          title={bankTitle}
          info={`* ${lastDigits} ${validThru}`}
          color={`linear-gradient(to bottom, ${color1}, ${color2})`}
          paymentSystem={paymentSystem}
          {...props}
        />
      );
    })}
    <CreditCard
      title="Добавить новую карту"
      addNew
      border={color.DISABLED}
      onClick={RouteManager.goToAddCard}
      {...props}
    />
  </>
));
