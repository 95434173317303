import { Form, Field, FormCombineSetup, FormParameters, FormSeparateSetup } from 'mobx-react-form';
import vjf from 'mobx-react-form/lib/validators/VJF';
import { trim } from 'utils/Helpers';

export class MobxFormStore<TFields extends string = string> extends Form<TFields> {
  constructor(setup: FormCombineSetup<TFields>, parameters?: FormParameters);

  // eslint-disable-next-line no-dupe-class-members
  constructor(setup: FormSeparateSetup<TFields>, parameters?: FormParameters);

  // eslint-disable-next-line no-dupe-class-members
  constructor(setup, parameters?) {
    super(setup, {
      ...parameters,
      plugins: {
        vjf: vjf(),
      },
      options: {
        showErrorsOnSubmit: true,
        validateOnChange: true,
        showErrorsOnReset: false,
        showErrorsOnClear: false,
        showErrorsOnChange: false,
        strictUpdate: true,
        validateOnChangeAfterInitialBlur: true,
        ...(parameters && parameters.options),
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  bindings() {
    return {
      default: {
        error: 'errorText',
      },
      formControl: ({ $try, field, props }) => ({
        id: $try(props.id, field.id),
        className: $try(props.className, field.error ? 'hasError' : ''),
        name: $try(props.name, field.name),
        errorText: $try(props.errorText, field.error),
        type: $try(props.type, field.type),
        value: $try(props.value, field.value),
        label: $try(props.label, field.label),
        placeholder: $try(props.placeholder, field.placeholder),
        disabled: $try(props.disabled, field.disabled),
        onChange: $try(props.onChange, field.onChange),
        onBlur: $try(props.onBlur, field.onBlur),
        onFocus: $try(props.onFocus, field.onFocus),
        autoFocus: $try(props.autoFocus, field.autoFocus),
      }),
      customCheckbox: {
        id: 'id',
        name: 'name',
        type: 'type',
        value: 'checked',
        label: 'label',
        placeholder: 'placeholder',
        disabled: 'disabled',
        onChange: 'onChange',
        onBlur: 'onBlur',
        onFocus: 'onFocus',
        autoFocus: 'autoFocus',
        error: 'errorText',
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  makeField(props) {
    const { data, ...other } = props;
    const patched = {
      ...other,
      data: {
        ...data,
        hooks: {
          ...data.hooks,
          /* trim spaces from all string fields */
          onBlur: (field: Field) => {
            if (typeof field.value === 'string') {
              field.set(trim(field.value));
            }
            if (data.hooks && data.hooks.onBlur) {
              data.hooks.onBlur(field);
            }
          },
        },
      },
    };
    return new Field(patched);
  }

  resetNested(fieldName, leaveOne = false) {
    const initialValue = this.state.initial.props.values[fieldName];
    const keys = [];
    this.$(fieldName).map((field) => {
      keys.push(field.key);
      return field;
    });

    if (keys.length > 0) {
      keys.forEach((key) => {
        this.$(fieldName).del(key);
      });

      if (leaveOne) {
        this.$(fieldName).add(initialValue[0]);
      }
    }
  }
}
