import React, { Component } from 'react';
import { observer } from 'mobx-react';

import ToastsStore from 'store/Toasts';

import InputFile from 'ui/FormControls/InputFileSimple';
import { Button } from 'ui/buttons/Button';
import DocumentIcon from 'ui/svg-icons/Document';

import { Title, Text } from 'modules/Modal/styled';
import * as color from 'const/colors';
import * as uploads from 'const/uploads';
import SampleRecalculationDoc from 'assets/files/sample_recalculation.pdf';
import { ApplicationCheckingLoader } from '../../components/ApplicationCheckingLoader';
import { ButtonsBlock } from '../../components/RecalculationModal/styled';
import { Documents, Link } from './styled';

type Props = {
  isDataLoaded?: boolean;
  continueButton?: React.ReactNode;
  cancelButton?: React.ReactNode;
  onClose?: () => void;
  onContinue?: (fileContent?) => void;
};

@observer
export class AppForRecalculation extends Component<Props> {
  state = {
    fileContent: null,
    fileName: '',
  };

  onContinue = () => {
    const { fileContent } = this.state;
    const { onContinue } = this.props;
    if (onContinue) {
      onContinue(fileContent);
    }
  };

  onSelectFile = (file) => {
    this.setState({
      fileContent: file.src,
      fileName: file.name,
    });
  };

  onError = (errorMessage) => {
    ToastsStore.addError({
      text: errorMessage,
    });
  };

  render() {
    const { fileName } = this.state;
    const { isDataLoaded, onClose } = this.props;

    if (!isDataLoaded) return <ApplicationCheckingLoader />;

    return (
      <>
        <Title>Пересчет займа</Title>
        <Text>
          Для перерасчёта займа: <br />
          1. Скачайте образец обращения. <br />
          2. Напишите обращение согласно образцу. <br />
          3. Загрузите подписанное обращение в текущее окно в удобном для вас формате – скан,
          фотография.
        </Text>

        <Documents>
          <DocumentIcon width="32" height="28" fill={color.BORDER} />
          <Link href={SampleRecalculationDoc} target="_blank">
            Образец обращения (.pdf)
          </Link>
        </Documents>

        <InputFile
          accept={[...uploads.PHOTO_ALLOWED_MIME_TYPES, uploads.PDF_MIME_TYPE]}
          fileName={fileName}
          maxSize={uploads.MAX_SIZE_APP_RECALCULATION_FILE_MB}
          style={{ maxWidth: 380 }}
          onLoadedEnd={this.onSelectFile}
          onError={this.onError}
          primary
        >
          Выбрать
        </InputFile>

        <Text>
          Формат файла pdf, jpeg, jpg, не более {uploads.MAX_SIZE_APP_RECALCULATION_FILE_MB} Мб
        </Text>

        <ButtonsBlock>
          <Button uppercase onClick={onClose}>
            Отмена
          </Button>
          <Button noFill uppercase onClick={this.onContinue} disabled={!fileName}>
            Продолжить
          </Button>
        </ButtonsBlock>
      </>
    );
  }
}
