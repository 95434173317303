import history from 'routes/history';
import Url from 'utils/Url';

import * as env from 'const/env';
import * as urls from 'const/urls';

// todo В будущем сделать для RouteManager именованный экспорт.
// eslint-disable-next-line import/no-default-export
export default class RouteManager {
  // todo In some cases replace is equal MouseEvent. Check logic.
  static goTo = ({ url = '', replace = false }) => {
    const target = decodeURIComponent(url);

    if (replace) {
      history.replace(target);
    } else {
      history.push(target);
    }
  };

  static goBack = () => {
    if (history.length > 1) {
      history.goBack();
    }
  };

  static setLoginRedirect(url: string | undefined | null) {
    if (typeof window !== 'undefined') {
      if (url != null) {
        window.localStorage.setItem('LOGIN_REDIRECT', url);
      } else {
        window.localStorage.removeItem('LOGIN_REDIRECT');
      }
    }
  }

  static getLoginRedirect() {
    if (typeof window === 'undefined') {
      return undefined;
    }

    const url = window.localStorage.getItem('LOGIN_REDIRECT');
    return (url as string) || undefined;
  }

  static goToLogin = (replace: any = false, params = {}) => {
    const url = Url.buildUrl(urls.LOGIN, params);
    RouteManager.goTo({ url, replace });
  };

  // todo In some cases replace is equal MouseEvent. Check logic.
  static goToDashboard = (replace: any = false) => {
    RouteManager.goTo({ url: urls.DASHBOARD, replace });
  };

  static goToApplicationSteps = (replace: any = false) => {
    RouteManager.goTo({ url: urls.TAKE_LOAN, replace });
  };

  static goToApplicationStep1 = (replace: any = false) => {
    RouteManager.goTo({ url: urls.TAKE_LOAN_STEP_1, replace });
  };

  static goToApplicationStep2 = (replace: any = false) => {
    RouteManager.goTo({ url: urls.TAKE_LOAN_STEP_2, replace });
  };

  static goToApplicationStep3 = (replace: any = false) => {
    RouteManager.goTo({ url: urls.TAKE_LOAN_STEP_3, replace });
  };

  static goToLoanUnderwriting = (replace: any = false) => {
    RouteManager.goTo({ url: urls.TAKE_LOAN_UNDERWRITING, replace });
  };

  // todo In some cases replace is equal MouseEvent. Check logic.
  static goToLoanClientIdentification = (replace: any = false) => {
    RouteManager.goTo({ url: urls.TAKE_LOAN_CLIENT_IDENTIFICATION, replace });
  };

  static goToLoanSignContract = (replace: any = false) => {
    RouteManager.goTo({ url: urls.TAKE_LOAN_SIGN_CONTRACT, replace });
  };

  static goToLoanChoosePaymentMethod = (replace: any = false) => {
    RouteManager.goTo({ url: urls.TAKE_LOAN_CHOOSE_PAYMENT_METHOD, replace });
  };

  static goToLoanChoosePaymentMethodNewCard = (replace: any = false) => {
    RouteManager.goTo({
      url: urls.TAKE_LOAN_CHOOSE_PAYMENT_METHOD_NEW_CARD,
      replace,
    });
  };

  static goToLoanPaidOut = (replace: any = false) => {
    RouteManager.goTo({
      url: urls.TAKE_LOAN_PAID_OUT,
      replace,
    });
  };

  static goToOfficesMap = (replace: any = false) => {
    RouteManager.goTo({ url: urls.OFFICES, replace });
  };

  static goToAdditionalIdentification = (replace: any = false) => {
    RouteManager.goTo({ url: urls.ADDITIONAL_IDENTIFICATION, replace });
  };

  static goToAddCard = (replace: any = false) => {
    RouteManager.goTo({ url: urls.ADD_CARD, replace });
  };

  static goToPromotions = (replace: any = false) => {
    RouteManager.goTo({ url: urls.PROMOTIONS, replace });
  };

  static goToChangeNumber = (replace: any = false) => {
    RouteManager.goTo({ url: urls.CHANGE_PHONE, replace });
  };

  static goToEvents = (replace: any = false) => {
    RouteManager.goTo({ url: urls.EVENTS, replace });
  };

  static goToBonusProgram = (replace: any = false) => {
    RouteManager.goTo({ url: urls.BONUS_PROGRAM, replace });
  };

  static goToAdditionalServices = (replace: any = false) => {
    RouteManager.goTo({ url: urls.ADDITIONAL_SERVICES, replace });
  };

  static goToAdditionalServicesSign = (replace, params) => {
    const url = Url.buildUrl(urls.ADDITIONAL_SERVICES_SIGN, params);
    RouteManager.goTo({ url, replace });
  };

  static goToAdditionalServicesSuccess = (replace, params) => {
    const url = Url.buildUrl(urls.ADDITIONAL_SERVICES_SUCCESS, params);
    RouteManager.goTo({ url, replace });
  };

  static goToAdditionalServicesFail = (replace, params) => {
    const url = Url.buildUrl(urls.ADDITIONAL_SERVICES_FAIL, params);
    RouteManager.goTo({ url, replace });
  };

  static goToAdditionalServicesReasonOfRefusal = (replace: any = false, params?) => {
    const url = Url.buildUrl(urls.ADDITIONAL_SERVICES_REASON_OF_REFUSAL, params);
    RouteManager.goTo({ url, replace });
  };

  static goToBrokerageSign = (replace, params) => {
    const url = Url.buildUrl(urls.ADDITIONAL_SERVICES_REFUSED_SIGN, params);
    RouteManager.goTo({ url, replace });
  };

  static goToProlongation = () => {
    // Синхронизация со старой версией пролонгации
    window.location.href = `//${env.PUBLIC_DOMAIN}${urls.DZP_ACCEPTANCE_PROLONGATION}`;
  };

  static goToPayment = () => {
    // Синхронизация со старой версией оплаты
    window.location.href = `//${env.PUBLIC_DOMAIN}${urls.DZP_ACCEPTANCE_PAYMENT}`;
  };

  /**
   * Вызывает переход на главную страницу кредитных каникул.
   */
  static goToCreditHolidays() {
    RouteManager.goTo({ url: urls.CREDIT_HOLIDAYS });
  }

  /**
   * Вызывает переход на страницу документов кредитных каникул.
   * @todo изменить url на CREDIT_HOLIDAYS_DOCUMENTS после полноценного внедрения MVP-1
   */
  static goToCreditHolidaysDocuments() {
    RouteManager.goTo({ url: urls.CREDIT_HOLIDAYS_ACTIVE });
  }

  static goToProlongationSign = ({ prolongationPeriod }) => {
    const url = Url.buildUrl(urls.PROLONGATION_SIGN, { prolongationPeriod });
    RouteManager.goTo({ url });
  };
}
