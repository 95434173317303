export class Singleton {
  private static instance;

  public static getInstance<T extends typeof Singleton>(this: T): InstanceType<T> {
    this.instance = this.instance || new this();
    return this.instance;
  }

  public dispose() {
    this.constructor.prototype.instance = null;
  }
}
