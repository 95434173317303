import React from 'react';

const Document = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20" {...props}>
      <path d="M13.597 0C14.921 0 15.996 1.104 16 2.46v15.08c0 1.356-1.079 2.46-2.403 2.46H2.403C1.079 20 0 18.896 0 17.54V2.46C0 1.104 1.079 0 2.403 0h11.194zm.683 17.536V2.46c0-.384-.308-.7-.683-.7H2.403a.694.694 0 0 0-.683.7v15.076c0 .383.308.699.683.699h11.194a.694.694 0 0 0 .683-.7zm-9.803-7.209a.84.84 0 1 1 0-1.683h3.407a.84.84 0 1 1 0 1.683H4.477zm5.711 3.04a.84.84 0 1 1 0 1.683h-5.71a.84.84 0 1 1 0-1.684h5.71zm1.103-9.217a.84.84 0 1 1 0 1.684H4.477a.84.84 0 1 1 0-1.684h6.814z" />
    </svg>
  );
};

export default Document;
