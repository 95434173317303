/** Функция загрузки сторонних скриптов через <script src /> */
export function loadScript(src: string, parameters?: Omit<Partial<HTMLScriptElement>, 'src'>) {
  return new Promise<boolean>((resolve, reject) => {
    if (typeof document === 'undefined') {
      resolve(false);
      return;
    }

    /** скрипт уже добавлен */
    if (document.body.querySelector(`[src="${src}"]`)) {
      resolve(true);
      return;
    }

    const defaultParameters: Partial<HTMLScriptElement> = {
      src,
      type: 'text/javascript',
    };

    const script = document.createElement('script');
    Object.assign(script, defaultParameters, parameters);
    script.onload = () => resolve(true);
    script.onerror = (event, source, line, col, error) => reject(error);

    document.body.appendChild(script);
  });
}
