import React, { ComponentClass } from 'react';

import { ErrorBoundary } from 'modules/ErrorBoundary';

// eslint-disable-next-line import/no-default-export
export default function withErrorBoundary<C extends ComponentClass>(WrappedComponent: C): C {
  return (((props) => (
    <ErrorBoundary>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  )) as any) as C;
}
