import Api from 'services/Api';

interface Request {
  socialName: string;
}

type Response = true;

export function deleteSocial(data: Request): Promise<Response> {
  return Api.getInstance().post('client.deleteSocial', data);
}
