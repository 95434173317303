import Api from 'services/Api';

type Request= {
  number: number;
};

type Response = true;

export function addPromo(data: Request): Promise<Response> {
  return Api.getInstance().post('client.addPromo', data);
}
