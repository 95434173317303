import { Singleton } from 'utils/singleton';
import * as env from 'const/env';

declare global {
  interface Window {
    firebase?;
  }
}

class Firebase extends Singleton {
  public onMessageCallBack = Function.prototype;

  public onTokenRefreshCallBack = Function.prototype;

  private readonly messaging;

  constructor() {
    super();
    try {
      this.messaging = window.firebase.messaging();
      this.messaging.usePublicVapidKey(env.FIREBASE_PUBLIC_VAPID_KEY);
      this.messaging.onMessage(this.onMessage);
      this.messaging.onTokenRefresh(this.onTokenRefresh);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  requestPermissions(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.messaging && window.firebase.messaging.isSupported()) {
        this.messaging
          .requestPermission()
          .then(() => resolve(true))
          .catch(() => resolve(false));
      } else {
        resolve(false);
      }
    });
  }

  getToken() {
    return new Promise((resolve) => {
      if (this.messaging && window.firebase.messaging.isSupported()) {
        this.messaging
          .getToken()
          .then(resolve)
          .catch(() => resolve());
      } else {
        resolve();
      }
    });
  }

  onMessage(payload) {
    this.onMessageCallBack(payload);
  }

  onTokenRefresh() {
    this.onTokenRefreshCallBack();
  }
}

// eslint-disable-next-line import/no-default-export
export default Firebase.getInstance();
