import React from 'react';

const SuitCase = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" {...props}>
      <circle fill="#F2F2FB" cx="24" cy="24" r="24" />
      <path
        d="M32 18h-4v-2c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2h-4c-1.11 0-1.99.89-1.99 2L14 31c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V20c0-1.11-.89-2-2-2zm-6 0h-4v-2h4v2zm-3 9h3v1h-3v2h-1v-2h-1v-1h1v-1h-1v-1h1v-2h-1v-1h4a2 2 0 1 1 0 4h-2v1zm0-4v2h2a1 1 0 0 0 0-2h-2z"
        fill="#A2A2B2"
      />
    </svg>
  );
};

export default SuitCase;
