import Model from './Model';

export default class CalculatorTariffModel extends Model<CalculatorTariffModel> {
  // Tariff id
  public tariffId = 0;

  // Tariff title
  public title = '';

  // Tariff subtitle
  public subtitle = '';

  // Tariff periodType (day, week, etc.)
  public periodType = 0;

  // Tariff icon type
  public iconType = 0;

  // Tariff range
  public range = [];

  // Tariff default slider values
  public defaultValues = {
    amount: 0,
    period: 0,
  };

  // Tariff description
  public description = [];

  public constructor(data?: Partial<CalculatorTariffModel>) {
    super();
    this.init(data);
  }
}
