import Api from 'services/Api';

interface Request {
  status: number;
  firstName: string;
  lastName: string;
  patronymic: string;
}

type Response = true;

export function setAbandonedState(data: Request): Promise<Response> {
  return Api.getInstance().post('client.setAbandonedState', data);
}
