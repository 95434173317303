import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { UserTabletBlank } from './components/User';
import { Arrow, AsideTablet } from './styled';

@observer
export class LeftPanelTabletBlank extends Component<{}, LeftPanelTabletBlank['state']> {
  state = {
    opened: false,
  };

  toggleAside = () => {
    this.setState((prevState) => ({
      opened: !prevState.opened,
    }));
  };

  render() {
    const { opened } = this.state;

    return (
      <AsideTablet onClick={this.toggleAside} opened={opened}>
        <Arrow opened={opened} />
        <UserTabletBlank opened={opened} />
      </AsideTablet>
    );
  }
}
