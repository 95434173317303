import Api from 'services/Api';

type Response = {
  url: string;
  // todo check type;
};

export function prepareBindingCreditCard(): Promise<Response> {
  return Api.getInstance().post('client.prepareBindingCreditCard', { source: 'ver3' });
}
