import Api from 'services/Api';

type Request = {
  type: number;
};

// todo check type
type Response = Array<any>;

export function getQuestions(data: Request): Promise<Response> {
  return Api.getInstance().post('client.getQuestions', data);
}
