import React from 'react';

const Cross = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path d="M8 6.598L1.692.29A.991.991 0 0 0 .29 1.692L6.598 8 .29 14.308a.991.991 0 0 0 1.402 1.402L8 9.402l6.308 6.308a.991.991 0 0 0 1.402-1.402L9.402 8l6.308-6.308A.991.991 0 0 0 14.308.29L8 6.598z" />
    </svg>
  );
};

export default Cross;
