import React from 'react';
import ReactDOM from 'react-dom';

// eslint-disable-next-line import/no-default-export
export default class Portal extends React.Component {
  private getPortalNode() {
    let portal = document.getElementById('portalRoot');

    if (!portal) {
      portal = document.createElement('div');
      portal.id = 'portalRoot';
      document.body.appendChild(portal);
    }

    return portal;
  }

  public render() {
    const { children } = this.props;
    const portal = this.getPortalNode();

    return ReactDOM.createPortal(children, portal);
  }
}
