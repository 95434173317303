import { action, computed, observable } from 'mobx';
import { get } from 'services/backend/Company';
import { Singleton } from 'utils/singleton';

const defaultData = {
  name: 'До Зарплаты',
  fullName: 'ООО МКК «ДЗП-Центр»',
  governmentRegistryNumber: '№651403140005467',
  inn: '7838500558',
  ogrn: '1147847029990',
  phone: '8 (812) 426-77-72',
  address:
    '©2012 – 2022 ООО МКК «ДЗП-Центр», 190031, г. Санкт-Петербург, пер. Спасский, д. 14/35, лит. А, пом. 38 Н.',
  freePhone: '8 800 333 10 60',
  promoPhone: '8 (383) 207-83-43',
  documentList: {
    recurrent_payments_agreement: '',
  },
};

export class CompanyInfoStore extends Singleton {
  @observable
  public inProcess = false;

  @observable
  public data: typeof defaultData = defaultData;

  @observable
  public hasSuccess = false;

  @computed
  public get isPrimaryCompany() {
    return /до зарплаты/i.test(this.data.name);
  }

  private fetching: Promise<void> = null;

  public touch() {
    return this.hasSuccess ? Promise.resolve() : this.fetch();
  }

  @action
  public reset() {
    this.hasSuccess = false;
    this.fetching = null;
  }

  @action
  public fetch = async () => {
    this.fetching =
      this.fetching ||
      (async () => {
        this.reset();
        this.inProcess = true;
        try {
          const data = await get();
          this.data = {
            ...defaultData,
            ...data,
          };
          this.hasSuccess = true;
        } finally {
          this.inProcess = false;
        }
      })();
    return this.fetching;
  };
}
