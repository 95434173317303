import {observable, action} from 'mobx';

class NotificationStore {
  @observable
  public paymentEvent: boolean = false;

  @action
  public changePaymentEventState(state: boolean) {
    this.paymentEvent = state;
  }
}

export default new NotificationStore();