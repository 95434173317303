import React, { Component } from 'react';
import { CARD_TYPE } from 'const/creditCard';
import { Button, Card, Plus, Logo, Type, Title, Info, Outline } from './styled';
import { PaymentSystem } from './PaymentSystem';

type Props = {
  logo?: React.ReactNode;
  logoUrl?: string;
  title?: string;
  info?: string;
  color?: string;
  border?: string;
  paymentSystem?: CARD_TYPE;
  addNew?: boolean;
  small?: boolean;
  hidden?: boolean;
  highlighted?: boolean;
  onClick?: () => void;
};

export class CreditCard extends Component<Props> {
  render() {
    const {
      logo,
      logoUrl,
      highlighted,
      title,
      info,
      color,
      border,
      paymentSystem,
      addNew,
      small,
      hidden,
      onClick,
    } = this.props;
    return (
      <Button
        msTitle={title}
        onClick={onClick}
        small={small}
        highlighted={highlighted}
        addNew={addNew}
        data-qa="creditCard-action-button"
      >
        <Outline highlighted={highlighted} small={small}>
          <Card border={border} bg={color}>
            {addNew && <Plus width="24" />}

            {logoUrl && (
              <Logo>
                {logoUrl && <img alt="" src={logoUrl} />}
                {logo && logo}
              </Logo>
            )}

            {paymentSystem && (
              <Type small={small}>
                <PaymentSystem type={paymentSystem} />
              </Type>
            )}
          </Card>
        </Outline>
        <div>
          {title && (
            <Title closed={hidden} small={small}>
              {title}
            </Title>
          )}
          {info && (
            <Info closed={hidden} small={small}>
              {info}
            </Info>
          )}
        </div>
      </Button>
    );
  }
}
