import { CustomError } from 'utils/errors';

/**
 * Ошибка, возникающая, когда сервис не инициализирован перед его
 * использованием.
 */
export class MyTargetNotInitializedError extends CustomError {
  /**
   * Создает экземпляр класса.
   */
  public constructor() {
    super(`Service is not initialized`);
  }
}
