import * as env from 'const/env';

export default class Logger {
  static log() {
    if (env.IS_UNDER_DEVELOPMENT) {
      console.log.apply(console, arguments);
    }
  }

  static warn() {
    if (env.IS_UNDER_DEVELOPMENT) {
      console.warn.apply(console, arguments);
    }
  }

  static error() {
    if (env.IS_UNDER_DEVELOPMENT) {
      console.error(console, arguments);
    }
  }
}
