import moment from 'moment';

import { CARD_TYPE } from 'const/creditCard';

export class CreditCardUtils {
  static definePaymentSystem(cardNumber) {
    if (!cardNumber) return CARD_TYPE.UNKNOWN;

    const digit = +cardNumber.substr(0, 1);

    switch (digit) {
      case 2:
      case 3:
        return CARD_TYPE.MIR;
      case 4:
        return CARD_TYPE.VISA;
      case 5:
        return CARD_TYPE.MASTERCARD;
      default:
        return CARD_TYPE.UNKNOWN;
    }
  }

  static getLastDigits(number) {
    return String(number).slice(-4);
  }

  static getValidThru(month, year) {
    // Тинькофф не передает эти данные
    if (month === null || month === 0 || year === null || year === 0) {
      return '';
    }

    return `${month}/${year > 100 ? year - 2000 : year}`;
  }

  static isExpiredCard(month, year) {
    // Тинькофф не передает эти данные
    if (month === null && year === null) {
      return true;
    }

    const now = moment().startOf('month');
    const cardValidThru = moment(`01.${month}.20${year > 100 ? year - 2000 : year}`, 'DD.MM.YYYY');
    return now.isAfter(cardValidThru);
  }
}
