import React from 'react';

const Star = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
      <path d="M6 9.963l-3.708 1.95L3 7.783 0 4.86l4.146-.602L6 .5l1.854 3.757L12 4.859 9 7.784l.708 4.129z" />
    </svg>
  );
};

export default Star;
