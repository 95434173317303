// import { ABGroup, ABTest, UserGroup } from './const';
// import { ABTestStore } from './ABTestStore';

/**
 * Производит перевод номера группы в строковое название.
 * @param abTestGroup номер тестовой группы.
 * @returns строковое название группы.
 */
// function assembleABTest(abTestGroup: ABGroup): UserGroup | undefined {
//   if (abTestGroup === ABGroup.a) {
//     return UserGroup.Test;
//   }

//   if (abTestGroup === ABGroup.b) {
//     return UserGroup.Basic;
//   }

//   if (abTestGroup === ABGroup.c) {
//     return UserGroup.Control;
//   }

//   return undefined;
// }

/**
 * Возвращает название группы из типа `UserGroup`.
 */
export function getCurrentUserGroup() {
  // const currentGroup = ABTestStore.getInstance().getABGroup(ABTest.DWN_17408, true);

  // return assembleABTest(currentGroup);

  return undefined;
}
