import styled, {css} from 'styled-components';

import * as color from 'const/colors';

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  padding: 0.75rem 0.18rem 0.75rem 0.75rem;
  font-size: 14px;
  line-height: 1.15em;
  border: 1px solid ${color.BORDER};
  border-radius: 4px;
  margin-bottom: 0.5rem;
`;

const internalText = () => `
  display: inline-block;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Placeholder = styled.div`
  ${internalText};
  color: ${color.GRAY_60};
`;

export const Value = styled.div`
  ${internalText};
  color: ${color.GRAY_80};
`;

export const Button = styled.div`
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  min-width: 144px;
  height: 32px;
  padding: 8px;
  color: ${color.TEXT_PRIMARY};
  background: #efefef;
  text-align: center;
  text-transform: uppercase;
  box-sizing: border-box;
  overflow: hidden;
  transition: .15s ease-out;
  white-space: nowrap;
  cursor: pointer;
`;

export const InputFile = styled.input`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
`;
