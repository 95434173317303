import React from 'react';
import { css } from 'styled-components';
import Responsive from 'react-responsive';

export const MOBILE_BREAKPOINT = 420;
export const TABLET_BREAKPOINT = 720;
export const TABLET_LANDSCAPE_BREAKPOINT = 1024;
export const LAPTOP_BREAKPOINT = 1280;
export const DESKTOP_BREAKPOINT = 1600;

export const footer = (...args) => css`
  @media (min-width: 920px) {
    ${css(...args)}
  }
`;

export const mobileOnly = (...args) => css`
  @media (max-width: ${TABLET_BREAKPOINT - 1}px) {
    ${css(...args)}
  }
`;

export const mobilePortraitOnly = (...args) => css`
  @media (max-width: ${MOBILE_BREAKPOINT - 1}px) {
    ${css(...args)}
  }
`;

export const mobile = (...args) => css`
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    ${css(...args)}
  }
`;

export const tablet = (...args) => css`
  @media (min-width: ${TABLET_BREAKPOINT}px) {
    ${css(...args)}
  }
`;

export const tabletOnly = (...args) => css`
  @media (max-width: ${TABLET_LANDSCAPE_BREAKPOINT - 1}px) {
    ${css(...args)}
  }
`;

export const tabletTabletLandscape = (...args) => css`
  @media (min-width: ${TABLET_BREAKPOINT}px) and (max-width: ${TABLET_LANDSCAPE_BREAKPOINT - 1}px) {
    ${css(...args)}
  }
`;

export const tabletL = (...args) => css`
  @media (min-width: ${TABLET_LANDSCAPE_BREAKPOINT}px) {
    ${css(...args)}
  }
`;

export const laptop = (...args) => css`
  @media (min-width: ${LAPTOP_BREAKPOINT}px) {
    ${css(...args)}
  }
`;

export const desktop = (...args) => css`
  @media (min-width: ${DESKTOP_BREAKPOINT}px) {
    ${css(...args)}
  }
`;

export const maxDesktop = (...args) => css`
  @media (max-width: ${DESKTOP_BREAKPOINT - 1}px) {
    ${css(...args)}
  }
`;

export const Desktop = (props) => <Responsive {...props} minWidth={DESKTOP_BREAKPOINT} />;
export const UntilDesktop = (props) => <Responsive {...props} maxWidth={DESKTOP_BREAKPOINT - 1} />;
export const Laptop = (props) => <Responsive {...props} minWidth={LAPTOP_BREAKPOINT} />;
export const UntilLaptop = (props) => <Responsive {...props} maxWidth={LAPTOP_BREAKPOINT - 1} />;
export const TabletL = (props) => <Responsive {...props} minWidth={TABLET_LANDSCAPE_BREAKPOINT} />;
export const UntilTabletL = (props) => (
  <Responsive {...props} maxWidth={TABLET_LANDSCAPE_BREAKPOINT - 1} />
);
export const Tablet = (props) => <Responsive {...props} minWidth={TABLET_BREAKPOINT} />;
export const UntilTablet = (props) => <Responsive {...props} maxWidth={TABLET_BREAKPOINT - 1} />;
export const MobileTablet = (props) => (
  <Responsive {...props} maxWidth={TABLET_LANDSCAPE_BREAKPOINT - 1} />
);
export const OnlyMobile = (props) => <Responsive {...props} maxWidth={TABLET_BREAKPOINT - 1} />;
export const OnlyTablet = (props) => (
  <Responsive {...props} minWidth={TABLET_BREAKPOINT} maxWidth={LAPTOP_BREAKPOINT - 1} />
);
export const OnlyLaptop = (props) => (
  <Responsive {...props} minWidth={LAPTOP_BREAKPOINT} maxWidth={DESKTOP_BREAKPOINT - 1} />
);
