type RequestHandler = <T>(request?: T) => T;
type ProcessResponse = (response: Response, request?: Request) => any;

type FetchParams = {
  url: string;
  rpcMethod?: string;
  method: string;
  body?: any;
  credentials?: RequestCredentials;
  params?: Record<string, any>;
  headers?: Record<string, any>;
  forceUpdate?: boolean;
  signal?: AbortSignal;
};

export class Fetcher {
  private mode: 'cors' | 'no-cors' = 'cors';

  private rpcMap = new Map<string, Promise<Response>>();

  private debounceTimerMap = new Map<string, number>();

  private headers: Record<string, string> = {};

  private useDebounceHandler: (request: FetchParams) => 0 | number = () => 0;

  private beforeHandler: RequestHandler = (request) => request;

  private responseHandler: ProcessResponse = (response) => response;

  private afterHandler: RequestHandler = (request?) => request;

  private networkErrorHandler: (error) => Error = (error) => {
    this.afterHandler();
    return error;
  };

  private deleteRequestFromRPCMap = (rpcMethod: string) => {
    this.rpcMap.delete(rpcMethod);
    clearTimeout(this.debounceTimerMap.get(rpcMethod));
  };

  public addHeaders(headers: Record<string, string>) {
    this.headers = { ...this.headers, ...headers };
  }

  public removeHeader(header: string) {
    delete this.headers[header];
  }

  public before(fn: RequestHandler) {
    this.beforeHandler = fn;
  }

  public after(fn: RequestHandler) {
    this.afterHandler = fn;
  }

  public processResponse(fn: ProcessResponse) {
    this.responseHandler = fn;
  }

  public onNetworkError(fn: (error) => Error) {
    this.networkErrorHandler = fn;
  }

  public doAfter(response?) {
    return this.afterHandler(response);
  }

  public useDebounce(fn: (request: FetchParams) => 0 | number) {
    this.useDebounceHandler = fn;
  }

  private async request<ExpectedResponse>(request: FetchParams) {
    await this.beforeHandler(request);

    const response = await (async () => {
      try {
        return await this.fetch(request);
      } catch (e) {
        throw this.networkErrorHandler(e);
      }
    })();

    // .clone() is allowing use .json() several times for the same response
    const processed = await this.responseHandler(response.clone());
    await this.afterHandler(processed);

    return processed as ExpectedResponse;
  }

  private async fetch(request: FetchParams) {
    let queryString = Object.keys(request.params || {})
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(request.params[key])}`)
      .join('&');

    queryString = queryString ? `?${queryString}` : queryString;

    const { rpcMethod, forceUpdate } = request;

    if (rpcMethod && this.rpcMap.has(rpcMethod) && !forceUpdate) {
      // if response is in map, means we should reuse it
      return this.rpcMap.get(rpcMethod);
    }
    this.deleteRequestFromRPCMap(rpcMethod);
    const fetchPromise = fetch(request.url + queryString, {
      credentials: request.credentials,
      method: request.method,
      headers: { ...this.headers, ...request.headers },
      mode: this.mode,
      body: request.body && request.method !== 'GET' ? JSON.stringify(request.body) : request.body,
      signal: request.signal,
    }).then((response) => {
      // get debounce value for request
      const debounceCount = this.useDebounceHandler(request);

      if (rpcMethod) {
        // we should clear map after debounce or immediately to allow fetch fresh data
        if (debounceCount > 0) {
          this.debounceTimerMap.set(
            rpcMethod,
            (setTimeout(
              this.deleteRequestFromRPCMap,
              debounceCount,
              rpcMethod,
            ) as unknown) as number,
          );
        } else {
          this.deleteRequestFromRPCMap(rpcMethod);
        }
      }

      return response;
    });

    // save response object to reuse it if it won't be resolved until next request
    if (rpcMethod) {
      this.rpcMap.set(rpcMethod, fetchPromise);
    }

    return fetchPromise;
  }

  public post<ExpectedResponse = any>(request) {
    return this.request<ExpectedResponse>({ ...request, method: 'POST' });
  }

  public get<ExpectedResponse = any>(request) {
    return this.request<ExpectedResponse>({ ...request, method: 'GET' });
  }

  public put(request) {
    return this.request({ ...request, method: 'PUT' });
  }

  public delete(request) {
    return this.request({ ...request, method: 'DELETE' });
  }
}
