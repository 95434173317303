import Api from 'services/Api';
import { CreditHolidaysType } from 'const/creditHolidays';

type Response = {
  id: number;
  verified: boolean;
  onVerifying: boolean;
  firstName: string;
  lastName: string;
  patronymic: string;
  email: string;
  showEmailWarning: boolean;
  mobilePhone: string;
  photo: string; // --- base64
  interestRate: number;
  isNew: boolean;
  birthDate: string; // --- dd.mm.yyyy
  nextLoanFreeDays: number;
  haveKoronaCard: boolean;
  toFreeProlongation: number;
  toFreeLoan: number;
  shortLoan: boolean;
  haveApplications: boolean;
  bonuses: number;
  promoPoints: number;
  socials: Array<any>;
  creditRating: number;
  showWelcomePopUp: boolean;
  identificationCode: string;
  esiaLock: boolean;
  availablePromos;
  isCacheEnabled: boolean;
  isCachedResult: boolean;
  /* активированы ли кредитные каникулы */
  isCreditHolidaysEnabled: boolean;
  /* ссылка на соглашение о кредитных каникулах */
  creditHolidaysNotificationDocumentUrl: string | null;
  /* тип кредитных каникул */
  creditHolidaysType: CreditHolidaysType | null;
  isAllowCreateLoan: boolean;
};

export function getProfile(): Promise<Response> {
  return Api.getInstance().post('client.getProfile');
}
