import Api from './Api';

export declare namespace FillStepThree {
  interface Request {
    noWork: boolean;
    employer: string;
    employerPhone: string;
    position: string;
    experience: string;
    salaryOfficial: string;
    salaryDate: string;
    haveOtherCredit: boolean;
    otherCredit: {
      paymentsCount: number;
      avgPaymentAmount: number;
    } | null;
  }

  type Response = true;
}

export declare namespace GetDocumentUrl {
  type Agreements = {
    [k: string]: boolean | Agreements;
  };

  type Request = {
    agreements: Agreements;
  };

  type Response = {
    url: string; // base64
  };
}

class ApplicationApi extends Api {
  getApplicationSteps() {
    return this.post('application.getApplicationSteps');
  }

  getApplicationStepsGroupB() {
    return this.post('application.getApplicationStepsB');
  }

  /**
   * Метод добавлен в рамках задачи https://jira.devim.team/browse/DWN-14004.
   */
  getApplicationStepsGroupC() {
    return this.post('application.getApplicationStepsC');
  }

  /**
   * Метод добавлен в рамках задачи https://jira.devim.team/browse/DWN-15082.
   */
  getApplicationStepsGroupD() {
    return this.post('application.getApplicationStepsD', undefined, true);
  }

  /**
   * Изменение в рамках задачи https://jira.devim.team/browse/DWN-14004
   */
  getApplicationStepsRepeatC() {
    return this.post('application.getApplicationStepsRepeatC');
  }

  /**
   * Метод добавлен в рамках задачи https://jira.devim.team/browse/DWN-15082.
   */
  getApplicationStepsRepeatD() {
    return this.post('application.getApplicationStepsRepeatD');
  }

  getStepsData() {
    return this.post('application.getStepsData');
  }

  fillStepOne(fields) {
    return this.post('application.fillStep1', fields);
  }

  fillStepOneB(fields) {
    return this.post('application.fillStep1b', fields);
  }

  fillStepTwo(fields) {
    return this.post('application.fillStep2', fields);
  }

  fillStepTwoGroupB(fields) {
    return this.post('application.fillStep2b', fields);
  }

  /*
   * Метод добавлен в рамках задачи https://jira.devim.team/browse/DWN-14004
   */
  fillStepTwoGroupC(fields) {
    return this.post('application.fillStep2c', fields);
  }

  fillStepThree = (fields: FillStepThree.Request): Promise<FillStepThree.Response> => {
    return this.post('application.fillStep3', fields);
  };

  fillStepThreeGroupB(fields) {
    return this.post('application.fillStep3b', fields);
  }

  /*
   * Изменение в рамках задачи https://jira.devim.team/browse/DWN-14004
   */
  fillStepThreeC = (fields: FillStepThree.Request): Promise<FillStepThree.Response> => {
    return this.post('application.fillStep3c', fields);
  };

  /* Получить ссылку на анкету-заявку */
  getDocumentUrl = (values?: GetDocumentUrl.Request): Promise<GetDocumentUrl.Response> => {
    return this.post('application.getDocumentUrl', values);
  };

  getApplicationStepsNew() {
    return this.post('application.getApplicationStepsNew');
  }
}

// eslint-disable-next-line import/no-default-export
export default new ApplicationApi();
