import React from 'react';
import { Avatar } from 'ui/Avatar';
import { Wrap, Top } from './styled';

type Props = {
  opened?: boolean;
};

export const UserTabletBlank: React.FC<Props> = ({ opened }) => {
  return (
    <Wrap closed={!opened}>
      <Top>
        <Avatar />
      </Top>
    </Wrap>
  );
};
