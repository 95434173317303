import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Star from 'ui/svg-icons/Star';
import ClientStore from 'store/Client';
import * as urls from 'const/urls';
import { CreditCardList } from 'modules/common/CreditCardList';
import { LoanInfo } from './containers/LoanInfo';
import { Arrow, AsideTablet, Box, Cards, Row, Cell, Text, Badge, Link, AsideExit } from './styled';
import { UserTablet } from './components/User';

type State = {
  opened: boolean;
};

@observer
export class LeftPanelTablet extends Component<{}, State> {
  state = {
    opened: false,
  };

  private clientStore = ClientStore;

  toggleAside = () => {
    const { opened } = this.state;
    this.setState({
      opened: !opened,
    });
  };

  /**
   * Нужна для срабатывания отправки метрики в функции
   * `createStyledBaseConditional`.
   */
  handleMsVoidClick = () => {};

  render() {
    const { clientStore } = this;
    const { opened } = this.state;

    const { rateIsHidden } = clientStore;
    const creditRating = clientStore.getValue('creditRating').get();

    return (
      <AsideTablet msDisabled onClick={this.toggleAside} opened={opened}>
        <Arrow opened={opened} />
        <Link to={urls.PROFILE} data-qa="leftPanel-profile-link">
          <UserTablet
            msTitle="Профиль"
            onClick={this.handleMsVoidClick}
            img={clientStore.getValue('photo').get()}
            badge={clientStore.getValue('bonuses').get()}
            name={clientStore.name}
            verified={clientStore.getValue('verified').get()}
            clientId={clientStore.identificationCode}
            opened={opened}
          />
        </Link>

        <Box>
          <Link to={urls.BONUS_PROGRAM} data-qa="leftPanel-bonusProgram-link">
            <Row msTitle="Бонусные баллы" onClick={this.handleMsVoidClick}>
              <Cell>
                <Badge>
                  <Star width="12" />
                  {clientStore.getValue('bonuses').get()}
                </Badge>
              </Cell>
              <Text hidden={!opened} nowrap>
                Бонусные баллы
              </Text>
            </Row>
          </Link>
          <Link to={urls.CREDIT_RATING} data-qa="leftPanel-creditRating-link">
            <Row msTitle="Кредитный рейтинг" onClick={this.handleMsVoidClick}>
              <Cell>
                <Badge color={creditRating && !rateIsHidden ? '#ffac32' : '#f2f2fb'}>
                  {rateIsHidden ? '-' : creditRating}
                </Badge>
              </Cell>
              <Text hidden={!opened} nowrap>
                Кредитный рейтинг
              </Text>
            </Row>
          </Link>
        </Box>

        <Box>
          <LoanInfo small={!opened} hideStatusTitle />
        </Box>

        <Cards>
          <CreditCardList small hidden={!opened} />
        </Cards>
        {opened && <AsideExit />}
      </AsideTablet>
    );
  }
}
