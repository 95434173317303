import React from 'react';

const Plus = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path d="M11 9V1a1 1 0 1 0-2 0v8H1a1 1 0 1 0 0 2h8v8a1 1 0 1 0 2 0v-8h8a1 1 0 1 0 0-2h-8z" />
    </svg>
  );
};

export default Plus;
