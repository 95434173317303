import { createStyled, createStyledBaseConditional } from 'utils/createStyled';
import styles from './LoanInfo.scss';

export const Wrap = createStyled('div', styles.wrap);

export const Row = createStyledBaseConditional('div', styles.row, {
  column: styles.column,
});

export const Title = createStyledBaseConditional('div', styles.title, {
  small: styles.small,
});

export const Text = createStyledBaseConditional('div', styles.text, {
  small: styles.small,
});
