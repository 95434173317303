import Model from '../Model';

// eslint-disable-next-line import/no-default-export
export default class Step2GroupBModel extends Model<Step2GroupBModel> {
  public noWork = false;

  public employer = '';

  public employerPhone = '';

  public position = '';

  public experience = -1;

  public salaryOfficial = -1;

  public salaryDate = -1;

  /*
   * Параметр убран https://jira.devim.team/browse/DWN-14004.
   */
  // public creditLoad = 1;

  public maritalStatus = -1;

  public familyCount = -1;

  public socials = [];

  public isCreditLoadDisabled = false;

  public haveOtherCredit = false;

  public otherCredit = {
    paymentsCount: 0,
    avgPaymentAmount: 0.0,
  };

  public constructor(data?: Partial<Step2GroupBModel>) {
    super();
    this.init(data);
  }
}
