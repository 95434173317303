import React from 'react';
import NotificationStore from 'store/NotificationStore';
import { ActiveFavicon } from 'ui/Favicons/ActiveFavicon';
import { observer } from 'mobx-react';

/** Компонент делает favicon мигающим при наступлении различных событий */
@observer
export class NotificationManager extends React.Component {
  public componentDidMount() {
    window.addEventListener('focus', this.onWindowFocus);
  }

  public componentWillUnmount() {
    window.removeEventListener('focus', this.onWindowFocus);
  }

  private onWindowFocus = () => {
    NotificationStore.changePaymentEventState(false);
  };

  public render() {
    const activeFavicon = NotificationStore.paymentEvent;
    return (
      <>
        <ActiveFavicon active={activeFavicon} />
      </>
    );
  }
}
