import { SERVICE_STATUS, SERVICE_TYPE } from 'const/paidService';
import Api from './Api';

export declare namespace GetPaymentInfo {
  interface Response {
    url: string;
  }
}

export declare namespace GetInfo {
  interface PaidService {
    type: number;
  }

  interface Request {
    paidServices: Array<PaidService>;
  }

  interface Response {
    documentUrl: string;
    price: number;
  }
}

export declare namespace Get {
  interface PaidService {
    type: SERVICE_TYPE;
    status: SERVICE_STATUS;
    price: number;
    oldPrice?: number;
    purchased: boolean;
  }

  type Response = Array<PaidService>;
}

class PaidServiceApi extends Api {
  get = (): Promise<Get.Response> => {
    return this.post('paidService.get');
  };

  // возвращает ссылку на договор и сумму за услуги
  getInfo = (data: GetInfo.Request): Promise<GetInfo.Response> => {
    return this.post('paidService.getInfo', data);
  };

  // получение кода для подписания договора
  sendConfirmationCode = (): Promise<true> => {
    return this.post('paidService.sendConfirmationCode', {}, true, true);
  };

  // отправка кода при подписании договора
  confirmSmsCode = (smsCode): Promise<true> => {
    return this.post('paidService.confirmSmsCode', { smsCode }, true);
  };

  // возвращает ссылку на форму Тинька
  getPaymentInfo = (): Promise<GetPaymentInfo.Response> => {
    return this.post('paidService.getPaymentInfo');
  };
}

// eslint-disable-next-line import/no-default-export
export default new PaidServiceApi();
