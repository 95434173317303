/* eslint-disable import/no-default-export */
import * as env from 'const/env';

const defaultExpiresDate = new Date();
defaultExpiresDate.setMonth(defaultExpiresDate.getMonth() + 6);

const cookieDomain = env.IS_LOCAL ? window.location.hostname : env.COOKIE_DOMAIN;

/**
 * @deprecated Использовать модуль `js-cookie`.
 *
 * Утилита для работы с куками.
 * Утилита не использует encodeURIComponent и decodeURIComponent, из-за этого
 * в браузере Safari могут не записываться куки.
 */
export default class Cookie {
  static set(name: string, value, expiresDate?: Date, path?: string) {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    expiresDate = expiresDate || defaultExpiresDate;
    const expires = `expires=${expiresDate.toUTCString()}`;

    // @ts-ignore
    // eslint-disable-next-line no-unused-expressions
    typeof document !== 'undefined'
      ? (document.cookie = `${name}=${value};${expires};path=/${path || ''};domain=${cookieDomain}`)
      : '';
  }

  static setSession(name, value) {
    // @ts-ignore
    // eslint-disable-next-line no-unused-expressions
    typeof document !== 'undefined'
      ? (document.cookie = `${name}=${value};path=/;domain=${cookieDomain}`)
      : '';
  }

  static get(cName: string) {
    const name = `${cName}=`;
    const ca = typeof document !== 'undefined' ? document.cookie.split(';') : '';

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  static delete(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${cookieDomain}`;
  }
}
