import Api from 'services/Api';
import * as rpc from 'const/rpc';

import { Draft } from './Draft';
import { Service as ClickhouseMetricService } from '../../../modules/common/ClickhouseMetric';
import { toCamelCase } from '../../../utils/Helpers';

interface Request {
  id: string;
}

type Response = {
  fields: Draft;
};

export async function fetch(id: string) {
  let response: any;

  try {
    response = await Api.getInstance().post('loanApplicationDraft.get', { id }, true, true);
  } catch (error) {
    if (typeof window !== 'undefined') {
      const textError = error.code || 'badRequest';

      const arrCurrentLocation = window.location.pathname.substr(1).split('/');
      const [url] = arrCurrentLocation;
      ClickhouseMetricService.push(`${toCamelCase(url)}`, textError);
    }
    if (error.code === rpc.RPC_LOAN_DRAFT_NOT_FOUND) {
      return undefined;
    }

    throw error;
  }

  const draft = response.fields as Draft;
  return draft;
}
