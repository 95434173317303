import React, { ComponentClass } from 'react';

import Portal from 'modules/Portal';

export default function withPortal<C extends ComponentClass>(WrappedComponent: C): C {
  WrappedComponent.displayName = `withPortal(${WrappedComponent.displayName || WrappedComponent.name})`;
  return ((props) => <Portal>
    <WrappedComponent {...props} />
  </Portal>) as any as C;
}
