import Model from './Model';

export class SelectedLoanModel extends Model<SelectedLoanModel> {
  public tariffId = 0;

  public name = '';

  public amount = 0;

  public period = 0;

  // public promoCode = '';

  public dueToDate = new Date().toISOString();

  public info = '';

  public constructor(data?: Partial<SelectedLoanModel>) {
    super();
    this.init(data);
  }
}
