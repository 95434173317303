import { getUUID } from 'utils/Metrics/utils';
import { hashCode } from 'utils/Helpers';
import { Singleton } from 'utils/singleton';
import { IS_DEVELOPMENT } from 'const/env';
import { LocalStorageKeys } from 'const/localStorage';
import YandexMetricProvider from 'services/marketing/providers/YandexMetricProvider';
import { OPERATOR } from 'const/cookies';
import Cookie from 'utils/Cookie';

import { ABGroup, ABTest, ABTestVariation } from './const';

declare global {
  interface Window {
    setAbGroup?: (abName: ABTest, abGroup: ABGroup) => void;
  }
}

/**
 * Стор для работы с AB тестами.
 */
export class ABTestStore extends Singleton {
  constructor() {
    super();
    this.initValues();
    this.setGlobalHook();
  }

  private readonly uuid = getUUID();

  private values = new Map<ABTest, ABGroup>();

  private isMetricSended: boolean = false;

  /**
   * Вычисление группы пользователя.
   * https://habr.com/ru/company/avito/blog/454164/
   * https://habr.com/ru/company/yandex/blog/342704/
   */
  private calculateGroup(abName: ABTest) {
    const config = ABTestVariation[abName];
    const countOfVariations = Object.keys(config).reduce((prev, key) => prev + config[key], 0);
    const calculation = Math.abs(hashCode(this.uuid + abName) % countOfVariations);
    const keys = Object.keys(config).reverse();
    let section = countOfVariations;

    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys) {
      section -= config[key];

      if (calculation >= section) {
        return Number(key);
      }
    }

    /*
     * В нормальном случае ключ вернётся в предыдущем цикле, до этого места
     * программа не должна доходить
     */
    return Number(keys[0]);
  }

  /**
   * Возвращает номер группы.
   * @param abName название АБ теста.
   */
  public getABGroup(abName: ABTest): ABGroup {
    const isOperator = Boolean(Cookie.get(OPERATOR));

    if (isOperator) {
      return undefined;
    }

    if (this.values.has(abName)) {
      return this.values.get(abName);
    }

    return this.setGroup(abName, this.calculateGroup(abName));
  }

  /**
   * Удаляет неактивный АБ тест.
   */
  public clearInactiveABTests() {
    let groups = JSON.parse(localStorage.getItem(LocalStorageKeys.abTestValues));

    if (groups && Array.isArray(groups)) {
      groups = groups.filter((group) => group.length && ABTest[group[0]]);

      localStorage.setItem(LocalStorageKeys.abTestValues, JSON.stringify(groups));
    }
  }

  /**
   * Удаляет АБ тест из localStorage.
   * @param {ABTest} name Название АБ теста.
   */
  public removeABTest(name: ABTest) {
    this.values.delete(name);

    let groups = JSON.parse(localStorage.getItem(LocalStorageKeys.abTestValues));

    if (groups && Array.isArray(groups)) {
      groups = groups.filter((group) => group[0] !== name);

      localStorage.setItem(LocalStorageKeys.abTestValues, JSON.stringify(groups));
    }
  }

  public sendMetric() {
    if (this.isMetricSended) {
      return;
    }

    const tests = Object.keys(ABTestVariation);
    const { length } = tests;

    for (let i = 0; i < length; i += 1) {
      const test = tests[i] as any;
      const group = this.getABGroup(test);
      YandexMetricProvider.sendMetric(`ab_${test}_group-${group}`);
    }

    this.isMetricSended = true;
  }

  private setGroup(abName: ABTest, abGroup: ABGroup) {
    this.values.set(abName, abGroup);

    if (IS_DEVELOPMENT) {
      localStorage.setItem(LocalStorageKeys.abTestValues, JSON.stringify(Array.from(this.values)));
    }

    return Number(abGroup);
  }

  private initValues() {
    if (IS_DEVELOPMENT) {
      const stored = localStorage.getItem(LocalStorageKeys.abTestValues);

      if (stored) {
        this.values = new Map(JSON.parse(stored));
      }
    }
  }

  private setGlobalHook() {
    if (!IS_DEVELOPMENT || typeof window === 'undefined' || window.setAbGroup) {
      return;
    }

    window.setAbGroup = this.setGroup.bind(this);
  }
}
