import Logger from 'utils/Logger';

/*
  Number
  String
  Object
  Array
  Undefined
  Null
*/
function getType(obj) {
  return {}.toString.call(obj).slice(8, -1);
}

// eslint-disable-next-line import/no-default-export
export default class Model<Fields = {}> {
  protected init(data?: Partial<Fields>) {
    Object.keys({ ...data }).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(this, key)) {
        if (getType(this[key]) === getType(data[key])) {
          this[key] = data[key];
        } else if (data[key] !== null) {
          Logger.warn(
            `Init parameter error: "${key}" type mismatch in model ${this.constructor.name}`,
          );
        }
      } else {
        Logger.warn(`Init parameter error: "${key}" not found in model ${this.constructor.name}`);
      }
    });
  }

  // eslint-disable-next-line consistent-return
  public readonly getValue = <K extends keyof Fields>(param: K): Fields[K] => {
    if (Object.prototype.hasOwnProperty.call(this, param)) {
      // @ts-ignore
      return this[param];
    }
    Logger.warn(`Get parameter error: "${param}" not found in model ${this.constructor.name}`);
  };

  public readonly setValue = <K extends keyof Fields>(param: K, value: Fields[K]) => {
    if (Object.prototype.hasOwnProperty.call(this, param)) {
      // @ts-ignore
      this[param] = value;
      return true;
    }
    Logger.warn(`Set parameter error: "${param}" not found in model ${this.constructor.name}`);
    return false;
  };
}
