import React from 'react';

const VisaPaymentSystem = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 20" {...props}>
      <g fill="none" transform="translate(0 4)">
        <polygon fill="#00579F" points="17.293 12.611 14.075 12.611 16.088 .378 19.306 .378" />
        <path
          fill="#00579F"
          d="M28.9582282.676960502C28.3235161.429451242 27.3168103.156170577 26.0720215.156170577 22.8942378.156170577 20.6564686 1.82179893 20.6427432 4.20314589 20.6163481 5.96009842 22.2449205 6.93595254 23.4629624 7.52177635 24.7079272 8.12039938 25.1311272 8.51112154 25.1311272 9.04471069 25.1184576 9.86420676 24.1251253 10.2419567 23.1986603 10.2419567 21.913927 10.2419567 21.225545 10.047201 20.1794227 9.59127202L19.7556947 9.39582445 19.3053958 12.1417763C20.0601172 12.4797449 21.4506065 12.7796618 22.8942378 12.7928069 26.2706879 12.7928069 28.4688647 11.1529499 28.4949077 8.61524493 28.5077533 7.22272428 27.6478038 6.15571895 25.793818 5.28381526 24.6683346 4.72410878 23.9790729 4.34670472 23.9790729 3.77402606 23.9922704 3.2534091 24.5620507 2.72016588 25.8325307 2.72016588 26.8786531 2.69404855 27.6472759 2.94121189 28.2295499 3.18854818L28.5205989 3.31844298 28.9582282.676960502 28.9582282.676960502 28.9582282.676960502zM33.2351002 8.27693039C33.5001062 7.57418397 34.5198335 4.8543495 34.5198335 4.8543495 34.50646 4.88046683 34.7843116 4.1386309 34.9432095 3.68322078L35.168095 4.73725393C35.168095 4.73725393 35.7774679 7.66533518 35.9097949 8.27693039 35.4068819 8.27693039 33.8706922 8.27693039 33.2351002 8.27693039L33.2351002 8.27693039zM37.2071978.377908433L34.7181481.377908433C33.9505811.377908433 33.3674272.598954439 33.0362578 1.39285207L28.2564728 12.6103315 31.6329229 12.6103315C31.6329229 12.6103315 32.1888019 11.1005423 32.3082833 10.7753729 32.6786933 10.7753729 35.9632888 10.7753729 36.4398068 10.7753729 36.5321893 11.2048387 36.8237663 12.6103315 36.8237663 12.6103315L39.8032354 12.6103315 37.2071978.377908433 37.2071978.377908433 37.2071978.377908433zM11.3874196.377908433L8.236031 8.71936833 7.89166406 7.02762264C7.30903813 5.07556847 5.48179933 2.95470296 3.44269655 1.90015092L6.32925523 12.5975323 9.73210037 12.5975323 14.7900888.377908433 11.3874196.377908433 11.3874196.377908433 11.3874196.377908433z"
        />
        <path
          fill="#FAA61A"
          d="M5.30970384,0.377908433 L0.132409923,0.377908433 L0.0794439292,0.625071769 C4.11805696,1.64018837 6.79275166,4.08708809 7.89166406,7.02814153 L6.76618068,1.40617018 C6.58088769,0.624898806 6.01145926,0.403679837 5.30970384,0.377908433 L5.30970384,0.377908433 Z"
        />
      </g>
    </svg>
  );
};

export default VisaPaymentSystem;
