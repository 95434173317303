export const enum SERVICE_TYPE {
  /**
   * @deprecated Моя кредитная история
   */
  CREDIT_HISTORY = 1,

  /**
   * @deprecated SMS-Информирование
   */
  SMS = 2,

  /**
   * Пакет Привилегия.
   */
  PRIVILEGE = 3,

  /**
   * Узнать причину отказа.
   */
  REASON_OF_REFUSAL = 4,
}

export const enum SERVICE_STATUS {
  NOT_AVAILABLE = 1,
  AVAILABLE = 2,
  CONTRACT_SIGNED = 3,
  PURCHASED = 4,
}

export const serviceConfig = [
  {
    type: SERVICE_TYPE.CREDIT_HISTORY,
    label: '«Моя кредитная история»',
    required: true,
  },
  {
    type: SERVICE_TYPE.SMS,
    label: '«SMS-информирование»',
  },
  {
    type: SERVICE_TYPE.PRIVILEGE,
    label: 'Пакет «Привилегия»',
    includes: [SERVICE_TYPE.SMS],
    required: true,
  },
  {
    type: SERVICE_TYPE.REASON_OF_REFUSAL,
    label: '«Узнать причину отказа»',
  },
];
