import { LOAN_STATUS } from 'const/loan';

export function getStatusTitle(status: LOAN_STATUS) {
  switch (status) {
    case LOAN_STATUS.APPROVED:
    case LOAN_STATUS.READY_FOR_PAYOUT:
    case LOAN_STATUS.PAYOUT_WAITING:
    case LOAN_STATUS.PAYOUT_PENDING:
      return 'Одобренный заём';
    case LOAN_STATUS.HAS_LOAN:
    case LOAN_STATUS.HAS_OUTDATED_LOAN:
      return 'Действующий заём';
    case LOAN_STATUS.NO_LOAN:
    case LOAN_STATUS.UNDERWRITING:
    case LOAN_STATUS.REJECTED:
    default:
      return 'Выбранный заём';
  }
}
