import Api from 'services/Api';

type Response = {
  issueAuthority: string;
  issueDate: string;
  subCode: string;
  birthDate: string;
  fullName: string;
  passport: string;
};

export function getPassportData(): Promise<Response> {
  return Api.getInstance().post('client.getPassportData');
}
