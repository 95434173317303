import React, { ComponentPropsWithoutRef, FC } from 'react';
import cn from 'classnames';
import * as env from 'const/env';

import LogoLarge from 'ui/svg-icons/Logos/Large';

import style from './DzpLogoLarge.scss';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<'a'>;

/**
 * Отображает логотип в шапке сайта в разделе оформления заявки на займ
 * до авторизации.
 */
export const DzpLogoLarge: FC<Props> = ({ className, ...props }) => (
  <a {...props} className={cn(style.root, className)} href={`//${env.PUBLIC_DOMAIN}`}>
    <LogoLarge />
  </a>
);
