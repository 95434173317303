import { action, computed, observable, toJS } from 'mobx';

import CalculatorService from 'services/Calculator';
// import PromoCodeService from 'services/PromoCode';

import CalculatorTariffModel from 'models/CalculatorTariff';

import moment from 'moment';
import Cookie from 'utils/Cookie';
import Helpers from 'utils/Helpers';

import * as c from 'const/calculator';
import { PERIOD_TYPE } from 'const/calculator';
import * as cookies from 'const/cookies';
import { LOAN_DIFF_DAYS } from 'const/loan';
import LoanApplicationStore from 'store/LoanApplication';
import { makeDefaultTariffs } from 'store/helpers/makeDefaultTariffs';

class CombinedCalculatorStore {
  /** Инициализирован ли калькулятор */
  @observable
  isInitialized = false;

  /** Загружены ли тарифы с бэка */
  @observable
  isDataLoaded = false;

  /** Какой параметр был изменен последним (для правильного расчета текущего тарифа) */
  @observable
  lastChangedParameter = c.PARAMETER_TYPE_AMOUNT;

  /** Текущая сумма в копейках */
  @observable
  amount = 0;

  /** Общая минимальная сумма, собранная из всех тарифов */
  @observable
  minAmount = 0;

  /** Общая максимальная сумма, собранная из всех тарифов */
  @observable
  maxAmount = 0;

  /** Текущий срок в днях */
  @observable
  period: PERIOD_TYPE = 0;

  /** Сумма к возврату */
  @observable
  totalAmount = 0;

  /** Сумма переплаты (начисленные проценты) */
  @observable
  percentAmount = 0;

  /** Платёж раз в период (месяц) */
  @observable
  paymentPerPeriod = 0;

  /** Количество платежей */
  @observable
  paymentCount = 0;

  /** Промокод */
  // @observable
  // promoCode = '';

  /** Применен ли промокод */
  @observable
  isPromoCodeApplied = false;

  /** Применен ли реферальный промокод */
  @observable
  isReferralPromoCodeApplied = false;

  /** Текст для счетчиков-триггеров */
  @observable
  triggerText = '';

  /** Данные для счетчиков-триггеров */
  @observable
  triggerData = {
    issuedCountToday: 0,
    issuePeriod: 0,
    repeatClientsCount: 0,
    cardIssuedCount: 0,
  };

  @observable
  public processing: boolean = false;

  /** Список тарифов */
  @observable
  tariffs: Array<CalculatorTariffModel> = [];

  /** Активированный тариф с промокодом */
  @observable
  promoTariff = new Map<number, CalculatorTariffModel>();

  /** Текущий тариф */
  @computed
  get currentTariff() {
    // ищем подходящий тариф по сумме
    let tariffId = this.findTariffIdByAmount(this.amount);

    // если последний раз меняли срок, то ищем тариф по сроку
    if (this.lastChangedParameter === c.PARAMETER_TYPE_PERIOD) {
      const periodType = this.period > c.DAYS_IN_MONTH ? PERIOD_TYPE.MONTH : PERIOD_TYPE.DAY;

      const period = periodType === PERIOD_TYPE.MONTH ? this.period / c.DAYS_IN_MONTH : this.period;

      const periodTariffId = this.findTariffIdByPeriod(period, periodType);

      if (periodTariffId) {
        tariffId = periodTariffId;
      }
    }

    return this.tariffs.find((tariff) => tariff.tariffId === tariffId);
  }

  /** Минимальная сумма в выбранном тарифе */
  @computed
  get minTariffAmount() {
    let minAmount = null;

    const tariffRange = this.currentTariff.range;

    tariffRange.forEach((range) => {
      if (minAmount === null) minAmount = range.minAmount;

      if (range.minAmount < minAmount) {
        minAmount = range.minAmount;
      }
    });

    return minAmount;
  }

  /** Максимальная сумма в выбранном тарифе */
  @computed
  get maxTariffAmount() {
    let maxAmount = null;

    const tariffRange = this.currentTariff.range;

    tariffRange.forEach((range) => {
      if (maxAmount === null) maxAmount = range.maxAmount;

      if (range.maxAmount > maxAmount) {
        maxAmount = range.maxAmount;
      }
    });

    return maxAmount;
  }

  /** Значение срока, которое отображается у юзера (под капотом может быть 62, но отображается в месяцах: 62 / 31 = 2) */
  @computed
  get visiblePeriod() {
    return this.periodType === PERIOD_TYPE.MONTH ? this.period / c.DAYS_IN_MONTH : this.period;
  }

  /** Список всех значений сроков в днях (подставляется в список всех сроков в  слайдер) */
  @computed
  get periodValues() {
    const periodList = [];

    this.tariffList.forEach((tariff) => {
      const tariffRange = tariff.range;

      tariffRange.forEach((range) => {
        const calculatedRange = Helpers.calculateValueRange(range.minPeriod, range.maxPeriod, 1);

        periodList.push(
          ...calculatedRange.map((value) =>
            tariff.periodType === PERIOD_TYPE.MONTH ? value * c.DAYS_IN_MONTH : value,
          ),
        );
      });
    });

    return periodList;
  }

  /** Минимальный срок в выбранном тарифе */
  @computed
  get minTariffPeriod() {
    const { range } = this.currentTariff;

    for (let i = 0, len = range.length; i < len; i += 1) {
      if (this.amount >= range[i].minAmount && this.amount <= range[i].maxAmount) {
        return this.currentTariff.periodType === PERIOD_TYPE.MONTH
          ? range[i].minPeriod * c.DAYS_IN_MONTH
          : range[i].minPeriod;
      }
    }
    return null;
  }

  /** Максимальный срок в выбранном тарифе */
  @computed
  get maxTariffPeriod() {
    const { range } = this.currentTariff;

    for (let i = 0, len = range.length; i < len; i += 1) {
      if (this.amount >= range[i].minAmount && this.amount <= range[i].maxAmount) {
        return this.currentTariff.periodType === PERIOD_TYPE.MONTH
          ? range[i].maxPeriod * c.DAYS_IN_MONTH
          : range[i].maxPeriod;
      }
    }
    return null;
  }

  /** Шаг суммы в выбранном тарифе */
  @computed
  get amountStep() {
    const { range } = this.currentTariff;

    for (let i = 0, len = range.length; i < len; i += 1) {
      if (this.amount >= range[i].minAmount && this.amount <= range[i].maxAmount) {
        return range[i].stepAmount;
      }
    }
    return null;
  }

  /** Сколько дней в займе */
  @computed
  get loanDays() {
    return this.period;
  }

  /** Дата (включительно), до которого берётся займ */
  @computed
  get dueToDate() {
    const date = moment().startOf('day').add(this.period, 'days').subtract(LOAN_DIFF_DAYS, 'days');

    return date.toDate();
  }

  /** Список тарифов как простой JS array */
  @computed
  get tariffList() {
    return toJS(this.tariffs);
  }

  /** Тип срока (дни, недели, месяцы) в выбранном тарифе */
  @computed
  get periodType(): PERIOD_TYPE {
    return this.currentTariff ? this.currentTariff.periodType : null;
  }

  /** Значение срока в нужном типе */
  @computed
  get periodTypeValue() {
    return this.currentTariff.periodType === PERIOD_TYPE.MONTH
      ? this.period / c.DAYS_IN_MONTH
      : this.period;
  }

  /** Инициализирует калькулятор */
  @action
  public init = async ({
    restoreValues = false,
    defaultTariffId = c.TARIFF_PDL,
    defaultAmount = 0,
    defaultPeriod = 0,
  } = {}) => {
    // Восстанавливаем значения из кук
    if (restoreValues) {
      this.restoreValuesFromCookies();
    }
    // Костыль для быстрой загрузки без бэка
    this.firstInit(defaultTariffId, defaultAmount, defaultPeriod);

    await this.fetchCalculationTariffs();

    this.setMinMaxAmount();
    this.isDataLoaded = true;
  };

  /** ставим изначальные значения для первого быстрого рендера калькулятора */
  @action
  private firstInit = (defaultTariffId: number, defaultAmount: number, defaultPeriod: number) => {
    this.tariffs = makeDefaultTariffs();

    const amount = this.amount || defaultAmount;
    const period = this.period || defaultPeriod;
    const tariffId = (this.currentTariff && this.currentTariff.tariffId) || defaultTariffId;

    this.setMinMaxAmount();

    if (tariffId) {
      this.setDefaultsFromTariffId(tariffId);
    }

    if (amount) {
      this.lastChangedParameter = c.PARAMETER_TYPE_AMOUNT;
      this.setAmount(amount);
    }

    if (period) {
      this.lastChangedParameter = c.PARAMETER_TYPE_PERIOD;
      this.setPeriod(period);
    }

    const promoTariff = this.getPromoTariff();
    if (promoTariff && promoTariff.tariffId) {
      this.setValuesByPromoTariff(promoTariff);
    }

    this.handleLimits();
    this.calculate();

    this.isInitialized = true;
  };

  /** Событие смены суммы */
  @action
  public onAmountChange = (amount: number) => {
    // Валидируем сумму из всех тарифов
    const calculatedAmount = this.validateAndHandleCombinedAmountLimits(amount);

    this.lastChangedParameter = c.PARAMETER_TYPE_AMOUNT;
    this.setAmount(calculatedAmount);

    // Валидируем и устанавливаем правильный срок для текущего тарифа
    this.handleTariffPeriodLimits();
  };

  /** Создание заявки из калькулятора */
  public onTakeLoan = async () => {
    this.processing = true;
    const fpsUserFp = Cookie.get('pixel_user_fp') || null;
    const fpsSessionId = Cookie.get('pixel_sess_id') || null;
    try {
      return await LoanApplicationStore.createSelectedLoan({
        period: this.periodTypeValue,
        amount: this.amount,
        // promoCode: this.isPromoCodeApplied ? this.promoCode : '',
        tariffId: this.currentTariff.tariffId,
        fpsUserFp,
        fpsSessionId,
      });
    } finally {
      this.processing = false;
    }
  };

  /** Событие смены срока */
  @action
  public onPeriodChange = (period: number, target: string) => {
    let calculatedPeriod = period;

    // Если срок поменяли из инпута, то валидируем только в текущем тарифе
    if (target === 'input') {
      calculatedPeriod =
        this.periodType === PERIOD_TYPE.MONTH
          ? calculatedPeriod * c.DAYS_IN_MONTH
          : calculatedPeriod;

      // Валидируем и устанавливаем правильный срок для текущего тарифа
      calculatedPeriod = this.validateAndHandleTariffPeriodLimits(calculatedPeriod);
    }

    this.lastChangedParameter = c.PARAMETER_TYPE_PERIOD;
    this.setPeriod(calculatedPeriod);

    // Валидируем и устанавливаем правильную сумму для текущего тарифа
    this.handleTariffAmountLimits();
  };

  /** Событие клика по дате в календаре */
  @action
  public onCalendarChange = (date: moment.MomentInput) => {
    const selected = moment(date).startOf('day');
    const diff = selected.diff(moment().startOf('day'), 'days');

    this.lastChangedParameter = c.PARAMETER_TYPE_PERIOD;
    this.setPeriod(diff + LOAN_DIFF_DAYS);

    // Валидируем и устанавливаем правильную сумму для текущего тарифа
    this.handleTariffAmountLimits();
  };

  // @action
  // public onPromoCodeChange = (promoCode) => {
  //   this.promoCode = promoCode;
  // };

  // @action
  // handlePromoCode = () => {
  //   const { promoCode } = this;

  //   if (this.isPromoCodeApplied) {
  //     this.isPromoCodeApplied = false;
  //     this.isReferralPromoCodeApplied = false;

  //     this.resetPromoTariff();
  //     this.calculate();

  //     return Promise.resolve();
  //   }

  //   return PromoCodeService.getInfo(this.promoCode).then((response) => {
  //     this.isPromoCodeApplied = true;
  //     this.promoCode = promoCode;

  //     // Если пришел тариф, записываем его
  //     if (response.tariff && response.tariff.tariffId) {
  //       this.setPromoTariff(new CalculatorTariffModel(response.tariff));
  //       //this.switchTab(response.tariff.tariffId, false);
  //       this.setValuesByPromoTariff(response.tariff);
  //     } else {
  //       // если не пришел тариф, то считаем, что это реферальный промокод
  //       this.isReferralPromoCodeApplied = true;
  //     }

  //     this.calculate();

  //     return response;
  //   });
  // };

  @action
  private setValuesByPromoTariff = (tariff) => {
    if (!tariff) {
      return;
    }

    const range = tariff.range[tariff.range.length - 1];

    if (range) {
      // Выставляем сумму и период
      this.setAmount(range.maxAmount);
      this.setPeriod(
        tariff.periodType === PERIOD_TYPE.MONTH
          ? range.maxPeriod * c.DAYS_IN_MONTH
          : range.maxPeriod,
      );
    }
  };

  /** Проверяет на корректность сумму и срок при изменении хотя бы одной составляющей */
  @action
  private handleLimits = () => {
    if (this.amount < this.minTariffAmount) {
      this.setAmount(this.minTariffAmount);
    }

    if (this.amount > this.maxTariffAmount) {
      this.setAmount(this.maxTariffAmount);
    }

    if (this.period < this.minTariffPeriod) {
      this.setPeriod(this.minTariffPeriod);
    }

    if (this.period > this.maxTariffPeriod) {
      this.setPeriod(this.maxTariffPeriod);
    }
  };

  @action
  private handleTariffPeriodLimits = () => {
    if (this.period < this.minTariffPeriod) {
      this.setPeriod(this.minTariffPeriod);
    }

    if (this.period > this.maxTariffPeriod) {
      this.setPeriod(this.maxTariffPeriod);
    }
  };

  @action
  private handleTariffAmountLimits = () => {
    if (this.amount < this.minTariffAmount) {
      this.setAmount(this.minTariffAmount);
    }

    if (this.amount > this.maxTariffAmount) {
      this.setAmount(this.maxTariffAmount);
    }
  };

  private validateAndHandleCombinedAmountLimits = (amount) => {
    return Helpers.handleMinMaxLimits(amount, this.minAmount, this.maxAmount);
  };

  private validateAndHandleTariffPeriodLimits = (period) => {
    return Helpers.handleMinMaxLimits(period, this.minTariffPeriod, this.maxTariffPeriod);
  };

  @action
  public calculate = async () => {
    const { tariffId } = this.currentTariff;

    if (tariffId === 1 || tariffId === 2) {
      const percentRange = [
        { maxAmount: 2000000, minAmount: 200000, loanPercent: 2 },
        { maxAmount: 2000000, minAmount: 200000, loanPercent: 0.5 },
      ];

      let loanPercent = 0;
      if (
        this.amount >= percentRange[tariffId - 1].minAmount &&
        this.amount <= percentRange[tariffId - 1].maxAmount
      ) {
        loanPercent = percentRange[tariffId - 1].loanPercent;
      }

      const percentAmount =
        (this.amount * this.periodTypeValue < 0 ? 0 : this.periodTypeValue * loanPercent) / 100;

      this.totalAmount = Math.round(this.amount + percentAmount);
      this.percentAmount = Math.round(percentAmount);
    } else if (tariffId === 3) {
      const periodMultiplier = 30;

      this.paymentPerPeriod =
        this.amount / this.periodTypeValue +
        (this.amount / this.periodTypeValue) * 0.005 * periodMultiplier;
      this.paymentCount = this.periodTypeValue;
    } else if (tariffId === 4) {
      const getDaysFromMonthAmount = (period) => {
        const start = moment().startOf('day');
        const end = moment().add(period, 'months');

        return end.diff(start, 'days');
      };
      const percent = 0.24;
      const percentAmount =
        (percent * this.amount * getDaysFromMonthAmount(this.periodTypeValue)) / 100;

      this.paymentPerPeriod = Math.round((percentAmount + this.amount) / this.periodTypeValue);
      this.paymentCount = this.periodTypeValue;
    }
    this.processing = false;
  };

  /** Запрашивает настройки калькулятора */
  @action
  private fetchCalculationTariffs = async () => {
    const response = await CalculatorService.getCalculationTariffs();
    this.tariffs = response.tariffs.map((tariff) => new CalculatorTariffModel(tariff));
  };

  /** Запрашивает счетчики для триггера */
  public fetchTriggersData = () => {
    return CalculatorService.getCounters()
      .then((response) => {
        this.triggerData = response;

        const randomTriggerKey = Object.keys(this.triggerData)[
          Math.floor(Math.random() * Object.keys(this.triggerData).length)
        ];

        if (randomTriggerKey === 'issuedCountToday') {
          const value = this.triggerData.issuedCountToday;
          this.triggerText = `<span>${value}</span> ${Helpers.plural(
            value,
            'клиент',
            'клиента',
            'клиентов',
          )} сегодня получили деньги`;
        }

        if (randomTriggerKey === 'issuePeriod') {
          this.triggerText = 'Каждую минуту выдаем деньги';
        }

        if (randomTriggerKey === 'repeatClientsCount') {
          const value = this.triggerData.repeatClientsCount;
          this.triggerText = `<span>${value}</span> ${Helpers.plural(
            value,
            'клиент',
            'клиента',
            'клиентов',
          )} ${Helpers.plural(value, 'вернулся', 'вернулись', 'вернулись')} к нам снова`;
        }

        if (randomTriggerKey === 'cardIssuedCount') {
          const value = this.triggerData.cardIssuedCount;
          this.triggerText = `<span>${value}</span> ${Helpers.plural(
            value,
            'клиент',
            'клиента',
            'клиентов',
          )} ${Helpers.plural(value, 'получил', 'получили', 'получили')} деньги на карту`;
        }
      })
      .catch();
  };

  @action
  private setPromoTariff = (tariff) => {
    this.promoTariff.set(1, tariff);
  };

  private getPromoTariff = () => {
    return this.promoTariff.get(1);
  };

  @action
  private resetPromoTariff = () => {
    this.promoTariff.set(1, null);
  };

  /** Восстанавливает значения калькулятора из кук */
  @action
  public restoreValuesFromCookies = () => {
    const cookieVal = Cookie.get(cookies.CALC_KEY);

    if (cookieVal) {
      try {
        const parsedCookie = JSON.parse(cookieVal);

        if (parsedCookie.amount) this.amount = +parsedCookie.amount;
        if (parsedCookie.days) this.period = +parsedCookie.days;

        this.handleLimits();
      } catch (e) {
        // continue regardless of error
      }
    }
  };

  // todo move it to localStorage
  /** Сохраняет значения калькулятора в куки */
  public saveValuesToCookies = ({
    tariffId = 0,
    amount = 0,
    period = 0,
    days = 0,
  }: // promoCode = '',
  { tariffId?; amount?; period?; days?; promoCode? } = {}) => {
    const obj = {
      tariffId: tariffId || (this.currentTariff ? this.currentTariff.tariffId : 0),
      amount: amount || this.amount,
      period: period || this.periodTypeValue || 0,
      days: days || this.period,
      // promoCode: promoCode || (this.isPromoCodeApplied ? this.promoCode : ''),
    };

    Cookie.setSession(cookies.CALC_KEY, JSON.stringify(obj));
  };

  /** Возвращает значения из кук */
  public getSavedValues(): {
    amount: number;
    days: number;
    period?: number;
    tariffId?: number;
    promoCode?: string;
  } {
    const cookieVal = Cookie.get(cookies.CALC_KEY);

    if (cookieVal) {
      try {
        return JSON.parse(cookieVal);
      } catch (e) {
        // continue regardless of error
      }
    }

    return { amount: 0, days: 0 };
  }

  /** Удаляем значения калькулятора из кук */
  public deleteValuesFromCookies = () => {
    Cookie.delete(cookies.CALC_KEY);
  };

  /** Находит последний тариф (если есть в 1-м и во 2-м, то возвращает последний (2-й), для которого подходит сумма */
  private findTariffIdByAmount = (amount) => {
    return this.tariffList.reduce((prev, cur) => {
      const { range } = cur;

      for (let i = 0, len = range.length; i < len; i += 1) {
        if (amount >= range[i].minAmount && amount <= range[i].maxAmount) {
          return cur.tariffId;
        }
      }

      return prev;
    }, null);
  };

  /** Находит последний тариф (если есть в 1-м и во 2-м, то возвращает последний(2-й), для которого подходит срок */
  private findTariffIdByPeriod = (period: number, periodType: PERIOD_TYPE) => {
    return this.tariffList.reduce((prev, cur) => {
      if (cur.periodType === periodType) {
        const { range } = cur;

        for (let i = 0, len = range.length; i < len; i += 1) {
          if (period >= range[i].minPeriod && period <= range[i].maxPeriod) {
            return cur.tariffId;
          }
        }
      }

      return prev;
    }, null);
  };

  /** Находит тариф по id */
  private findTariffById = (id: number) => {
    return this.tariffList.find((tariff) => tariff.tariffId === id);
  };

  /** Рассчитываем общие min max суммы для всех тарифов для единого слайдера с суммами */
  @action
  private setMinMaxAmount = () => {
    let minAmount = null;
    let maxAmount = null;

    this.tariffList.forEach((tariff) => {
      const tariffRange = tariff.range;

      tariffRange.forEach((range) => {
        if (minAmount === null) minAmount = range.minAmount;
        if (maxAmount === null) maxAmount = range.maxAmount;

        if (range.minAmount < minAmount) {
          minAmount = range.minAmount;
        }

        if (range.maxAmount > maxAmount) {
          maxAmount = range.maxAmount;
        }
      });
    });

    this.minAmount = minAmount;
    this.maxAmount = maxAmount;
  };

  @action
  private setAmount = (amount: number) => {
    this.amount = amount;
  };

  @action
  private setPeriod = (period: number) => {
    this.period = period;
  };

  @action
  public setDefaultsFromTariffId = (tariffId: number) => {
    const tariff = this.findTariffById(tariffId);

    if (tariff) {
      this.lastChangedParameter = c.PARAMETER_TYPE_AMOUNT;

      this.setAmount(tariff.defaultValues.amount);
      this.setPeriod(
        tariff.periodType === PERIOD_TYPE.MONTH
          ? tariff.defaultValues.period * c.DAYS_IN_MONTH
          : tariff.defaultValues.period,
      );
    }
  };
}

// eslint-disable-next-line import/no-default-export
export default new CombinedCalculatorStore();
