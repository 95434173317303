import { CaptchaStore } from 'store/Captcha';
import { CaptchaError } from './CaptchaError';

export async function getCaptchaToken(action?: string) {
  const captchaProvider = CaptchaStore.getCaptchaProvider();
  if (!captchaProvider) {
    throw new CaptchaError('captcha provider not registered');
  }
  try {
    return await captchaProvider.executeRecaptcha(action && action.replace(/\./g, '_'));
  } catch (e) {
    /* e might be null */
    throw new CaptchaError(e && e.message || 'unknown captcha error');
  }

}