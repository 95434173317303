export const TEXT_PRIMARY = '#4E4E64';
export const TEXT_SECONDARY = '#A2A2B2';

export const PRIMARY = '#FFC800';
export const SECONDARY = '#57B560';
export const ERROR = '#ff5353';
export const DISABLED = '#dadae0';
export const SUCCESS = '#22D22E';

export const BUTTON = PRIMARY;
export const BUTTON_HOVER = '#FFD743';
export const BUTTON_ACTIVE = '#F3C600';
export const BUTTON_DISABLED = '#dadae0';

export const BG = '#F7F7FB';
export const ICON_BG = '#F2F2FB';

export const RATE_TERRIBLE = '#FF5353';
export const RATE_BAD = '#FFAC32';
export const RATE_NORMAL = '#7BD83B';
export const RATE_GOOD = '#22D22E';

export const DIVIDER = '#ececef';
export const BORDER = '#bebec8';

export const GRAY_5 = '#f7f7fb';
export const GRAY_15 = '#ececef';
export const GRAY_60 = '#a2a2b2';
export const GRAY_80 = '#787891';
export const GRAY_100 = '#4e4e65';

export const GREEN = '#22d22e';
export const GREEN_DARK = '#57B560';
export const RED = '#ED4242';

export const MENU = GRAY_80;

export const DZP_BRAND_PRIMARY = '#DA3232';
export const DZP_BRAND_SECONDARY = '#FBDE6C';

export const INPUT_PLACEHOLDER = '#D1D1D9';

export const CALC_PDL_COLOR = SECONDARY;
export const CALC_INSTALL_COLOR = PRIMARY;
