import React from 'react';

const Promo = () => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M18 7c.17-.342.3-.94.3-1.3 0-1.53-1.17-2.7-2.7-2.7-1.53 0-2.79 1.426-3.6 2.596C11.19 4.426 9.93 3 8.4 3 6.96 3 5.7 4.26 5.7 5.7c0 .36.1.93.3 1.3H5a2 2 0 0 0-2 2v4h1v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-6h1V9a2 2 0 0 0-2-2h-1zm-2.4-2.2c.54 0 .9.36.9.9S16.157 7 15 7h-2c.63-.99 2.06-2.2 2.6-2.2zm-7.2 0c.54 0 1.97 1.21 2.6 2.2H9c-1.09 0-1.5-.76-1.5-1.3s.45-.9.9-.9zM11.1 19H5.7v-6h5.4v6zm1.8 0v-6h5.4v6h-5.4zm6.1-8H5V9h14v2z" />
    </svg>
  );
};

export default Promo;
