import React, { Component } from 'react';
import withPortal from 'hocs/withPortal';

import Cross from 'ui/svg-icons/Cross';

import { Service as MetricService } from 'modules/common/MetricService';

import { Overlay, Box, Close } from './styled';

type Props = React.HTMLProps<HTMLDivElement> & {
  open: boolean;
  modal?: boolean;
  onRequestClose?: () => void;
  onCancel?: () => void;
  onOpen?: () => void;
  positionTop?: boolean;
  noGap?: boolean;
  crossColor?: string;
};

@withPortal
// eslint-disable-next-line import/no-default-export
export default class Modal extends Component<Props> {
  /* eslint-disable react/sort-comp */
  private overlayRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    const { open, modal } = this.props;

    if (open && !modal) {
      this.handleOpen();
    }
  }

  componentDidUpdate(prevProps) {
    const { open, modal } = this.props;

    if ((!prevProps.open && open) || (prevProps.modal && !modal)) {
      this.handleOpen();
    }
  }

  componentWillUnmount() {
    const { open } = this.props;

    if (open) {
      this.onRequestClose();
    }
  }

  handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const { modal } = this.props;

    if (modal) {
      return;
    }

    const { target } = event;
    const { current } = this.overlayRef;

    if (target === current) {
      this.onCancel();
      MetricService.onButtonPressed('Закрыть модальное окно при нажатии на серую область');
    }
  };

  onRequestClose = () => {
    const { onRequestClose } = this.props;

    if (onRequestClose) {
      onRequestClose();
    }
  };

  onCancel = () => {
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel();
    }
    this.onRequestClose();
  };

  handleOpen() {
    const { onOpen } = this.props;

    if (onOpen) {
      onOpen();
    }
  }

  render() {
    const { className, open, positionTop, noGap, crossColor, children, title } = this.props;

    if (!open) {
      return null;
    }

    // @ts-ignore
    const name = title || (children && children.props && children.props.title);

    if (name) {
      MetricService.onPopupShown(name);
    }

    return (
      <Overlay msDisabled onClick={this.handleOverlayClick} forwardRef={this.overlayRef}>
        <Box {...{ className, positionTop, noGap }}>
          <Close
            type="button"
            msTitle="Закрыть модальное окно"
            withoutOpacity={Boolean(crossColor)}
            onClick={this.onCancel}
            data-qa="modal-close-button"
          >
            <Cross width="18" fill={crossColor || 'inherit'} />
          </Close>
          {children}
        </Box>
      </Overlay>
    );
  }
}
