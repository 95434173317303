import { GENDER } from 'const/loan';
import Model from '../Model';

// eslint-disable-next-line import/no-default-export
export default class Step1Model extends Model<Step1Model> {
  public photo = '';

  public lastName = '';

  public firstName = '';

  public patronymic = '';

  public gender: GENDER = GENDER.UNKNOWN;

  public birthDate = '';

  public email = '';

  public snils = '';

  public passportScan = '';

  public subCode = '';

  // public promoCode = '';

  public passport = '';

  public issueDate = '';

  public placeOfBirth = '';

  public issueAuthority = '';

  public esiaLock = false;

  public esiaTrusted = false;

  public inn = '';

  public constructor(data?: Partial<Step1Model>) {
    super();
    this.init(data);
  }
}
