import React from 'react';

const MaestroPaymentSystem = (props) => {
  return (
    <svg viewBox="0 0 27 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fill="#FF5F00" d="M9.52 1.685h7.032v12.338H9.52z" />
      <path
        d="M9.967 7.868c-.003-2.404 1.129-4.676 3.07-6.16C9.74-.816 5.008-.45 2.166 2.552a7.688 7.688 0 0 0 0 10.629c2.842 3 7.574 3.368 10.87.844-1.94-1.483-3.072-3.754-3.07-6.157z"
        fill="#EB001B"
      />
      <path
        d="M26.04 7.867c0 2.999-1.758 5.734-4.525 7.044a8.208 8.208 0 0 1-8.479-.887c1.939-1.485 3.07-3.755 3.07-6.158 0-2.404-1.131-4.674-3.07-6.159A8.208 8.208 0 0 1 21.515.82c2.767 1.31 4.524 4.046 4.524 7.045v.002z"
        fill="#F79E1B"
      />
    </svg>
  );
};

export default MaestroPaymentSystem;
