import React, { Component } from 'react';
import { Wrap, Label, Value } from './styled';

type Props = {
  isGray?: boolean;
  label?: React.ReactNode;
  value: React.ReactNode;
  nowrap?: boolean;
  lineThrough?: boolean;
  dataQa?: string;
  className?: string;
  isAmount?: boolean;
  onClick?: () => void;
};

// eslint-disable-next-line import/no-default-export
export default class LabelText extends Component<Props> {
  static defaultProps = {
    isGray: false,
    nowrap: true,
    lineThrough: false,
  };

  render() {
    const { isGray, label, value, nowrap, lineThrough, dataQa, className } = this.props;

    return (
      <Wrap className={className}>
        {label && <Label>{label}</Label>}
        <Value isGray={isGray} nowrap={nowrap} lineThrough={lineThrough} data-qa={dataQa}>
          {value}
        </Value>
      </Wrap>
    );
  }
}
