import { createStyledBaseConditional } from 'utils/createStyled';
import styles from './UserTablet.scss';

export const Wrap = createStyledBaseConditional('div', styles.wrap, {
  closed: styles.closed,
  tall: styles.tall,
});

export const Name = createStyledBaseConditional('div', styles.name, {
  closed: styles.closed,
});

export const Top = createStyledBaseConditional('div', styles.top, {
  verified: styles.verified,
});

export const Info = createStyledBaseConditional('div', styles.info, {
  closed: styles.closed,
});
