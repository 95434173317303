import {
  createStyled,
  createStyledBaseConditional,
  createStyledElementBase,
} from 'utils/createStyled';
import { NavLink } from 'react-router-dom';
import { Badge as BadgeDefault } from 'ui/Common';
import styles from './LeftPanel.scss';

export const Aside = createStyled('aside', styles.aside);

export const AsideTablet = createStyledBaseConditional('aside', styles.asideTablet, {
  opened: styles.opened,
});

export const AsideExit = createStyled('div', styles.asideExit);

export const Block = createStyled('div', styles.block);

export const Title = createStyled('h3', styles.title);

export const Arrow = createStyledBaseConditional('div', styles.arrow, {
  opened: styles.opened,
});

export const Row = createStyled('div', styles.row);

export const Box = createStyled('div', styles.box);

export const Cell = createStyled('div', styles.cell);

export const Badge = createStyledElementBase(BadgeDefault, styles.badge);

export const Text = createStyledBaseConditional('div', styles.text, {
  disabled: styles.disabled,
  nowrap: styles.nowrap,
  hidden: styles.hidden,
});

export const Cards = createStyled('div', styles.cards);

export const Link = createStyledElementBase(NavLink, styles.link);
