import Api from 'services/Api';

declare namespace GetCalculationTariffs {
  type Tariff = {
    tariffId: number;
    title: string;
    subtitle: string;
    periodType: number;
    iconType: number;
    description: Array<{
      content: string;
      iconType: number;
    }>;
    defaultValues: {
      amount: number;
      period: number;
    };
    range: Array<{
      maxAmount: number;
      maxPeriod: number;
      minAmount: number;
      minPeriod: number;
      stepAmount: number;
      stepPeriod: number;
    }>;
  };

  interface Response {
    tariffs: Array<Tariff>;
  }
}

declare namespace GetCounters {
  interface Response {
    cardIssuedCount: number;
    issuePeriod: number;
    issuedCountToday: number;
    repeatClientsCount: number;
  }
}

class CalculatorApi extends Api {
  getCalculationTariffs = (): Promise<GetCalculationTariffs.Response> => {
    return this.post('calculator.getCalculationTariffs', undefined, true);
  };

  calculate = ({ amount, period, tariffId, promoCode }) => {
    return this.post(
      'calculator.calculate',
      {
        amount,
        period,
        tariffId,
        promoCode,
      },
      true,
    );
  };

  getCounters = () => {
    return this.post('calculator.getCounters', undefined, true);
  };
}

// eslint-disable-next-line import/no-default-export
export default new CalculatorApi();
