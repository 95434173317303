import React, { ComponentPropsWithoutRef, FC, useEffect } from 'react';
import * as urls from 'const/urls';
import { Service as MetricService } from 'modules/common/MetricService';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<'a'>;

/**
 * Компонент отображающий ссылку на документ "Согласие заемщика (заявителя) на
 * обработку персональных данных".
 * Отправляет метрики показа и открытия документа.
 */
export const MetricAgreementLink: FC<Props> = ({ children, onClick, ...props }) => {
  const message = 'Согласие заемщика (заявителя) на обработку персональных данных';

  useEffect(() => {
    MetricService.onDocumentShown(message);
  }, [children, message]);

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }

    MetricService.onDocumentOpened(message);
  };

  return (
    <a
      onClick={handleClick}
      href={urls.DZP_AGREEMENT}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {children}
    </a>
  );
};
