import { createStyled, createStyledBaseConditional } from 'utils/createStyled';
import styles from './Toasts.scss';

export const Wrapper = createStyled('div', styles.wrapper);

export const Element = createStyledBaseConditional('div', styles.element, {
  typeNotice: styles.typeNotice,
  typeError: styles.typeError,
  typeSuccess: styles.typeSuccess,
});

export const Icon = createStyled('div', styles.icon);

export const Text = createStyled('div', styles.text);

export const Close = createStyled('button', styles.close);
