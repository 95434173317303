import React, { Component } from 'react';
import { observer } from 'mobx-react';

import ToastsStore from 'store/Toasts';

import { Toast } from './components/Toast';
import { Wrapper } from './styled';

@observer
export class Toasts extends Component {
  private toastsStore = ToastsStore;

  onToastClose = (id: number) => {
    this.toastsStore.remove(id);
  };

  render() {
    const { toastsStore } = this;
    if (toastsStore.toasts.length === 0) {
      return null;
    }

    return (
      <Wrapper>
        {toastsStore.toasts.map((toast) => (
          <Toast key={toast.id} id={toast.id} type={toast.type} onClose={this.onToastClose}>
            {toast.text}
          </Toast>
        ))}
      </Wrapper>
    );
  }
}
