import { BaseMarketingProvider } from 'services/marketing/providers/base/baseMarketingProvider';
import * as env from 'const/env';

class YandexMetricProvider extends BaseMarketingProvider {
  public sendMetric(metricName: string): void {
    if (env.IS_UNDER_DEVELOPMENT) {
      // eslint-disable-next-line no-console
      console.log(`%c Yandex reach goal: ${metricName}`, 'background: orange; color: white');
      return;
    }

    const counter =
      typeof window !== 'undefined' && window[env.YM_COUNTER] ? window[env.YM_COUNTER] : null;

    if (counter && counter.reachGoal) {
      counter.reachGoal(metricName);
    }
  }
}

// TODO Переделать в статический класс.
// eslint-disable-next-line import/no-default-export
export default new YandexMetricProvider();
