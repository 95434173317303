import React, { ComponentProps } from 'react';
import Icon from 'ui/svg-icons/Plus';
import { styled } from 'const/stitches';

import { withMetricServiceClick } from '../../hocs/metrics/withMetricServiceClick';

export const StyleButton = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginTop: '1rem',
  outline: 'none',
  height: '48px',
  '@mediaLaptopAndMore': { marginTop: '1.5rem' },
  '&:first-of-type': { marginTop: '0' },

  variants: {
    small: {
      true: {
        height: '36px',
      },
    },
    highlighted: {
      true: {
        cursor: 'pointer',
      },
    },
    addNew: {
      true: {
        cursor: 'pointer',
      },
    },
  },
});

export const Button = withMetricServiceClick<ComponentProps<typeof StyleButton>>(StyleButton);

export const Plus = styled(Icon, {
  fill: '$color-disabled',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate3d(-50%, -50%, 0)',
});

export const Logo = styled('div', {
  position: 'absolute',
  top: '1px',
  left: '0',
  padding: '0 3px',
  img: {
    display: 'block',
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '10px',
  },
  svg: { display: 'block', minWidth: '14px', maxHeight: '14px' },
  '@mediaLaptopAndMore': {
    img: { maxHeight: '15px' },
    svg: { minWidth: '19px', maxHeight: '17px' },
  },
});

export const Type = styled('div', {
  position: 'absolute',
  bottom: '3px',
  right: '3px',
  svg: {
    display: 'block',
    minWidth: '16px',
    minHeight: '12px',
    maxHeight: '14px',
    maxWidth: '16px',
  },
  '@mediaLaptopAndMore': {
    svg: { minWidth: '26px', maxHeight: '16px', maxWidth: '26px' },
  },

  variants: {
    small: {
      true: {
        svg: {
          minWidth: '16px',
          minHeight: '12px',
          maxHeight: '14px',
          maxWidth: '16px',
        },
      },
    },
  },
});

export const Title = styled('div', {
  color: '$color-text-primary',
  fontSize: '16px',
  lineHeight: 1.25,
  paddingLeft: '1rem',
  boxSizing: 'border-box',
  textAlign: 'left',
  '@mediaTabletLAndLess': {
    visibility: 'visible',
    opacity: 1,
    display: 'block',
    transition: 'visibility 0.15s ease-out',
  },
  variants: {
    closed: {
      true: {
        '@mediaTabletLAndLess': {
          visibility: 'hidden',
          opacity: 0,
          display: 'none',
        },
      },
    },
    small: {
      true: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
  },
});

export const Info = styled('div', {
  color: '$color-text-secondary',
  fontSize: '14px',
  lineHeight: 'initial',
  paddingLeft: '1rem',
  boxSizing: 'border-box',
  textAlign: 'left',
  '@mediaTabletLAndLess': {
    visibility: 'visible',
    opacity: 1,
    display: 'block',
    transition: 'visibility 0.15s ease-out',
  },

  variants: {
    closed: {
      true: {
        '@mediaTabletLAndLess': {
          visibility: 'hidden',
          opacity: 0,
          display: 'none',
        },
      },
    },
    small: {
      true: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
  },
});

const StyleCard = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  background: '#fff',
  overflow: 'hidden',
  borderRadius: '4px',
  transition: '0.25s ease-out',
});

type CardProps = { bg?: string; border?: string };
export const Card: React.FC<CardProps> = ({ bg, border, ...other }) => {
  const style =
    bg || border
      ? {
          ...(bg ? { background: bg } : {}),
          ...(border ? { border: `thin solid ${border}` } : {}),
        }
      : undefined;
  return (
    <StyleCard
      css={{
        ...style,
      }}
      {...other}
    />
  );
};

export const Outline = styled('div', {
  width: '80px',
  minWidth: '80px',
  height: '48px',

  variants: {
    highlighted: {
      true: {
        padding: '3px 3px',
        border: 'dashed 1px #d93151',
        borderRadius: '4px',
      },
    },
    small: {
      true: {
        width: '48px',
        minWidth: '48px',
        height: '32px',
      },
    },
  },
});
