export const FROM_CALC = 'fromCalc';
export const LOGIN_PHONE = 'mobilePhone';
export const LOGIN_AUTOSUBMIT = 'submit';
export const AFTER_LOGIN_REDIRECT = 'redirectTo';

export const ESIA_CODE = 'code';
export const ESIA_STATE = 'state';

// from do-zarplati.com
export const CALC_PERIOD = 'c_period';
export const CALC_AMOUNT = 'c_amount';
export const CALC_TARIFF = 'c_tariff';
export const CALC_TOTAL_DAYS = 'c_days';
// export const CALC_PROMO_CODE = 'c_promocode';

export const ADDITIONAL_SERVICES = 'services';
export const ADDITIONAL_SERVICES_BROKERAGE_AMOUNT = 'amount';
export const ADDITIONAL_SERVICES_BROKERAGE_SERVICE_COST = 'serviceCost';
export const ADDITIONAL_SERVICES_BROKERAGE_OLD_SERVICE_COST = 'oldServiceCost';
