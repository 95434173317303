import { observable, action } from 'mobx';

import { setAbandonedState } from 'services/backend/Client';
import Cookie from 'utils/Cookie';

import * as cookies from 'const/cookies';
import { Service as ClickhouseMetricService } from '../modules/common/ClickhouseMetric';

const STATUS_ACTIVE = 1;
const STATUS_STOP = 2;
const TOOLTIP_PADDING = 36;
const MAX_HOUR_INACTIVITY = 2;
const ONE_HOUR_MS = 3600000;

class AppStore {
  // показан ли тултип "Как увеличить сумму и уменьшить ставку"
  @observable
  isImproveLoanOptionsTooltipShown = false;

  // позиция (y) для тултипа
  @observable
  improveLoanOptionsTooltipPositionTop = 0;

  // Таймер для отслеживания брошенной корзины
  private pingTimerId = null;

  // Таймер для отслеживания активности пользователя
  private pingTimerActivityId = null;

  sendPing = async () => {
    const firstName = Cookie.get(cookies.APPLICATION_FIRST_NAME);
    const lastName = Cookie.get(cookies.APPLICATION_LAST_NAME);
    const patronymic = Cookie.get(cookies.APPLICATION_PATRONYMIC);
    const failedMetrics = JSON.parse(Cookie.get('_d_failed_metrics')) || [];
    const succeedResolve = await setAbandonedState({
      status: STATUS_ACTIVE,
      firstName,
      lastName,
      patronymic,
    });
    if (succeedResolve) {
      if (failedMetrics.length > 0) {
        failedMetrics.forEach((failedMetric) => {
          ClickhouseMetricService.push(failedMetric.title, failedMetric.value);
        });
        Cookie.set('_d_failed_metrics', JSON.stringify([]));
      }
    }
  };

  startPingActivityWatcher = () => {
    let hourSinceLastActivity = 0;

    this.pingTimerActivityId = setInterval(() => {
      // eslint-disable-next-line no-plusplus
      hourSinceLastActivity++;
      if (hourSinceLastActivity >= MAX_HOUR_INACTIVITY) {
        this.stopPingTimer(STATUS_ACTIVE);
      }
    }, ONE_HOUR_MS);

    function activity() {
      hourSinceLastActivity = 0;
    }

    const activityEvents = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];

    activityEvents.forEach((eventName) => {
      document.addEventListener(eventName, activity, true);
    });
  };

  startPingTimer = () => {
    this.sendPing();
    this.startPingActivityWatcher();
    this.pingTimerId = setInterval(this.sendPing, 50 * 1000);
  };

  stopPingTimer = (status = STATUS_STOP) => {
    clearInterval(this.pingTimerId);
    clearInterval(this.pingTimerActivityId);

    const firstName = Cookie.get(cookies.APPLICATION_FIRST_NAME);
    const lastName = Cookie.get(cookies.APPLICATION_LAST_NAME);
    const patronymic = Cookie.get(cookies.APPLICATION_PATRONYMIC);

    setAbandonedState({ status, firstName, lastName, patronymic });

    if (status === 2) {
      Cookie.delete(cookies.APPLICATION_FIRST_NAME);
      Cookie.delete(cookies.APPLICATION_LAST_NAME);
      Cookie.delete(cookies.APPLICATION_PATRONYMIC);
    }
  };

  @action
  toggleShowImproveLoanOptionsTooltip = (event) => {
    if (!this.isImproveLoanOptionsTooltipShown) {
      const { top } = event.currentTarget.getBoundingClientRect();

      this.improveLoanOptionsTooltipPositionTop =
        top + document.documentElement.scrollTop + TOOLTIP_PADDING;
    } else {
      this.improveLoanOptionsTooltipPositionTop = 0;
    }

    this.isImproveLoanOptionsTooltipShown = !this.isImproveLoanOptionsTooltipShown;
  };
}

// eslint-disable-next-line import/no-default-export
export default new AppStore();
