import { parse, stringify } from 'query-string';

// eslint-disable-next-line import/no-default-export
export default class Url {
  static getQueryParameters(str) {
    return parse(str, { arrayFormat: 'bracket' });
  }

  static buildUrl = (url, queryParams) => {
    const queryString = stringify(queryParams, { arrayFormat: 'bracket' });
    return `${url}${queryString ? `?${queryString}` : ''}`;
  };

  static setHashToCurrent(hash) {
    const href = window.location.href.replace(/#.+$/, '');
    const next = `${href}#${hash}`;
    window.location.replace(next);
  }
}
