import React from 'react';

const Error = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <path
        fill="#FF5353"
        d="M24 42c9.9411255 0 18-8.0588745 18-18S33.9411255 6 24 6 6 14.0588745 6 24s8.0588745 18 18 18zm0 4C11.8497355 46 2 36.1502645 2 24S11.8497355 2 24 2s22 9.8497355 22 22-9.8497355 22-22 22zm0-24.8284271l-7.7781746-7.7781746-2.8284271 2.8284271L21.1715729 24l-7.7781746 7.7781746 2.8284271 2.8284271L24 26.8284271l7.7781746 7.7781746 2.8284271-2.8284271L26.8284271 24l7.7781746-7.7781746-2.8284271-2.8284271L24 21.1715729z"
      />
    </svg>
  );
};

export default Error;
