import { observable, action } from 'mobx';

class StarAnimation {
  // Отображать компонент Header поверх всего
  @observable
  headerOverElements = false;

  // 1 шаг был заполнен
  @observable
  step1Filled = false;

  // 2 шаг был заполнен
  @observable
  step2Filled = false;

  @action
  setOverHeader = (visible) => {
    this.headerOverElements = visible;
  };

  @action
  setStep1Filled = (visible) => {
    this.step1Filled = visible;
  };

  @action
  setStep2Filled = (visible) => {
    this.step2Filled = visible;
  };
}

// eslint-disable-next-line import/no-default-export
export default new StarAnimation();
