import {
  createStyled,
  createStyledBaseConditional,
  createStyledElementBase,
} from 'utils/createStyled';
import { NavLink } from 'react-router-dom';
import { Badge as BadgeDefault } from 'ui/Common';
import styles from './Header.scss';

export const Section = createStyled('header', styles.section);

export const Row = createStyledBaseConditional('div', styles.row, {
  blank: styles.rowBlank,
});

export const Logo = createStyled('a', styles.logo);

export const TakeLoanLogo = createStyled('a', styles.takeLoanLogo);

export const Nav = createStyledBaseConditional('nav', styles.nav, {
  mobileOpen: styles.mobileOpen,
});

export const Block = createStyled('div', styles.block);

export const LinkInner = createStyledBaseConditional('div', styles.linkInner, {
  marked: styles.marked,
  arrow: styles.arrow,
  arrowOpened: styles.arrowOpened,
});

export const Link = createStyledElementBase(NavLink, styles.link);

export const LinkOut = createStyled('a', styles.link);

export const NavBtn = createStyled('a', styles.navBtn);

export const MenuBtn = createStyled('a', styles.menuBtn);

export const Badge = createStyledElementBase(BadgeDefault, styles.badge);

export const Inner = createStyled('div', styles.inner);

export const Trigger = createStyledBaseConditional('div', styles.trigger, {
  opened: styles.opened,
});

export const AmountOfAdditionalServices = createStyledBaseConditional(
  'span',
  styles.amountOfAdditionalServices,
  {
    inToggle: styles.inToggle,
  },
);
