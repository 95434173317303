import React, { ComponentPropsWithoutRef, FC } from 'react';
import { observer } from 'mobx-react';

import BaseRadioButton from 'ui/FormControls/RadioButton';
import { Service as MetricService } from 'modules/common/MetricService';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof BaseRadioButton>;

export const RadioButton: FC<Props> = observer(({ field, value, onClick, ...props }) => {
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }

    MetricService.onButtonPressed(`${field.label} | ${value}`);
  };

  return (
    <BaseRadioButton
      {...field.bind({
        value,
        checked: field.value === value,
      })}
      label={value}
      onClick={handleClick}
      {...props}
    />
  );
});
