import React from 'react';
import { styled } from 'const/stitches';

export const LoanIcon = styled('div', {
  width: '48px',
  minWidth: '48px',
  height: '48px',
  borderRadius: '50%',
  position: 'relative',
  background: '$color-icon-bg',
  svg: {
    position: 'absolute',
    display: 'block',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    fill: '$color-text-secondary',
  },
});

export const DocumentLink = styled('a', {
  textDecoration: 'underline',
  color: '$color-text-secondary',
  fontSize: '13px',
  padding: '0.5rem 1.5rem',
  boxSizing: 'border-box',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1.3,
  svg: { minWidth: '16px', marginRight: '1em' },
  '@mediaMobileAndLess': {
    margin: '0.75rem 0',
    padding: '0.5rem',
    '&:last-of-type': { marginBottom: '0' },
  },
  '@mediaTabletLAndMore': { padding: '0.5rem 2rem' },
  '@mediaDesktopAndMore': { fontSize: '14px' },
});

type BadgeProps = {
  color?: string;
};

const StyleBadge = styled('div', {
  display: 'inline-block',
  textAlign: 'center',
  minWidth: '40px',
  height: '24px',
  background: '$color-main',
  lineHeight: '24px',
  fontSize: '12px',
  fontWeight: 'bold',
  borderRadius: '4px',
  padding: '0 2px',
  boxSizing: 'border-box',
  whiteSpace: 'nowrap',
  color: '$color-text-primary',
});

export const Badge: React.FC<BadgeProps> = ({ color, ...others }) => {
  return (
    <StyleBadge
      css={{
        background: color,
      }}
      {...others}
    />
  );
};
