import { useEffect, FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Service } from 'modules/common/MetricService';

/**
 * Компонент анализирует изменение url страницы.
 */
export const MetricPageEnter: FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    Service.setPageFromCookie(window.location.href);
    Service.onPageEnter(window.location.href);
  }, [pathname]);

  return null;
};
