/**
 * How many days to subtract
 */
export const LOAN_DIFF_DAYS = 0;

/**
 * Loan types (currentLoan.type)
 */
export const enum LOAN_TYPE {
  /** PDL. Мини и Смарт. */
  SHORT = 1,
  /**  INSTALL */
  LONG = 2,
  /** Заем первичника. */
  FIRST_180 = 3,
}

export const enum FULL_LOAN_TYPE {
  SMART = 8,
  SMART_WITH_SCHEDULE = 12,
  MINI = 13,
  /** Заем "Смар 15" */
  SMART_15 = 14,
  /** Заем "Смар 20" */
  SMART_20 = 15,
  /** Заем "Смар 25" */
  SMART_25 = 16,
  /** Заем "Смар 30" */
  SMART_30 = 17,
  /** Заем "Аннуитет 15" */
  ANNUITET_15 = 18,
  /** Заем "Аннуитет 30" */
  ANNUITET_30 = 19,
}

/**
 * Варианты `insertId` в преордерах.
 */
export const enum PRE_ORDER_INSET_ID {
  /** Оригинальный КД */
  ORIGINAL = 0,
  /** Альтернативный КД (только для кд2 самоход) */
  ORIGINAL_V2 = 1,
  /** Мини (только для кд2 самоход) */
  MINI = 2,
  /** Смарт с сп (текущий)  */
  SMART_SP = 3,
  SMART_15 = 4,
  SMART_20 = 5,
  SMART_25 = 6,
  SMART_30 = 7,
}

/**
 * Loan Tariffs (currentLoan.loanType)
 */
export const TYPE_FIRST_180_ADDITIONAL = 10; // 180
export const TYPE_REPEAT_180 = 11;

/**
 * Тариф "Начини с нуля".
 */
export const TYPE_START_FROM_SCRATCH = 101;

/**
 * Статусы займа.
 */
export const enum LOAN_STATUS {
  /**
   * Займа нет.
   */
  NO_LOAN = 0,

  /**
   * Заем на рассмотрении.
   */
  UNDERWRITING = 1,

  /**
   * В займе отказано.
   */
  REJECTED = 2,

  /**
   * Займ одобрен.
   */
  APPROVED = 3,

  /**
   * Займ имеется.
   */
  HAS_LOAN = 4,
  HAS_OUTDATED_LOAN = 5,
  APPROVED_CHANGED = 6,
  READY_FOR_PAYOUT = 7,
  PAYOUT_PENDING = 8,
  PAYOUT_WAITING = 10,
}

/**
 * Источник займа. Обозначает, каким образом этот займ был создан. В некоторых
 * случаях, в зависимости от источника займа, у него могут быть отличия
 * в бизнес-флоу.
 */
export const enum LOAN_SOURCE {
  /**
   * Источник займа не определён. Это означает, что данные по займу ещё не были
   * загружены, или у текущего пользователя нет активных займов.
   */
  NONE = 0,

  /**
   * Источник займа - заявка из ЛК или физическое обращение в офис. Это обычный
   * флоу возникновения займа.
   */
  NORMAL = 1,

  /**
   * Предодобренный займ. Иногда пользователю, который создал подал заявку на
   * займ, может быть предложен другой займ на более выгодных условиях. На
   * логическом уровне просходит следующее: старый займ пользователя удаляется,
   * взамен создаётся этот.
   */
  NORMAL_PREAPPROVED = 3,

  /**
   * Займ был создан автоматически после успешного погашения предыдущего займа.
   * Это своеобразная рекламная компания для стимулирования пользователей.
   */
  PREAPPROVED_AFTER_REPAY = 2,
}

/**
 * Loan document types
 */
export const enum DOCUMENT_TYPE {
  AGREEMENT = 1,
  PROLONGATION_AGREEMENT = 2,
  ADDITIONAL = 13,
  SCHEDULE = 3,
  PAYMENTS = 4,
  PRIVILEGE = 7,
  PRIVILEGE_3 = 9,
  INTERACTION_WITH_DEBTOR = 21,
  COLLECTORS = 22,
  AMOUNT_CHANGE = 25,
  CONCESSION = 26,
  NOTICES_ABOUT_THE_RIGHT_TO_REFUSE_SERVICES = 31,
  NOTIFICATION_OF_ENGAGING_A_COLLECTION_AGENCY = 32,
  /** Уведомление об уступке права требования по абонентскому договору */
  NOTICE_OF_ASSIGNMENT_OF_CLAIM_RIGHTS = 34,
}

// Auto approve statuses
export const AUTO_APPROVE_UNKNOWN = 0;
export const AUTO_APPROVE_ACCEPTED = 1;
export const AUTO_APPROVE_REJECTED = 2;

// Person identification statuses
export const PERSON_ID_NEED_IDENTIFICATION = 1;
export const PERSON_ID_PROCESSING = 2;
export const PERSON_ID_IDENTIFIED = 3;
export const PERSON_ID_REJECTED = 4;

/**
 * Loan rejected days
 */
export const REJECTED_WAITING_DAYS_SECOND = 53;

/**
 * Genders
 */
export const enum GENDER {
  UNKNOWN = 0,
  MALE = 1,
  FEMALE = 2,
}

/**
 * Loan approved types
 */
export const enum APPROVED_TYPE {
  SAME = 0,
  OTHER = 1,
  BETTER = 2,
}

/**
 * PDL period 15
 */
export const PDL_PERIOD_15 = 15;

/**
 * List constants
 */
export const MARITAL_STATUS = [
  {
    label: 'холост/не замужем',
    value: 1,
  },
  {
    label: 'гражданский брак',
    value: 2,
  },
  {
    label: 'женат/замужем',
    value: 3,
  },
  {
    label: 'в разводе',
    value: 4,
  },
];

export const FAMILY_COUNT = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: 'Более 5',
    value: 6,
  },
];

export const CHILDREN_COUNT = [
  {
    label: 'Нет',
    value: 0,
  },
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: 'Более 5',
    value: 6,
  },
];

export const CONTACT_RELATION = [
  {
    label: 'Член семьи',
    value: 1,
  },
  {
    label: 'Друг/знакомый',
    value: 2,
  },
  {
    label: 'Коллега по работе',
    value: 3,
  },
];

export const MAX_CONTACT_PERSONS = 3;

export const EXPERIENCE = [
  {
    label: '0 - 3 месяца',
    value: 1,
  },
  {
    label: '3 - 6 месяцев',
    value: 2,
  },
  {
    label: '6 - 12 месяцев',
    value: 3,
  },
  {
    label: 'более года',
    value: 4,
  },
];

export const SALARY_OFFICIAL = [
  {
    label: '5 000 - 10 000 р.',
    value: 6,
  },
  {
    label: '10 000 - 15 000 р.',
    value: 7,
  },
  {
    label: '15 000 - 20 000 р.',
    value: 8,
  },
  {
    label: '20 000 - 25 000 р.',
    value: 9,
  },
  {
    label: '25 000 - 30 000 р.',
    value: 10,
  },
  {
    label: '30 000 - 35 000 р.',
    value: 11,
  },
  {
    label: '35 000 - 40 000 р.',
    value: 12,
  },
  {
    label: '40 000 - 45 000 р.',
    value: 13,
  },
  {
    label: '45 000 - 50 000 р.',
    value: 14,
  },
  {
    label: '50 000 - 55 000 р.',
    value: 15,
  },
  {
    label: '55 000 - 60 000 р.',
    value: 16,
  },
  {
    label: '60 000 - 80 000 р.',
    value: 17,
  },
  {
    label: '80 000 - 100 000 р.',
    value: 18,
  },
  {
    label: '100 000 - 120 000 р.',
    value: 19,
  },
  {
    label: '120 000 - 140 000 р.',
    value: 20,
  },
  {
    label: '140 000 - 160 000 р.',
    value: 21,
  },
  {
    label: '160 000 - 180 000 р.',
    value: 22,
  },
  {
    label: '180 000 - 200 000 р.',
    value: 23,
  },
  {
    label: '200 000 - 220 000 р.',
    value: 24,
  },
  {
    label: '220 000 - 240 000 р.',
    value: 25,
  },
  {
    label: '240 000 - 260 000 р.',
    value: 26,
  },
  {
    label: '260 000 - 280 000 р.',
    value: 27,
  },
  {
    label: '280 000 - 300 000 р.',
    value: 28,
  },
  {
    label: '300 000 - 320 000 р.',
    value: 29,
  },
  {
    label: '320 000 - 340 000 р.',
    value: 30,
  },
  {
    label: '340 000 - 360 000 р.',
    value: 31,
  },
  {
    label: '360 000 - 380 000 р.',
    value: 32,
  },
  {
    label: '380 000 - 400 000 р.',
    value: 33,
  },
  {
    label: 'более 400 000 р.',
    value: 34,
  },
];

export const CREDIT_LOAD = [
  {
    label: 'нет активных кредитов',
    value: 0,
  },
  {
    label: 'до 10 000 р.',
    value: 1,
  },
  {
    label: '10 001 - 20 000 р.',
    value: 2,
  },
  {
    label: '20 001 - 35 000 р.',
    value: 3,
  },
  {
    label: '35 001 - 50 000 р.',
    value: 4,
  },
  {
    label: 'более 50 000 р.',
    value: 5,
  },
];

export const PURPOSE = [
  {
    label: 'Личные нужды',
    value: 1,
  },
  {
    label: 'Крупная покупка',
    value: 2,
  },
  {
    label: 'Погашение кредита / отдать долг',
    value: 3,
  },
  {
    label: 'Коммунальные платежи',
    value: 4,
  },
  {
    label: 'Лечение / лекарства',
    value: 5,
  },
  {
    label: 'Ремонт',
    value: 6,
  },
  {
    label: 'Учёба',
    value: 7,
  },
  {
    label: 'Праздник',
    value: 8,
  },
  {
    label: 'Подарок',
    value: 9,
  },
  {
    label: 'На поездку',
    value: 10,
  },
];

export const HOUSING_TYPE = [
  {
    label: 'Собственное отдельное жилье',
    value: 1,
  },
  {
    label: 'Аренда',
    value: 2,
  },
  {
    label: 'Общежитие',
    value: 3,
  },
  {
    label: 'Коммунальная квартира',
    value: 4,
  },
  {
    label: 'Жильё родственников',
    value: 5,
  },
];

export const REGISTRATION_TYPE_TEMPORARY = 1;
export const REGISTRATION_TYPE_PERMANENT = 2;

export const TYPE_OF_WORK_NO_WORK = 1;

export const TYPE_OF_WORK = [
  {
    label: 'Не работаю',
    value: TYPE_OF_WORK_NO_WORK,
  },
  {
    label: 'Частичная занятость',
    value: 2,
  },
  {
    label: 'Полный рабочий день',
    value: 3,
  },
  {
    label: 'Сменный график',
    value: 4,
  },
  {
    label: 'Свободный график',
    value: 5,
  },
  {
    label: 'Удаленная работа',
    value: 6,
  },
  {
    label: 'Вахта',
    value: 7,
  },
];

export const SALARY_DATE = [];

for (let i = 1; i <= 31; i += 1) {
  SALARY_DATE.push({
    label: `${i} числа`,
    value: i,
  });
}

export const FAMILY_MEMBERS = [
  { value: 3, label: 'Муж' },
  { value: 4, label: 'Жена' },
  { value: 10, label: 'Сын' },
  { value: 11, label: 'Дочь' },
  { value: 8, label: 'Отец' },
  { value: 7, label: 'Мать' },
  { value: 2, label: 'Сестра' },
  { value: 1, label: 'Брат' },
  { value: 6, label: 'Бабушка' },
  { value: 5, label: 'Дедушка' },
  { value: 12, label: 'Внучка' },
  { value: 13, label: 'Внук' },
  { value: 14, label: 'Домашнее животное' },
];

export const MULTIPLE_FAMILY_MEMBERS = [1, 2, 5, 6, 10, 11, 12, 13, 14];

export const USER_TYPE = {
  FIRST: 'first',
  REPEAT: 'repeat',
};
