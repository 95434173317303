import { action, computed, observable } from 'mobx';
import moment from 'moment';
import jwtDecode from 'jwt-decode';

import ClientModel from 'models/Client';
import { CreditCardModel } from 'models/CreditCard';
import Cookie from 'utils/Cookie';
import Helpers from 'utils/Helpers';
import * as cookies from 'const/cookies';
import * as bonuses from 'const/bonuses';
import * as ClientApi from 'services/backend/Client';
import { getProfile } from 'services/backend/Client';
import { LOAN_STATUS } from 'const/loan';
import CurrentLoanStore from 'store/CurrentLoan';
import PaidServicesStore from 'store/PaidServices';
import { SERVICE_TYPE } from 'const/paidService';
import { AuthMetricStore } from 'store/AuthMetricStore';
// https://jira.devim.team/browse/DWN-12373 временное отключение метрик из SENTRY
// import { Service as SentryService } from 'modules/common/Sentry';
import { Service as MetricService } from 'modules/common/MetricService';

import { QuestionsStore } from 'modules/Questions';
import { ABTestStore } from './ABTest';

/*
 * Свойства декодированного токена авторизации.
 */
type Token = Partial<{
  id: string;
  userId: number;
  roles: string[];
  salt: string;
  exp: number;
}>;

/**
 * Стор для работы с информацией о пользователе.
 */
class ClientStore {
  constructor() {
    this.confirmRoles();
  }

  @observable
  public inProcess = false;

  @observable
  public currentUser = new ClientModel();

  @observable
  public hasSuccess = false;

  private fetching: Promise<ClientModel> = null;

  @observable
  public creditCards: Array<CreditCardModel> = [];

  @computed
  get age() {
    if (!this.hasSuccess) {
      return undefined;
    }

    const birthDate = moment(this.getValue('birthDate').get(), 'DD.MM.YYYY').startOf('day');
    const currentDate = moment().startOf('day');

    return currentDate.diff(birthDate, 'years');
  }

  get isLoggedIn() {
    return !!Cookie.get(cookies.USER_TOKEN) && Cookie.get(cookies.USER_TOKEN_CONFIRMED) === 'true';
  }

  @computed
  get rateIsHidden() {
    const currentLoanStatus = CurrentLoanStore.getValue('status').get();
    const hidden = this.getValue('isNew').get()
      ? [LOAN_STATUS.NO_LOAN, LOAN_STATUS.UNDERWRITING, LOAN_STATUS.REJECTED].includes(
          currentLoanStatus,
        )
      : currentLoanStatus === LOAN_STATUS.REJECTED;

    return hidden && !PaidServicesStore.isPurchased(SERVICE_TYPE.REASON_OF_REFUSAL);
  }

  @computed
  get name() {
    const lastName = this.getValue('lastName').get();
    const firstName = this.getValue('firstName').get();
    const mobilePhone = this.getValue('mobilePhone').get();

    return lastName && firstName ? `${lastName} ${firstName}` : Helpers.asMobilePhone(mobilePhone);
  }

  @computed
  get bonusRank() {
    const bonusesValue = this.getValue('bonuses').get();

    const level = bonuses.RANKS.reduce((prev, current, index) => {
      if (current.minStars <= bonusesValue && current.maxStars >= bonusesValue) {
        return index;
      }

      return prev;
    }, 0);

    return bonuses.RANKS[level].title;
  }

  @computed
  get identificationCode() {
    const c = String(this.getValue('identificationCode').get());

    if (c.length === 8 || c.length === 7) {
      return `${c.substring(0, 3)}-${c.substring(3, 5)}-${c.substring(5, 8)}`;
    }

    return '';
  }

  @action
  getValue<K extends keyof ClientModel>(param: K) {
    return computed(() => this.currentUser[param]);
  }

  @action
  setValue<K extends keyof ClientModel>(param: K, value: ClientModel[K]) {
    return this.currentUser.setValue(param, value);
  }

  saveToken = (token: string) => {
    Cookie.set(cookies.USER_TOKEN, token);
  };

  deleteToken = () => {
    Cookie.delete(cookies.USER_TOKEN);
  };

  confirmToken = () => {
    Cookie.set(cookies.USER_TOKEN_CONFIRMED, true);
  };

  deleteTokenConfirmation = () => {
    Cookie.delete(cookies.USER_TOKEN_CONFIRMED);
  };

  confirmRoles = () => {
    const token = Cookie.get(cookies.USER_TOKEN);

    if (!token) {
      Cookie.delete(cookies.OPERATOR);
      return;
    }

    const { roles } = jwtDecode<Token>(token);
    const hasRoles = roles && Array.isArray(roles);
    if (!hasRoles) {
      Cookie.delete(cookies.OPERATOR);
      return;
    }

    /** Роль оператора. */
    const isRoleEmployee = roles.indexOf('ROLE_EMPLOYEE') >= 0;
    if (isRoleEmployee) {
      Cookie.set(cookies.OPERATOR, 1);
    } else {
      Cookie.delete(cookies.OPERATOR);
    }
  };

  deleteAuthCredentials = (silent?: boolean) => {
    if (!silent) {
      AuthMetricStore.instance.dispatchLogout();
    }

    this.reset();
    this.deleteToken();
    this.deleteTokenConfirmation();
    this.confirmRoles();
    sessionStorage.clear();
    MetricService.deleteUserTypeCookie();
    MetricService.deleteABTestCookie();

    // https://jira.devim.team/browse/DWN-12373 временное отключение метрик из SENTRY
    // SentryService.setUserContext();

    ABTestStore.getInstance().clearInactiveABTests();

    QuestionsStore.getInstance().setIsQuestionsSubmitted(false);
  };

  public authorize = async (token: string) => {
    this.saveToken(token);
    this.confirmToken();
    this.confirmRoles();

    this.reset();
    await this.fetch();
    await CurrentLoanStore.fetch();

    /*
     * Инициализируем AB-тесты здесь, так как необходимо распределять
     * юзеров по группам только по мере авторизации
     */
    MetricService.setUserTypeCookie(true);
    Cookie.set(cookies.FAILED_METRICS, JSON.stringify([]));
    ABTestStore.getInstance().clearInactiveABTests();
    ABTestStore.getInstance().sendMetric();
    AuthMetricStore.instance.dispatchLogin();

    // https://jira.devim.team/browse/DWN-12373 временное отключение метрик из SENTRY
    // SentryService.setUserContext();
  };

  public touch() {
    return this.hasSuccess ? Promise.resolve(this.currentUser) : this.fetch();
  }

  @action
  public reset() {
    this.hasSuccess = false;
  }

  @action
  public fetch = async () => {
    this.fetching =
      this.fetching ||
      (async () => {
        this.inProcess = true;
        try {
          const response = await getProfile();
          this.currentUser = new ClientModel(response);
          await CurrentLoanStore.fetch();
          this.hasSuccess = true;
          return this.currentUser;
        } catch (e) {
          this.hasSuccess = false;
          throw e;
        } finally {
          this.fetching = null;
          this.inProcess = false;
        }
      })();

    return this.fetching;
  };

  @action
  fetchCreditCards = () => {
    return ClientApi.getCreditCards().then((response) => {
      this.creditCards = response.map((card) => new CreditCardModel(card));
      // this.setValue('creditCards', cards);
    });
  };

  @computed
  get isPassportPhotoRequired() {
    return false;
  }

  @computed
  get clientType(): 'first' | 'repeat' | undefined {
    const isFirst = this.getValue('isNew').get();

    if (isFirst == null) {
      return undefined;
    }

    return isFirst ? 'first' : 'repeat';
  }
}

const clientStore = new ClientStore();

// eslint-disable-next-line import/no-default-export
export default clientStore;
