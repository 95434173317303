import { observable, computed, action } from 'mobx';

import BrokeragePaidService from 'services/BrokeragePaidService';
import BrokerageServiceModel from 'models/BrokerageService';

import { SERVICE_STATUS } from 'const/paidService';

class BrokeragePaidServiceStore {
  @observable
  service = new BrokerageServiceModel();

  @observable
  isDataLoaded = false;

  // возвращает услуги для клиента
  @action
  fetch = () => {
    this.isDataLoaded = false;

    return BrokeragePaidService.get()
      .then((response) => {
        this.service = new BrokerageServiceModel(response);
        this.isDataLoaded = true;
      })
      .catch((e) => {
        this.isDataLoaded = true;
        throw Error(e.message);
      });
  };

  @computed
  get isBrokerageServiceAvailable() {
    return this.service.status && this.service.status !== SERVICE_STATUS.NOT_AVAILABLE;
  }
}

export default new BrokeragePaidServiceStore();
