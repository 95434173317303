import React from 'react';
import { Avatar } from 'ui/Avatar';
import { Wrap, Top } from './styled';

export const UserBlank: React.FC = () => {
  return (
    <Wrap>
      <Top>
        <Avatar large />
      </Top>
    </Wrap>
  );
};
