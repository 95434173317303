import React, { ComponentPropsWithoutRef, ReactNode, FC } from 'react';
import { Button as BaseButton, Icon, Text } from './styled';

type Props = Omit<ComponentPropsWithoutRef<typeof BaseButton>, 'icon'> & {
  /**
   * Добавочная иконка.
   */
  icon?: ReactNode;
};

export const Button: FC<Props> = ({ children, icon, ...props }) => (
  <BaseButton {...props} icon={Boolean(icon)}>
    {icon ? (
      <>
        <Text>{children}</Text>
        <Icon>{icon}</Icon>
      </>
    ) : (
      children
    )}
  </BaseButton>
);
