import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import * as urls from 'const/urls';

export const drawRoutes = (routes: Array<RouteProps>): Array<React.ReactNode> => {
  const rts = routes && routes.length > 0
    ? routes.map((route, key) => <Route {...route} key={key} />)
    : [];

  rts.push(<Redirect to={urls.NOT_FOUND} key={routes.length}/>);

  return rts;
};
