// TODO: update loaders to load properly

// @ts-ignore
import { createStitches } from '@stitches/react';

// @ts-ignore
export const { styled, globalCss, keyframes } = createStitches({
  theme: {
    colors: {
      'color-text-primary': '#4E4E64',
      'color-text-secondary': '#A2A2B2',
      'color-main': '#FFC800',
      'color-main-hover': '#ffde66',
      'color-main-disabled': 'rgba(255, 200, 0, 0.3)',
      'color-secondary': '#57B560',
      'color-secondary-hover': '#9ed4a3',
      'color-divider': '#ECECEF',
      'color-border': '#BEBEC8',
      'color-success': '#22D22E',
      'color-error': '#ff5353',
      'color-red': '#ed4242',
      'color-placeholder': '#d1d1d9',
      'color-icon-bg': '#f2f2fb',
      'color-disabled': '#dadae0',
      'color-grey-5': '#f7f7fb',
      'color-grey-80': '#787891',
      'color-text': '#4E4E65',
      'color-rate-normal': '#7BD83B',
      'color-pdl-calculator': '#57B560',
      'color-installment-calculator': '#FFC800',
      'color-bg': '#F7F7FB',
      'color-autofill': 'rgb(232, 240, 254)',
    },
    sizes: {
      'size-xs': '420px',
      'size-sm': '720px',
      'size-md': '1024px',
      'size-lg': '1280px',
      'size-xl': '1600px',
      'mobile-header': '64px',
      'tablet-header': '65px',
      'width-mobile-aside': '96px',
      'width-desktop-aside': '264px',
    },
  },
  media: {
    mediaMobilePortraitAndLess: '(max-width: 419px)',
    mediaMobileAndLess: '(max-width: 719px)',
    mediaTabletAndLess: '(max-width: 1023px)',
    mediaTabletLAndLess: '(max-width: 1279px)',
    mediaLaptopAndLess: '(max-width: 1599px)',
    mediaMobileAndMore: '(min-width: 420px)',
    mediaTabletAndMore: '(min-width: 720px)',
    mediaCustomMinHeight964: '(min-height: 964px)',
    mediaTabletLAndMore: '(min-width: 1024px)',
    mediaLaptopAndMore: '(min-width: 1280px)',
    mediaDesktopAndMore: '(min-width: 1600px)',
  },
  utils: {
    fbasisSize: (size: string | number) => ({
      flexBasis: `${+size * (100 / 12)}%`,
    }),
  },
});
