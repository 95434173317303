import React, { Component } from 'react';
import { Badge } from 'ui/Common';
import { Rating as Bar } from 'ui/Rating';
import styles from './Rating.scss';

type Props = {
  rate?: number;
  isEmpty?: boolean;
};

export class Rating extends Component<Props> {
  render() {
    const { rate, isEmpty } = this.props;

    return (
      <div>
        <div className={styles.row}>
          <div>Кредитный рейтинг</div>
          <Badge color={isEmpty ? '#f2f2fb' : null}>{isEmpty ? '-' : rate}</Badge>
        </div>
        <Bar rate={isEmpty ? 0 : rate} />
      </div>
    );
  }
}
