import Api from './Api';

class PushApi extends Api {
  register(deviceId) {
    return this.post('push.register', {
      deviceId,
    });
  }
}

export default new PushApi();