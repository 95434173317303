export const DZP = 'dzp';
export const KORONA = 'korona';
export const CONTACT = 'contact';

export const list = {
  [DZP]: 'До зарплаты',
  [KORONA]: 'Золотая Корона',
  [CONTACT]: 'CONTACT',
};

export const listTo = {
  [DZP]: 'До зарплаты',
  [KORONA]: 'Золотую Корону',
  [CONTACT]: 'CONTACT',
};
