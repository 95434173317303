import React, { Component } from 'react';
import { observer } from 'mobx-react';

import ClientStore from 'store/Client';
import PaidServicesStore from 'store/PaidServices';
import BrokeragePaidServiceStore from 'store/BrokeragePaidServices';
// import { SubscriptionStore } from 'modules/LoanSubscription';
import Cookie from 'utils/Cookie';
import { Service as MetricService } from 'modules/common/MetricService';
import * as env from 'const/env';

import { Tablet, OnlyMobile, MobileTablet } from 'const/media';
import * as cookies from 'const/cookies';
import LogoIcon from 'ui/svg-icons/Logos/Small';

import Home from 'ui/svg-icons/Header/Home';
import Events from 'ui/svg-icons/Header/Events';
import Promo from 'ui/svg-icons/Header/Promo';
import Complain from 'ui/svg-icons/Header/Complain';
// import All from 'ui/svg-icons/Header/All';

import Profile from 'ui/svg-icons/Header/Profile';
import Rating from 'ui/svg-icons/Header/Rating';
import Bonus from 'ui/svg-icons/Header/Bonus';
import Card from 'ui/svg-icons/Header/Card';
import Exit from 'ui/svg-icons/Header/Exit';

import Logout from 'ui/svg-icons/Logout';
import Menu from 'ui/svg-icons/Menu';
import Cross from 'ui/svg-icons/Cross';

import Star from 'ui/svg-icons/Star';

import * as urls from 'const/urls';
import * as media from 'const/media';
import withErrorBoundary from 'hocs/withErrorBoundary';
import { CreditCardList } from 'modules/common/CreditCardList';
import RouteManager from 'routes/manager';
import {
  Section,
  Row,
  Logo,
  Nav,
  Block,
  Link,
  LinkOut,
  LinkInner,
  MenuBtn,
  NavBtn,
  Badge,
  Trigger,
  Inner,
} from './styled';
import StarAnimation from '../../store/StarAnimation';

type State = {
  menuOpen: boolean;
  profileMenuOpen: boolean;
  cardListOpen: boolean;
};

@withErrorBoundary
@observer
export class Header extends Component<{}, State> {
  state = {
    menuOpen: false,
    profileMenuOpen: false,
    cardListOpen: false,
  };

  /* eslint-disable react/sort-comp */
  private clientStore = ClientStore;

  private paidServicesStore = PaidServicesStore;

  private brokeragePaidServiceStore = BrokeragePaidServiceStore;

  // private subscriptionStore = SubscriptionStore.getInstance();

  componentDidMount() {
    if (!this.paidServicesStore.isDataLoaded) {
      this.paidServicesStore.fetch();
    }

    if (!this.brokeragePaidServiceStore.isDataLoaded) {
      this.brokeragePaidServiceStore.fetch();
    }
  }

  onBtnMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    const width = window.innerWidth;

    if (width < media.LAPTOP_BREAKPOINT) {
      this.setState((prevState) => ({
        menuOpen: !prevState.menuOpen,
        profileMenuOpen: false,
        cardListOpen: false,
      }));
    }
  };

  onBtnNav = (event: React.MouseEvent) => {
    event.stopPropagation();
    const width = window.innerWidth;

    if (width < media.LAPTOP_BREAKPOINT) {
      this.setState((prevState) => ({
        profileMenuOpen: !prevState.profileMenuOpen,
        menuOpen: false,
        cardListOpen: false,
      }));
    }
  };

  hideMenuHandler = () => {
    this.setState({
      profileMenuOpen: false,
      menuOpen: false,
      cardListOpen: false,
    });
  };

  onCardTrigger = (event: React.MouseEvent) => {
    event.stopPropagation();

    const width = window.innerWidth;

    if (width < media.LAPTOP_BREAKPOINT) {
      this.setState((prevState) => ({
        cardListOpen: !prevState.cardListOpen,
      }));
    }
  };

  logout = async () => {
    try {
      await MetricService.onSessionEnded('logout');
    } finally {
      this.clientStore.deleteAuthCredentials();
      RouteManager.goToLogin();
      Cookie.delete(cookies.APPLICATION_FIRST_NAME);
      Cookie.delete(cookies.APPLICATION_LAST_NAME);
      Cookie.delete(cookies.APPLICATION_PATRONYMIC);
      Cookie.delete(cookies.OUTER_LOAN);
      Cookie.delete(cookies.WP_USER_LABEL);
    }
  };

  getServiceUrl = () => {
    return urls.ADDITIONAL_SERVICES;
  };

  /**
   * Нужна для срабатывания отправки метрики в функции
   * `createStyledBaseConditional`.
   */
  handleMsVoidClick = () => {};

  handleClick = (title: string) => {
    MetricService.onButtonPressed(title);
  };

  render() {
    const { menuOpen, profileMenuOpen, cardListOpen } = this.state;

    const { rateIsHidden } = this.clientStore;
    const creditRating = this.clientStore.getValue('creditRating').get();
    const bonuses = this.clientStore.getValue('bonuses').get();
    // const { hasAvailableSubscriptions, hasActiveSubscription } = this.subscriptionStore;
    // const {
    //   hasAvailableServicesForCurrentLoan,
    //   amountOfAvailableServices: amountOfPaidServices,
    // } = this.paidServicesStore;
    // const additionalServiceIsAvailable =
    //   hasAvailableServicesForCurrentLoan || hasAvailableSubscriptions || hasActiveSubscription;
    // const amountOfAvailableServices = amountOfPaidServices + Number(hasAvailableSubscriptions);
    const { headerOverElements } = StarAnimation;

    return (
      <Section style={headerOverElements ? { position: 'fixed', top: '0', width: '100%' } : {}}>
        <Row>
          <Logo href={`//${env.PUBLIC_DOMAIN}`} data-qa="header-logo-link">
            <LogoIcon />
          </Logo>
          <Nav msDisabled mobileOpen={menuOpen} onClick={this.hideMenuHandler}>
            <Block>
              <Link to={urls.DASHBOARD} data-qa="header-dashboard-link">
                <LinkInner onClick={this.handleMsVoidClick}>
                  <Home />
                  Главная
                </LinkInner>
              </Link>
            </Block>
            <Block>
              <Link to={urls.EVENTS} data-qa="header-events-link">
                <LinkInner onClick={this.handleMsVoidClick}>
                  <Events />
                  События
                </LinkInner>
              </Link>
            </Block>
            <Block>
              <Link to={urls.PROMOTIONS} data-qa="header-promotions-link">
                <LinkInner onClick={this.handleMsVoidClick}>
                  <Promo />
                  Акции
                </LinkInner>
              </Link>
            </Block>
            {/*<Block>*/}
            {/*  <Link to={urls.OFFICES} data-qa="header-offices-link">*/}
            {/*    <LinkInner>*/}
            {/*      <Offices />*/}
            {/*      Отделения*/}
            {/*    </LinkInner>*/}
            {/*  </Link>*/}
            {/*</Block>*/}
            <Block>
              <LinkOut href={urls.DZP_COMPLAIN} data-qa="header-complain-link">
                <LinkInner onClick={() => this.handleClick('Пожаловаться')}>
                  <Complain />
                  Пожаловаться
                </LinkInner>
              </LinkOut>
            </Block>
            {/* {additionalServiceIsAvailable && (
              <Block>
                <Link to={this.getServiceUrl()} data-qa="header-offices-link">
                  <LinkInner onClick={this.handleMsVoidClick}>
                    <All />
                    Услуги
                    {amountOfAvailableServices > 0 && (
                      <AmountOfAdditionalServices>
                        {amountOfAvailableServices}
                      </AmountOfAdditionalServices>
                    )}
                  </LinkInner>
                </Link>
              </Block>
            )} */}
          </Nav>

          <MobileTablet>
            <MenuBtn
              msTitle="Кнопка мобильного меню"
              onClick={this.onBtnMenu}
              data-qa="header-menu-button"
            >
              {menuOpen ? (
                <Cross width="14" />
              ) : (
                <>
                  <Menu width="18" />
                  {/* {additionalServiceIsAvailable && amountOfAvailableServices > 0 && (
                    <AmountOfAdditionalServices inToggle>
                      {amountOfAvailableServices}
                    </AmountOfAdditionalServices>
                  )} */}
                </>
              )}
            </MenuBtn>
          </MobileTablet>

          <Tablet>
            <NavBtn msTitle="Выйти" onClick={this.logout} data-qa="header-logout-button">
              <Logout height="18" /> <span>Выйти</span>
            </NavBtn>
          </Tablet>

          <OnlyMobile>
            <NavBtn
              msTitle="Кнопка профиля"
              onClick={this.onBtnNav}
              data-qa="header-navigation-button"
            >
              <Profile id="elementProfile" width="24" height="24" />
            </NavBtn>

            <Nav mobileOpen={profileMenuOpen} onClick={this.hideMenuHandler} msDisabled>
              <Block>
                <Link to={urls.PROFILE} data-qa="header-profile-link">
                  <LinkInner msTitle="Профиль" onClick={this.handleMsVoidClick}>
                    <Profile />
                    Профиль
                  </LinkInner>
                </Link>
              </Block>
              <Block>
                <Link to={urls.CREDIT_RATING} data-qa="header-creditRating-link">
                  <LinkInner msTitle="Кредитный рейтинг" onClick={this.handleMsVoidClick}>
                    <Rating />
                    Кредитный рейтинг
                    <Badge color={rateIsHidden || creditRating === 0 ? '#f2f2fb' : null}>
                      {rateIsHidden ? '-' : creditRating}
                    </Badge>
                  </LinkInner>
                </Link>
              </Block>
              <Block>
                <Link to={urls.BONUS_PROGRAM} data-qa="header-bonusProgram-link">
                  <LinkInner onClick={this.handleMsVoidClick}>
                    <Bonus />
                    Бонусная программа
                    <Badge>
                      <Star width="12" />
                      {bonuses}
                    </Badge>
                  </LinkInner>
                </Link>
              </Block>
              <Block>
                <Trigger opened={cardListOpen}>
                  <LinkInner
                    onClick={this.onCardTrigger}
                    arrowOpened={cardListOpen}
                    arrow
                    data-qa="header-cardTrigger-button"
                  >
                    <Card />
                    Ваши карты
                  </LinkInner>
                  <Inner>
                    <CreditCardList small />
                  </Inner>
                </Trigger>
              </Block>
              <Block>
                <LinkInner onClick={this.logout} data-qa="header-logout-button">
                  <Exit />
                  Выйти
                </LinkInner>
              </Block>
            </Nav>
          </OnlyMobile>
        </Row>
      </Section>
    );
  }
}
