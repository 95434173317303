import React, { ComponentPropsWithoutRef } from 'react';
import { Avatar } from 'ui/Avatar';
import Verified from 'ui/svg-icons/Verified';
import Person from 'ui/svg-icons/Person';
import { Code } from '../styled';
import { Wrap, Info, Top, Name } from './styled';

type Props = ComponentPropsWithoutRef<typeof Wrap> & {
  img: string;
  badge: number;
  name: string;
  verified?: boolean;
  clientId: string;
  opened?: boolean;
};

export const UserTablet: React.FC<Props> = ({
  img,
  opened,
  name,
  verified,
  clientId,
  ...props
}) => {
  return (
    <Wrap closed={!opened} tall={!!clientId} {...props}>
      <Top verified={verified}>
        <Avatar img={img} />
        <Verified width="12" />
      </Top>
      <Info closed={!opened}>
        <Name closed={!opened}>{name}</Name>
        {clientId && (
          <Code>
            <Person width="14" />
            Код клиента <span>{clientId}</span>
          </Code>
        )}
      </Info>
    </Wrap>
  );
};
