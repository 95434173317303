import { CustomError } from 'utils/errors';

type FieldError = {
  invalidValue: string;
  message: string;
  constraintId: string;
};

type NestedFieldError = {
  [key: string]: FieldError | NestedFieldError;
};

export type InvalidParameters = {
  [key: string]: FieldError | NestedFieldError;
};

export class BackendError extends CustomError {
  public code: number;

  public data?;

  constructor(message: string, code: number, data) {
    super(`${code} : ${message}`);
    this.code = code;
    this.data = data;
  }
}
