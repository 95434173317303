import Api from 'services/Api';

type Request = {
  photo: string; // --- base64
};

type Response = true;

export function updatePhoto(data: Request): Promise<Response> {
  return Api.getInstance().post('client.updatePhoto', data);
}
