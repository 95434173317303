import { loadScript } from 'utils/loadScript';
import { GTMNotInitializedError } from '../errors';

declare global {
  interface Window {
    gtag?;
  }
}

/**
 * Сервис для работы с API Google Tag Manager.
 * @see https://developers.google.com/tag-manager/quickstart
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs
 */
export class Service {
  /**
   * Коллекция событий GTM.
   */
  private static events: any[];

  /**
   * Инициализирует глобальный объект событий метрики.
   */
  private static initializeEvents() {
    if (typeof window === 'undefined') {
      this.events = [];
      return;
    }

    window.dataLayer = window.dataLayer || [];
    this.events = window.dataLayer;
  }

  /**
   * Инициализирует скрипт Google Tag Manager.
   * @param id GA_MEASUREMENT_ID, указанный в документации.
   * @see https://developers.google.com/analytics/devguides/collection/gtagjs
   */
  private static initializeGtagScript(id: string) {
    loadScript(`https://www.googletagmanager.com/gtag/js?id=${id}`);
  }

  /**
   * Инициализирует скрипт gtm.js, используемый для сбора метрик.
   * @param id Идентификатор GTM-XXXX из документации.
   * @see https://developers.google.com/tag-manager/quickstart
   */
  private static initializeGtmScript(id: string) {
    if (typeof window === 'undefined') {
      return;
    }

    loadScript(`https://www.googletagmanager.com/gtm.js?id=${id}`);

    const iframe = window.document.createElement('IFRAME');
    iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${id}`);
    iframe.setAttribute('height', '0');
    iframe.setAttribute('width', '0');
    iframe.setAttribute('style', 'display:none;visibility:hidden');

    const noscript = window.document.createElement('NOSCRIPT');
    noscript.appendChild(iframe);

    window.document.body.appendChild(noscript);
  }

  /**
   * Инициализирует сервис.
   * @param gtmId Идентификатор GTM-XXXX из документации.
   * @param gtagId Идентификатор GA_MEASUREMENT_ID из документации.
   * @see https://developers.google.com/tag-manager/quickstart
   * @see https://developers.google.com/analytics/devguides/collection/gtagjs#install_the_global_site_tag
   */
  public static initialize(gtmId?: string, gtagId?: string) {
    if (this.events != null) {
      return;
    }

    this.initializeEvents();

    const now = new Date();

    if (gtmId) {
      this.initializeGtmScript(gtmId);

      const isInited = this.events.some((item) => item.event === 'gtm.js');

      if (!isInited) {
        this.push({
          'gtm.start': now.getTime(),
          event: 'gtm.js',
        });
      }
    }

    if (gtagId) {
      this.initializeGtagScript(gtagId);

      const isInited = this.events.some((item) => item[0] === 'js');

      if (!isInited) {
        this.push('js', now);
        this.push('config', gtagId);
      }
    }
  }

  /**
   * Логгирует сущность, отправленную в метрику.
   * @param value Значение.
   */
  private static log(value: any) {
    if (process.env.NODE_ENV === 'production') {
      return;
    }

    // eslint-disable-next-line no-console
    console.debug('google_event', value);
  }

  /**
   * Добавляет событие в GTag.
   * @param event Событие.
   * @param args Параметры события.
   */
  private static pushGtag(event: string, ...args: any[]) {
    const parameters = [event, ...args];

    const isDefined = typeof window !== 'undefined' && window.gtag != null;

    if (isDefined) {
      window.gtag(...parameters);
    } else {
      this.events.push(...parameters);
    }

    this.log(parameters);
  }

  /**
   * Добавляет событие в Google Tag Manager.
   * @param event Событие.
   */
  private static pushGtm(event: Record<string, any>) {
    this.events.push(event);
    this.log(event);
  }

  /**
   * Записывает событие в метрику.
   * @param event Объект события или его название.
   * @param args Параметры события (указываются, если событие передано как
   * список параметров, а не объект).
   */
  public static push(event: string | Record<string, any>, ...args: any[]) {
    if (this.events == null) {
      throw new GTMNotInitializedError();
    }

    if (typeof event === 'string') {
      this.pushGtag(event, ...args);
    } else {
      this.pushGtm(event);
    }
  }
}
