import Model from './Model';
import { SERVICE_STATUS, SERVICE_TYPE } from 'const/paidService';

export default class PaidServiceModel extends Model<PaidServiceModel> {

  // credit, sms ...
  public type: SERVICE_TYPE = 0;

  // >>> const/paidService, not available, available, purchased ...
  public status: SERVICE_STATUS = 0;

  // for multi-purchasable services
  public purchased = false;

  // public name of service
  public label = '';

  // related service ids
  public includes: Array<SERVICE_TYPE> = [];

  // user have to select at least one of required services
  public required = false;

  public documentUrl = '';

  public price = 0;
  public oldPrice = 0;

  public constructor(data?: Partial<PaidServiceModel>) {
    super();
    this.init(data);
  }
}
