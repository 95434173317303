import Model from '../Model';

// eslint-disable-next-line import/no-default-export
export default class Step2Model extends Model<Step2Model> {
  public addressRegistrationDadata = {};

  public addressRegistration = {
    locality: '',
    street: '',
    house: '',
    flat: '',
  };

  public maritalStatus = -1;

  public familyCount = -1;

  public contactPersons = [];

  public familyMembers = [];

  public socials = [];

  /**
   * Fields from step 3
   */
  public noWork = false;

  public withParents = false;

  public employer = '';

  public employerPhone = '';

  public position = '';

  public childrenCount = -1;

  public experience = -1;

  public salaryOfficial = -1;

  public salaryDate = -1;

  public constructor(data?: Partial<Step2Model>) {
    super();
    this.init(data);
  }
}
