import React from 'react';

const Menu = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16" {...props}>
      <path d="M1.25 0h17.5a1.25 1.25 0 1 1 0 2.5H1.25a1.25 1.25 0 1 1 0-2.5zm0 6.8h17.5a1.25 1.25 0 1 1 0 2.5H1.25a1.25 1.25 0 1 1 0-2.5zm0 6.7h17.5a1.25 1.25 0 1 1 0 2.5H1.25a1.25 1.25 0 1 1 0-2.5z" />
    </svg>
  );
};

export default Menu;
