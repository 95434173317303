import { MetricService } from 'services/MetricService';

import ClientStore from './Client';
import { toCamelCase } from '../utils/Helpers';

/**
 * Хранилище состояния событий метрики.
 */
export class MetricStore {
  /**
   * Сохраненное значение свойства instance.
   */
  private static instanceValue: MetricStore;

  /**
   * Экземпляр синглтона.
   */
  public static get instance() {
    if (this.instanceValue == null) {
      this.instanceValue = new this();
    }

    return this.instanceValue;
  }

  /**
   * Возвращает текущий адрес страницы.
   */
  private get location() {
    return typeof window === 'undefined' ? undefined : window.location.pathname;
  }

  /**
   * Отправляет событие метрики.
   * @param target Объект, с которым произошло событие (страница,кнопка, текстовое поле).
   * @param event Тип события.
   * @param location Адрес страницы, на которой произошло событие. По умолчанию
   * текущая страница.
   */
  public send(target: string, event: string, location: string = this.location) {
    return MetricService.send(ClientStore.clientType, location, target, event);
  }

  /**
   * Отправляет событие метрики с произвольным именем. Событие предваряется
   * префиксом "first_", "repest_" или "guest_" в зависимости от типа клиента.
   * @param event Наименование события.
   * @param useLocation Добавлять location в конечный event.
   */
  public dispatch(event: string, useLocation = false) {
    const user = ClientStore.clientType || 'guest';
    const path = toCamelCase(this.location);

    const dispatchEvent = useLocation ? `${path}_${event}` : event;

    return MetricService.dispatch(user, dispatchEvent);
  }
}
