import { MetricStore } from './MetricStore';

/**
 * Предназначено для отправки событий метрики, связанного с процессом получения
 * займа.
 */
export class TakeLoanMetricStore {
  /**
   * Название события, которое происходит, когда для пользователя создаётся
   * заявка на займ.
   */
  private static CREATED = 'applicationCreated';

  /**
   * Название события, которое происходит, когда пользователь меняет
   * условия займа при заполнении заявки.
   */
  private static CHANGED = 'applicationChanged';

  /**
   * Название события, которое происходит, когда пользователь отправляет
   * первый шаг заявки.
   */
  private static FIRST_STEP_SUBMITTED = 'firstStepSubmitted';

  /**
   * Название события, которое происходит, когда пользователь отправляет
   * второй шаг заявки.
   */
  private static SECOND_STEP_SUBMITTED = 'secondStepSubmitted';

  /**
   * Название события, которое происходит, когда пользователь отправляет
   * третий шаг заявки.
   */
  private static THIRD_STEP_SUBMITTED = 'takeLoanStep3_submitButton_click';

  /**
   * Название события, которое происходит, когда пользователь нажимает на кнопку продолжить
   * на третьем шаге заявки.
   */
  private static THIRD_STEP_CONTINUE_CLICKED = 'takeLoanStep3_continueButton_click';

  /**
   * Название события, которое происходит, когда пользователь подписывает заявку
   * на займ.
   */
  private static SIGNED = 'applicationSigned';

  /**
   * Название события, которое происходит, когда пользователю отказывают в
   * займе.
   */
  private static REJECTED = 'applicationRejected';

  /**
   * Название события, которое происходит, когда пользователю одобряют займ.
   */
  private static APPROVED = 'applicationApproved';

  /**
   * Сохраненное значение свойства instance.
   */
  private static instanceValue: TakeLoanMetricStore;

  /**
   * Название события, которое происходит, когда пользователь работает с полем Имя:
   */
  private static FIRSTNAME_FILLED = 'applicationFirstNameFilled';

  /**
   * Название события, которое происходит, когда пользователь, когда пользователь работает с полем Фамилия:
   */
  private static LASTNAME_FILLED = 'applicationLastNameFilled ';

  /**
   * Название события, которое происходит, когда пользователь, когда пользователь работает с полем Отчество:
   */
  private static PATRONYMIC_FILLED = 'applicationPatronymicFilled';

  /**
   * Название события, которое происходит, когда пользователь, когда пользователь работает с полем Дата рождения:
   */
  private static DATE_OF_BIRTH_FILLED = 'applicationDateOfBirthFilled';

  /**
   * Название события, которое происходит, когда пользователь, когда пользователь работает с полем Эл. почта:
   */
  private static EMAIL_FILLED = 'applicationEmailFilled';

  /**
   * Название события, которое происходит, когда пользователь работает с полем Серия и номер:
   */
  private static PASSPORT_FILLED = 'applicationPassportFilled';

  /**
   * Название события, которое происходит, когда пользователь работает с полем Код подразделения:
   */
  private static SUB_CODE_FILLED = 'applicationSubCodeFilled';

  /**
   * Название события, которое происходит, когда пользователь работает с полем Дата выдачи:
   */
  private static ISSUE_FILLED = 'applicationIssueDateFilled';

  /**
   * Название события, которое происходит, когда пользователь работает с полем Место рождения:
   */
  private static PLACE_OF_BIRTH_FILLED = 'applicationPlaceOfBirthFilled';

  /**
   * Название события, которое происходит, когда пользователь работает с полем Кем выдан:
   */
  private static AUTHORITY_FILLED = 'applicationAuthorityFilled';

  /**
   * Название события, которое происходит, когда пользователь работает с полем Промокод:
   */
  private static PROMO_CODE_FILLED = 'applicationPromoCodeFilled';

  private static EMPLOYER_FILLED = 'applicationEmployerFilled';

  private static EMPLOYER_PHONE_FILLED = 'applicationEmployerPhoneFilled';

  private static POSITION_FILLED = 'applicationPositionFilled';

  private static EXPERIENCE_FILLED = 'applicationExperienceFilled';

  private static SALARY_OFFICIAL_FILLED = 'applicationSalaryOfficialFilled';

  private static SALARY_DATE_FILLED = 'applicationSalaryDateFilled';

  private static LOCALITY_FILLED = 'applicationLocalityFilled';

  private static STREET_FILLED = 'applicationStreetFilled';

  private static HOUSE_FILLED = 'applicationHouseFilled';

  private static FLAT_FILLED = 'applicationFlatFilled';

  private static MARTIAL_STATUS_FILLED = 'applicationMaritalStatusFilled';

  private static FAMILY_COUNT_FILLED = 'applicationFamilyCountFilled';

  private static CREDIT_LOAD_FILLED = 'applicationCreditObligationsFilled';

  private static CHAT_TEXT_BUTTON_CLICKED = 'chat_textbutton_clicked';

  /**
   * Экземпляр синглтона.
   */
  public static get instance() {
    if (this.instanceValue == null) {
      this.instanceValue = new this();
    }

    return this.instanceValue;
  }

  /**
   * Генерирует событие метрики, сигнализирующее о том, что пользователь
   * выбрал параметры на калькуляторе, нажал "Получить деньги", после чего
   * на бэк успешно ушел запрос на создание заявки на займ.
   */
  public dispatchCreated() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.CREATED);
  }

  /**
   * Генерирует событие метрики, сигнализирующее о том, что пользователь
   * выбрал параметры на калькуляторе, нажал "Получить деньги", после чего
   * на бэк успешно ушел запрос на обновление заявки на займ.
   */
  public dispatchChanged() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.CHANGED);
  }

  /**
   * Генерирует событие метрики, сигнализирующее о том, что пользователь
   * верно заполнил и отправил на сервер первый шаг заявки.
   */
  public dispatchFirstStepSubmitted() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.FIRST_STEP_SUBMITTED);
  }

  /**
   * Генерирует событие метрики, сигнализирующее о том, что пользователь
   * верно заполнил и отправил на сервер второй шаг заявки.
   */
  public dispatchSecondStepSubmitted() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.SECOND_STEP_SUBMITTED);
  }

  /**
   * Генерирует событие метрики, которое сингализирует о том, что пользователь
   * верно заполнил и отправил на сервер третий шаг заявки.
   */
  public dispatchThirdStepSubmitted() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.THIRD_STEP_SUBMITTED);
  }

  /**
   * Генерирует событие метрики, которое сингализирует о том, что пользователь
   * нажал на кнопку продолжить в 3ем шаге тестовой анкеты
   */
  public dispatchThirdStepContinueClick() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.THIRD_STEP_CONTINUE_CLICKED);
  }

  /**
   * Генерирует событие метрики, которое сигнализирует о том, что клиент
   * успешно подписал заявку на займ.
   */
  public dispatchSigned() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.SIGNED);
    localStorage.setItem('metricApplicationSigned', '1');
  }

  /**
   * Генерирует событие метрики, которое сигнализирует о том, что клиенту
   * отказали в займе.
   */
  public dispatchRejected() {
    const isDispatch = Boolean(localStorage.getItem('metricApplicationSigned'));

    if (!isDispatch) {
      return;
    }

    localStorage.removeItem('metricApplicationSigned');
    MetricStore.instance.dispatch(TakeLoanMetricStore.REJECTED);
  }

  /**
   * Генерирует событие метрики, сигнализирующее о том, что пользователь
   * 1) Убирал фокус с поля;
   * 2) оно не пустое;
   * 3) оно прошло валидацию.
   */
  public dispatchFirstNameFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.FIRSTNAME_FILLED);
  }

  /**
   * Генерирует событие метрики, сигнализирующее о том, что пользователь
   * 1) Убирал фокус с поля;
   * 2) оно не пустое;
   * 3) оно прошло валидацию.
   */
  public dispatchLastNameFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.LASTNAME_FILLED);
  }

  /**
   * Генерирует событие метрики, сигнализирующее о том, что пользователь
   * 1) Убирал фокус с поля;
   * 2) оно не пустое;
   * 3) оно прошло валидацию.
   */
  public dispatchPatronymicFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.PATRONYMIC_FILLED);
  }

  /**
   * Генерирует событие метрики, сигнализирующее о том, что пользователь
   * 1) Убирал фокус с поля;
   * 2) оно не пустое;
   * 3) оно прошло валидацию.
   */
  public dispatchDateOfBirthFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.DATE_OF_BIRTH_FILLED);
  }

  /**
   * Генерирует событие метрики, сигнализирующее о том, что пользователь
   * 1) Убирал фокус с поля;
   * 2) оно не пустое;
   * 3) оно прошло валидацию.
   */
  public dispatchEmailFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.EMAIL_FILLED);
  }

  /**
   * Генерирует событие метрики, сигнализирующее о том, что пользователь
   * 1) Убирал фокус с поля;
   * 2) оно не пустое;
   * 3) оно прошло валидацию.
   */
  public dispatchPassportFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.PASSPORT_FILLED);
  }

  /**
   * Генерирует событие метрики, сигнализирующее о том, что пользователь
   * 1) Убирал фокус с поля;
   * 2) оно не пустое;
   * 3) оно прошло валидацию.
   */
  public dispatchSubCodeFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.SUB_CODE_FILLED);
  }

  /**
   * Генерирует событие метрики, сигнализирующее о том, что пользователь
   * 1) Убирал фокус с поля;
   * 2) оно не пустое;
   * 3) оно прошло валидацию.
   */
  public dispatchIssueDateFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.ISSUE_FILLED);
  }

  /**
   * Генерирует событие метрики, сигнализирующее о том, что пользователь
   * 1) Убирал фокус с поля;
   * 2) оно не пустое;
   * 3) оно прошло валидацию.
   */
  public dispatchPlaceOfBirthFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.PLACE_OF_BIRTH_FILLED);
  }

  /**
   * Генерирует событие метрики, сигнализирующее о том, что пользователь
   * 1) Убирал фокус с поля;
   * 2) оно не пустое;
   * 3) оно прошло валидацию.
   */
  public dispatchAuthorityFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.AUTHORITY_FILLED);
  }

  /**
   * Генерирует событие метрики, сигнализирующее о том, что пользователь
   * 1) Убирал фокус с поля;
   * 2) оно не пустое;
   * 3) оно прошло валидацию.
   */
  public dispatchPromoCodeFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.PROMO_CODE_FILLED);
  }

  public dispatchCreditLoadFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.CREDIT_LOAD_FILLED);
  }

  public dispatchEmployerFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.EMPLOYER_FILLED);
  }

  public dispatchEmployerPhoneFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.EMPLOYER_PHONE_FILLED);
  }

  public dispatchPositionFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.POSITION_FILLED);
  }

  public dispatchExperienceFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.EXPERIENCE_FILLED);
  }

  public dispatchSalaryOfficialFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.SALARY_OFFICIAL_FILLED);
  }

  public dispatchSalaryDateFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.SALARY_DATE_FILLED);
  }

  public dispatchLocalityFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.LOCALITY_FILLED);
  }

  public dispatchStreetFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.STREET_FILLED);
  }

  public dispatchHouseFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.HOUSE_FILLED);
  }

  public dispatchFlatFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.FLAT_FILLED);
  }

  public dispatchMaritalStatusFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.MARTIAL_STATUS_FILLED);
  }

  public dispatchFamilyCountFilled() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.FAMILY_COUNT_FILLED);
  }

  /**
   * Генерирует событие метрики, которое сигнализирует о том, что клиенту
   * одобрили займ.
   */
  public dispatchApproved() {
    const isDispatch = Boolean(localStorage.getItem('metricApplicationSigned'));

    if (!isDispatch) {
      return;
    }

    localStorage.removeItem('metricApplicationSigned');
    MetricStore.instance.dispatch(TakeLoanMetricStore.APPROVED);
  }

  /**
   * Генерирует событие метрики, которое сигнализирует о том, что юзер
   * нажал на "чат" в поп-апе ошибки корректности паспортных данных
   */
  public dispatchChatTextClicked() {
    MetricStore.instance.dispatch(TakeLoanMetricStore.CHAT_TEXT_BUTTON_CLICKED);
  }
}
