import Api from 'services/Api';

type CreditCard = {
  id: number;
  number: string;
  expirationYear;
  expirationMonth;
  operationType: number;
  nameEng: string;
  nameRus: string;
  url: string;
  backgroundColorFirst: string;
  backgroundColorSecond: string;
  backgroundLightness: string;
  logoStyle: string;
  textColor: string;
  logoPath: string;
  payoutBlockedBy: string;
};

type Response = Array<CreditCard>;

export function getCreditCards(): Promise<Response> {
  return Api.getInstance().post('client.getCreditCards', { source: 'ver3' });
}
