import React from 'react';
import Camera from 'ui/svg-icons/Camera';
import { Img, Wrap } from './styled';

type AvatarProps = {
  large?: boolean;
  img?: string;
};

export const Avatar = ({ large, img }: AvatarProps) => (
  <Wrap large={large}>
    {img ? <Img css={{ background: `url(${img}) no-repeat center / cover` }} /> : <Camera />}
  </Wrap>
);
