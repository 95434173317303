import React, { Component } from 'react';
import { observer } from 'mobx-react';

import CurrentLoanStore from 'store/CurrentLoan';
import LoanApplicationStore from 'store/LoanApplication';
import CombinedCalculatorStore from 'store/CombinedCalculator';
import Helpers from 'utils/Helpers';
import LoanIcons, { loanTitle } from 'utils/LoanIcons';

import { LOAN_STATUS } from 'const/loan';
import { getStatusTitle } from './helpers';
import { LoanInfo as LoanInfoComponent } from '../../components/LoanInfo';
import { Title } from '../../styled';

type Props = {
  hideStatusTitle?: boolean;
  small?: boolean;
  onTakeLoan?: boolean;
  isChangingLoanConditions?: boolean; // todo check if necessary
};

@observer
export class LoanInfo extends Component<Props> {
  private currentLoanStore = CurrentLoanStore;

  private loanApplicationStore = LoanApplicationStore;

  private combinedCalculatorStore = CombinedCalculatorStore;

  render() {
    const { small, hideStatusTitle, onTakeLoan } = this.props;
    const { currentLoanStore, loanApplicationStore, combinedCalculatorStore } = this;

    if (!currentLoanStore.isDataLoaded) {
      // Загрузочка
      if (!onTakeLoan) {
        return null;
      }
    }

    const loanStatus = currentLoanStore.getValue('status').get();
    const loanAmount = currentLoanStore.getValue('amount').get();
    const statusTitle = getStatusTitle(loanStatus);

    if (loanStatus === LOAN_STATUS.REJECTED) {
      return null;
    }

    let loanTariff = currentLoanStore.getValue('loanType').get();
    let dueToDate = currentLoanStore.getValue('dueToDate').get();

    let name = currentLoanStore.getValue('name').get();

    let amountText = Helpers.asMoney(loanAmount);

    if (loanStatus === LOAN_STATUS.NO_LOAN) {
      name = loanApplicationStore.getSelectedLoanValue('name').get();
      loanTariff = loanApplicationStore.getSelectedLoanValue('tariffId').get();

      const selectedAmount = loanApplicationStore.getSelectedLoanValue('amount').get();
      const selectedPeriod = loanApplicationStore.getSelectedLoanValue('period').get();

      dueToDate = loanApplicationStore.getSelectedLoanValue('dueToDate').get();

      if (!selectedAmount || !selectedPeriod) {
        if (!onTakeLoan) {
          return null;
        }
      }

      amountText = Helpers.asMoney(selectedAmount);
    }

    if (onTakeLoan) {
      const savedLoanValues = combinedCalculatorStore.getSavedValues();

      const { amount, period, days, tariffId } = savedLoanValues;

      name = loanTitle(tariffId);
      loanTariff = tariffId;

      amountText = Helpers.asMoney(amount);
      dueToDate = Helpers.getLoanEndDate({
        days: days === period ? days : 0,
        months: days !== period ? period : 0,
      }) as any;
    }

    return (
      <>
        {!hideStatusTitle && <Title>{statusTitle}</Title>}
        <LoanInfoComponent
          icon={LoanIcons(loanTariff)}
          title={name}
          text={amountText}
          date={Helpers.asDate(dueToDate)}
          small={small}
        />
      </>
    );
  }
}
