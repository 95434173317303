import React, { Component } from 'react';
import { LoanIcon } from 'ui/Common';
import { Row, Wrap, Title, Text } from './styled';

type Props = {
  icon: React.ReactNode;
  title: React.ReactNode;
  text: React.ReactNode;
  date?: string;
  small?: boolean;
};

export class LoanInfo extends Component<Props> {
  render() {
    const { icon, title, text, small } = this.props;

    return (
      <Row column={small}>
        {small ? (
          <>
            <Title small data-qa="loanInfo-type-title">
              {' '}
              {text}{' '}
            </Title>
            <Text small data-qa="loanInfo-type-text">
              {' '}
              {title}{' '}
            </Text>
          </>
        ) : (
          <>
            <LoanIcon>{icon}</LoanIcon>
            <Wrap>
              <Title data-qa="loanInfo-type-title"> {title} </Title>
              <Text data-qa="loanInfo-type-text"> {text} </Text>
            </Wrap>
          </>
        )}
      </Row>
    );
  }
}
