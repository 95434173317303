export const PHOTO_CROP_WIDTH = 480;
export const PHOTO_CROP_HEIGHT = 480;
export const PHOTO_QUALITY = 0.9;
export const MAX_SIZE_MB = 7;
export const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1000;
export const MAX_SIZE_APP_RECALCULATION_FILE_MB = 10;

export const PHOTO_ALLOWED_MIME_TYPES = ['image/jpeg', 'image/jpg'];
export const PHOTO_ALLOWED_EXTENSIONS = ['jpeg', 'jpg'];
export const PDF_MIME_TYPE = 'application/pdf';

// type for UploadImage component
export const UPLOAD_IMAGE_TYPE_DEFAULT = 0; // show preview image and webcam separately
export const UPLOAD_IMAGE_TYPE_MINI = 1; // webcam not showing in component, preview show in button
