import React from 'react';

const Banknotes = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" {...props}>
      <circle cx="24" cy="24" r="24" fill="#F2F2FB" />
      <path
        fillRule="evenodd"
        fill="#A2A2B2"
        d="M19 27l-4-4v-6h10l4 4v6H19zm2-4v-1h2.5c.8284271 0 1.5-.67157288 1.5-1.5s-.6715729-1.5-1.5-1.5H20v4h-1v1h1v1h1v-1h3v-1h-3zm0-3h2.5c.2761424 0 .5.22385763.5.5 0 .27614237-.2238576.5-.5.5H21v-1zm13 12V19h1v14H17v-1h17zm-2-2V17h1v14H15v-1h17zm-19-1V15h18v14H13z"
      />
    </svg>
  );
};

export default Banknotes;
