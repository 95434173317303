import styled from 'styled-components';
import * as color from 'const/colors';
import * as media from 'const/media';

export const RadioWrapper = styled.div.withConfig({
  displayName: 'RadioWrapper',
})`
  display: inline-block;
`;

export const RadioMark = styled.span.withConfig({ displayName: 'RadioMark' })`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid
    ${props =>
  props.hasError ? color.ERROR : color.BORDER};

  &:after {
    content: '';
    position: absolute;
    opacity: 1;
  }

  &:after {
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
    transition: all 0.25s ease-out;
  }
`;

export const RadioLabel = styled.label.withConfig({
  displayName: 'RadioLabel',
})`
  display: flex;
  align-items: center;
  padding-left: 28px;
  position: relative;
  line-height: 22px;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }


  input:checked ~ ${RadioMark}:after {
    background-color: ${color.TEXT_PRIMARY};
    display: block;
    opacity: 1;
  }

  input:disabled ~ * {
    opacity: 0.4;
  }

  input:disabled ~ ${RadioMark} {
    border-color: ${color.DISABLED} !important;
  }
  
  span {
    font-size: 13px;
    color: ${props => props.hasError ? color.ERROR : '#4f4e67'};
  }
  
  ${media.tabletL`
    span {
      font-size: 14px;
    ]
  `};
`;