import React from 'react';

import MasterCard from 'ui/svg-icons/MasterCardPaymentSystem';
import Mir from 'ui/svg-icons/MirPaymentSystem';
import Visa from 'ui/svg-icons/VisaPaymentSystem';
import Maestro from 'ui/svg-icons/MaestroPaymentSystem';

import { CARD_TYPE } from 'const/creditCard';

type InnerProps = {
  type: CARD_TYPE;
};
type Props = InnerProps & Omit<React.SVGProps<SVGElement>, keyof InnerProps>;

export const PaymentSystem: React.FC<Props> = ({ type, ...other }) => {
  if (!type) {
    return null;
  }

  return (
    <>
      {type === CARD_TYPE.VISA && <Visa {...other} />}
      {type === CARD_TYPE.MASTERCARD && <MasterCard {...other} />}
      {type === CARD_TYPE.MAESTRO && <Maestro {...other} />}
      {type === CARD_TYPE.MIR && <Mir {...other} />}
    </>
  );
};
