import { createStyled, createStyledBaseConditional } from 'utils/createStyled';
import styles from './LabelText.scss';

export const Wrap = createStyled('div', styles.wrap);

export const Label = createStyled('div', styles.label);

export const Value = createStyledBaseConditional('div', styles.value, {
  isGray: styles.isGray,
  lineThrough: styles.lineThrough,
  nowrap: styles.nowrap,
});
