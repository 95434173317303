import Api from '../../Api';

type Response = {
  /* eslint-disable camelcase */
  utm_source?: string;
};

export function utmByLoan(data: { loanId: number }): Promise<Response> {
  return Api.getInstance().post('loan.utmByLoan', data);
}
