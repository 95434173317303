import { Service as Api } from '@frontend/clickhouse-metric';
import jwtDecode from 'jwt-decode';

import { AuthMetricStore } from 'store/AuthMetricStore';
import Cookie from 'utils/Cookie';
import * as cookies from 'const/cookies';
import ClientStore from 'store/Client';

/**
 * Сервис для работы с API Clickhouse.
 */
export class Service {
  /**
   * Экземпляр API ClickHouse.
   */
  private static api: Api = new Api();

  /**
   * Инициализирует сервис.
   * @param url Адрес API.
   * @param id Идентификатор API.
   * @param clientId Идентификатор API.
   */
  public static initialize(url?: string, id?: string, clientId?: string) {
    this.api = new Api({
      url,
      id,
      clientId,
      verbose: process.env.NODE_ENV !== 'production',
    });

    this.api.sendVisit();
  }

  /**
   * Сохранённое значение токена, из которого был получени идентификатор
   * текущего пользователя.
   */
  private static previousToken?: string = undefined;

  /**
   * Предыдущий рассчитаный идентификатор пользователя.
   */
  private static previousUserId?: number = undefined;

  /**
   * Возвращает идентификатор авторизованного пользователя. Если пользователь
   * не авторизован, возвращает `undefined`.
   */
  public static getUserId() {
    const token = Cookie.get(cookies.USER_TOKEN);

    if (!token) {
      return undefined;
    }

    if (this.previousToken === token) {
      return this.previousUserId;
    }

    this.previousUserId = undefined;
    this.previousToken = token;

    try {
      const { userId } = jwtDecode(token) as any;
      this.previousUserId = userId;

      return userId;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * Извлекает из cookie сохранённую UTM-метку и возвращает её значение. Если
   * метки нет, возвращает `undefined`.
   * @param name Название метки.
   */
  private static getUtm(name: string) {
    const utmText = Cookie.get('_dzp_utm');

    if (!utmText) {
      return undefined;
    }

    let value: string;

    try {
      const utm = JSON.parse(utmText);
      value = utm[name];
    } catch (error) {
      return undefined;
    }

    return value;
  }

  /**
   * Записывает событие в метрику.
   * @param event Событие.
   * @param eventValue Дополнительный параметр события.
   */
  public static push(event: string, eventValue?: string) {
    const userId = this.getUserId();
    const operator = Boolean(Cookie.get(cookies.OPERATOR));
    const userType = ClientStore.clientType;

    this.api.send(event, {
      payload: eventValue ? `${eventValue}` : '',
      userId: userId == null ? undefined : String(userId),
      logout: event === AuthMetricStore.LOGOUT,
      utmCampaign: this.getUtm('utm_campaign'),
      utmContent: this.getUtm('utm_content'),
      utmMedium: this.getUtm('utm_medium'),
      utmSource: this.getUtm('utm_source'),
      utmTerm: this.getUtm('utm_term'),
      operator,
      userType,
      prevPage: document.referrer,
      testGroup: {},
      yandexID: Cookie.get(cookies.YANDEX_ID),
    });
  }
}
