import Api from 'services/Api';

type Request = {
  id: string;
};

type Response = Array<{
  amount: number;
  date: string;
  type: number;
}>;

export function getEventHistory(data: Request): Promise<Response> {
  return Api.getInstance().post('client.getEventHistory', data);
}
