import Api from 'services/Api';

interface Request {
  subCode: string;
  issueDate: string;
  issueAuthority: string;
}

type Response = true;

export function updatePassportData(data: Request): Promise<Response> {
  return Api.getInstance().post('client.updatePassportData', data);
}
