import React from 'react';
import { createStyled } from 'utils/createStyled';
import * as colors from 'const/colors';
import * as creditRating from 'const/creditRating';
import styles from './Rating.scss';

const rateColors = [
  colors.RATE_TERRIBLE,
  colors.RATE_BAD,
  colors.PRIMARY,
  colors.RATE_NORMAL,
  colors.RATE_GOOD,
];

export const rateColor = (rating: number) => {
  return rating === 0 ? colors.BORDER : rateColors[creditRating.getIndex(rating)];
};

export const Bar = createStyled('div', styles.bar);

const rateValue = createStyled('div', styles.rateValue);
type RateValueProps = { rate?: number; left?: number } & React.ComponentProps<typeof rateValue>;

export const RateValue: React.FC<RateValueProps> = ({ rate, left, ...other }) => {
  const borderColor = rateColor(rate);
  const style = { left: `${left}%`, borderColor };
  return React.createElement(rateValue, { ...other, style });
};

const rate = createStyled('span', styles.rate);
type RateProps = { color?: string } & React.ComponentProps<typeof rate>;

export const Rate: React.FC<RateProps> = ({ color, ...others }) => {
  const style = color ? { background: color } : undefined;
  return React.createElement(rate, { ...others, style });
};

export const Row = createStyled('div', styles.row);

export const Point = createStyled('div', styles.point);
