import { toCamelCase } from 'utils/Helpers';

import { Service as ClickhouseMetricService } from 'modules/common/ClickhouseMetric';
import { Service as MyTargetMetricService } from 'modules/common/MyTargetMetric';
import { Service as GoogleService } from 'modules/common/GoogleMetric';
import YandexMetricProvider from './marketing/providers/YandexMetricProvider';

/**
 * Содержит методы для работы с событиями метрики.
 */
export class MetricService {
  /**
   * Передает имя метрики в метрик провайдер для отправки.
   * @param user Тип пользователя (first, repeat или guest).
   * @param location Адрес страницы (без доменного имени), на которой произошло событие.
   * @param target Объект, с которым произошло событие.
   * @param event Тип события.
   */
  public static send(user: string, location: string, target: string, event: string) {
    const path = toCamelCase(location);

    this.sendToYandex(user, path, target, event);
    this.sendToGoogle(user, path, target, event);
  }

  /**
   * Отправляет событие в Google Tag Manager.
   * @param user Тип пользователя (first, repeat или guest).
   * @param path Адрес страницы в camelCase.
   * @param subject Объект, с которым произошло событие.
   * @param event Тип события.
   */
  private static sendToGoogle(user: string, path: string, subject: string, event: string) {
    const name = `${subject}_${event}`;

    GoogleService.push('event', name, {
      [`event_category`]: user,
      [`event_label`]: path,
    });
  }

  /**
   * Отправляет событие в Yandex.Метрику.
   * @param user Тип пользователя (first, repeat или guest).
   * @param path Адрес страницы в camelCase.
   * @param subject Объект, с которым произошло событие.
   * @param event Тип события.
   */
  private static sendToYandex(user: string, path: string, subject: string, event: string) {
    const name = [user, path, subject, event].join('_');
    YandexMetricProvider.sendMetric(name);
  }

  /**
   * Отправляет событие с произвольным именем во все провайдеры метрик (Яндекс
   * и Google на данный момент).
   * @param user Тип пользователя (first, repeat или guest).
   * @param event Название события.
   */
  public static dispatch(user: string, event: string) {
    YandexMetricProvider.sendMetric(`${user}_${event}`);
    MyTargetMetricService.push(`${user}_${event}`);
    GoogleService.push('event', event, { [`event_category`]: user });
    ClickhouseMetricService.push(event);
  }
}
