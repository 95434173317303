export enum CARD_TYPE {
  UNKNOWN = 0,
  VISA = 1,
  MASTERCARD = 2,
  MAESTRO = 3,
  MIR = 4,
}

export const BIND_TYPE_PAYOUT = 1;
export const BIND_TYPE_PAYMENT = 2;
export const BIND_TYPE_BOTH = 3;

export const DEFAULT_CARD_COLOR_1 = '#2455BA';
export const DEFAULT_CARD_COLOR_2 = '#2455BA';
export const DZP_KORONA_CARD_COLOR = 'linear-gradient(180deg, #557 0%, #D9D7D7 100%)';
