import Api from 'services/Api';

interface Request {
  type?: number;
  page?: number;
}

type Response = Array<{
  id;
  name: string;
  amount: number;
  dateEnd: string;
  dateOn: string;
  loanTariffId: number;
  loanType;
  status: number;
  type: number;
}>;

export function getEvents(data: Request): Promise<Response> {
  return Api.getInstance().post('client.getEvents', data);
}
