/**
 * Возвращает текст ошибки компонента формы для отправки в метрику.
 */
export function generateFieldErrorMessage({
  label,
  errorText,
}: {
  /**
   * Название поля.
   */
  label: string;

  /**
   * Текст ошибки.
   */
  errorText: string;
}): string {
  return `"${label}" - "${errorText}"`;
}
