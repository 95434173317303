import { FormOptions } from 'mobx-react-form';

export const mobxOptionsValidateAfterSubmit: Partial<FormOptions> = {
  validateOnChange: false,
  validateOnBlur: false,
  validateOnChangeAfterInitialBlur: false,
  validateOnChangeAfterSubmit: true,
  showErrorsOnSubmit: true,
  showErrorsOnChange: true,
};
