import Model from './Model';
import { SERVICE_STATUS } from 'const/paidService';

export default class BrokerageServiceModel extends Model<BrokerageServiceModel> {
  // not available, available, purchased ...
  public status: SERVICE_STATUS = 0;

  // calc amount
  public amount = 0;

  public documentUrl = '';

  public createdAt = '';

  public constructor(data?: Partial<BrokerageServiceModel>) {
    super();
    this.init(data);
  }
}
