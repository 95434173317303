import React from 'react';
import classNames from 'classnames';

export function createStyledElementBase<T extends React.FC<any> | React.JSXElementConstructor<any>>(
  element: T,
  className: string,
  defaultProps?: Partial<React.ComponentProps<T>>,
): React.FC<React.ComponentProps<T>> {
  return ({ className: innerClassName, ...props }) => {
    return React.createElement(element, {
      className: classNames(className, innerClassName),
      ...defaultProps,
      ...props,
    });
  };
}
