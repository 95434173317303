import React, { Component } from 'react';

export class ErrorBoundary extends Component {
  state = { hasError: false };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.error(error, info);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    return hasError ? <h1>Что-то пошло не так</h1> : children;
  }
}
