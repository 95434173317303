import React from 'react';

const Rating = () => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M5 5v14h14V5H5zm14-2c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h14zM9 17v-7H7v7h2zm4 0V7h-2v10h2zm4 0v-4h-2v4h2z" />
    </svg>
  );
};

export default Rating;
