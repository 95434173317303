import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Laptop, OnlyTablet } from 'const/media';
import ClientStore from 'store/Client';
import * as urls from 'const/urls';
import withErrorBoundary from 'hocs/withErrorBoundary';
import { CreditCardList } from 'modules/common/CreditCardList';
import { Aside, Block, Title, Link } from './styled';
import { User } from './components/User';
import { Rating } from './components/Rating';
import { LeftPanelTablet } from './LeftPanelTablet';
import { LoanInfo } from './containers/LoanInfo';

@withErrorBoundary
@observer
export class LeftPanel extends Component {
  private clientStore = ClientStore;

  render() {
    const { clientStore } = this;
    const { rateIsHidden } = clientStore;
    const rate = clientStore.getValue('creditRating').get();

    return (
      <>
        <OnlyTablet>
          <LeftPanelTablet />
        </OnlyTablet>
        <Laptop>
          <Aside>
            <Block>
              <User
                img={clientStore.getValue('photo').get()}
                badge={clientStore.getValue('bonuses').get()}
                name={clientStore.name}
                verified={clientStore.getValue('verified').get()}
                clientId={clientStore.identificationCode}
              />
            </Block>

            <Block>
              <Link to={urls.CREDIT_RATING} data-qa="leftPanel-creditRating-link">
                <Rating rate={rate} isEmpty={rateIsHidden} />
              </Link>
            </Block>

            <Block>
              <LoanInfo />
            </Block>

            <Block>
              <Title>Ваши карты</Title>
              <CreditCardList />
            </Block>
          </Aside>
        </Laptop>
      </>
    );
  }
}
