import React from 'react';

const Bonus = () => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-5.339L8.6 16.5l.78-3.658L6.5 10.32l3.88-.422L12 6.5l1.62 3.398 3.88.422-2.88 2.522.78 3.658-3.4-1.839z" />
    </svg>
  );
};

export default Bonus;
