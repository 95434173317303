import { useState, useEffect, RefObject } from 'react';

/**
 * Хук, который определяет, находится ли нужный блок во Viewport.
 * @param ref ссылка на узел DOM.
 * @returns {boolean} если `true`, то нужный объект находится во Viewport.
 */
export const useOnScreen = (ref: RefObject<HTMLElement>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), {
    threshold: 1.0,
  });

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [observer, ref]);

  return isIntersecting;
};
