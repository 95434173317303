import React, { ComponentPropsWithoutRef, FC, useState, useRef, useEffect } from 'react';
import { styled } from 'const/stitches';
import { Button } from 'ui/buttons/Button';
import MobxForm from 'modules/MobxForm';
import { observer } from 'mobx-react';

import { Service as MetricService, useOnScreen } from 'modules/common/MetricService';

import { QuestionsStore } from '../stores/QuestionsStore';

import { RadioButton } from './RadioButton';

const Title = styled('h2', {
  margin: 0,
  padding: 0,
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',

  '@mediaMobileAndLess': {
    fontSize: '18px',
  },
});

const Hint = styled('p', {
  margin: '20px 0 0',
  padding: 0,
  color: '#787891',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',

  '@mediaMobileAndLess': {
    margin: '16px 0 0',
  },
});

const List = styled('div', {
  marginBottom: '20px',
});

const ListItem = styled('div', {
  padding: '20px 0',

  '&:not(:first-child)': {
    borderTop: 'solid 1px #DADAE0',
  },
});

const ListItemTitle = styled('h3', {
  margin: 0,
  padding: 0,
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',

  '@mediaMobileAndLess': {
    fontSize: '16px',
  },
});

const ListItemGrid = styled('div', {
  marginTop: '20px',
  display: 'flex',
  gap: '24px',
});

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<'div'>;

/**
 * Контрольные вопросы.
 */
export const Questions: FC<Props> = observer(({ ...props }) => {
  const title = 'Заявка одобрена!';
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [isSentMetric, setIsSentMetric] = useState(false);

  useEffect(() => {
    if (isVisible && !isSentMetric) {
      setIsSentMetric(true);

      MetricService.onBannerShown(title);
    }
  }, [isVisible, isSentMetric]);

  const { form } = QuestionsStore.getInstance();

  const handleSubmit = () => {
    QuestionsStore.getInstance().setIsQuestionsSubmitted(true);
  };

  return (
    <div ref={ref} {...props}>
      <Title>{title}</Title>
      <Hint>
        Пожалуйста, ответьте на 3 вопроса. Так мы сможем минимизировать риск финансового
        мошенничества и убедиться, что настоящий заём получаете именно Вы, а не третьи лица
      </Hint>
      <MobxForm formState={form} onSubmit={handleSubmit}>
        <List>
          <ListItem>
            <ListItemTitle>{form.$('question-1').label}</ListItemTitle>
            <ListItemGrid>
              <RadioButton
                field={form.$('question-1')}
                value="Да"
                data-qa="questions-question1-radioButton-yes"
              />
              <RadioButton
                field={form.$('question-1')}
                value="Нет"
                data-qa="questions-question1-radioButton-no"
              />
              <RadioButton
                field={form.$('question-1')}
                value="Не помню"
                data-qa="questions-question1-radioButton-iDoNotRemember"
              />
            </ListItemGrid>
          </ListItem>
          <ListItem>
            <ListItemTitle>{form.$('question-2').label}</ListItemTitle>
            <ListItemGrid>
              <RadioButton
                field={form.$('question-2')}
                value="Да"
                data-qa="questions-question2-radioButton-yes"
              />
              <RadioButton
                field={form.$('question-2')}
                value="Нет"
                data-qa="questions-question2-radioButton-no"
              />
              <RadioButton
                field={form.$('question-2')}
                value="Не помню"
                data-qa="questions-question2-radioButton-iDoNotRemember"
              />
            </ListItemGrid>
          </ListItem>
          <ListItem>
            <ListItemTitle>{form.$('question-3').label}</ListItemTitle>
            <ListItemGrid>
              <RadioButton
                field={form.$('question-3')}
                value="Да"
                data-qa="questions-question3-radioButton-yes"
              />
              <RadioButton
                field={form.$('question-3')}
                value="Нет"
                data-qa="questions-question3-radioButton-no"
              />
              <RadioButton
                field={form.$('question-3')}
                value="Не помню"
                data-qa="questions-question3-radioButton-iDoNotRemember"
              />
            </ListItemGrid>
          </ListItem>
        </List>
        <Button type="submit" onClick={() => {}} data-qa="questions-buttonSubmit">
          Продолжить
        </Button>
      </MobxForm>
    </div>
  );
});
