import { MyTargetNotInitializedError } from '../errors';

declare global {
  interface Window {
    _tmr?;
  }
}

/**
 * Сервис для работы с API MyTarget.
 */
export class Service {
  /**
   * Коллекция событий GTM.
   */
  private static events: any[];

  /**
   * Идентификатор счётчика метрики.
   */
  private static id?: number = undefined;

  /**
   * Инициализирует глобальный объект событий метрики.
   */
  private static initializeEvents() {
    if (typeof window === 'undefined') {
      this.events = [];
      return;
    }

    // eslint-disable-next-line no-underscore-dangle
    window._tmr = window._tmr || [];
    // eslint-disable-next-line no-underscore-dangle
    this.events = window._tmr;
  }

  /**
   * Инициализирует сервис.
   * @param id Идентификатор счётчика mytarget.
   */
  public static initialize(id?: number) {
    if (this.events != null) {
      return;
    }

    this.initializeEvents();
    this.id = id;
  }

  /**
   * Логгирует сущность, отправленную в метрику.
   * @param value Значение.
   */
  private static log(value: any) {
    if (process.env.NODE_ENV === 'production') {
      return;
    }

    // eslint-disable-next-line no-console
    console.debug('mytarget_event', value);
  }

  /**
   * Записывает событие в метрику.
   * @param event Объект события или его название.
   * @param args Параметры события (указываются, если событие передано как
   * список параметров, а не объект).
   */
  public static push(event: string) {
    if (this.events == null) {
      throw new MyTargetNotInitializedError();
    }

    const finalEvent: Record<string, any> = {
      type: 'reachGoal',
      goal: event,
    };

    if (this.id) {
      finalEvent.id = this.id;
      this.events.push(finalEvent);
    }

    this.log(event);
  }
}
