import React from 'react';

import Loading from 'ui/Loading';

import { Wrap, Text } from './styled';

export const ApplicationCheckingLoader: React.FC = () => {
  return (
    <Wrap>
      <Loading />
      <Text>
        Мы обрабатываем ваше обращение на перерасчёт. Пожалуйста, подождите. Это займёт не более 15
        секунд.
      </Text>
    </Wrap>
  );
};
