import React, { FC } from 'react';

import { Wrap, Dot } from './styled';

export const Loading: FC<React.ComponentProps<typeof Wrap>> = (props) => (
  <Wrap {...props}>
    <Dot />
    <Dot />
    <Dot />
  </Wrap>
);
