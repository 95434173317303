export const TAB_NEW_CLIENT = 1;
export const TAB_REPEAT_CLIENT = 2;
export const TAB_PREMIUM_CLIENT = 3;

/**
 * @deprecated not used now
 */
export const TAB_CAR_MORTGAGE = 4;

export enum PERIOD_TYPE {
  DAY = 1,
  WEEK = 2,
  MONTH = 3,
}

// Combined calculator
export const PARAMETER_TYPE_AMOUNT = 1;
export const PARAMETER_TYPE_PERIOD = 2;

export const TARIFF_PDL = 2;
export const TARIFF_INSTALL = 3;

// Кол-во дней в месяце, для слияния дней и месяцев в один слайдер
export const DAYS_IN_MONTH = 30;
