import Api from 'services/Api';

type Request = {
  // todo check type
  social;
};

type Response = true;

export function addSocial(data: Request): Promise<Response> {
  return Api.getInstance().post('client.addSocial', data);
}
