import React, { ComponentPropsWithoutRef, FC } from 'react';
import cn from 'classnames';

import { Apollo } from 'ui/svg-icons/Logos/Apollo';

import style from './ApolloLogo.scss';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<'a'>;

/**
 * Отображает логотип "Аполлон-Займ" в шапке сайта в разделе оформления заявки
 * на займ до авторизации.
 */
export const ApolloLogo: FC<Props> = ({ className, ...props }) => (
  <a {...props} className={cn(style.root, className)} href="https://apollon-zaym.ru/">
    <Apollo />
  </a>
);
