import React from 'react';

const Logout = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 20" {...props}>
      <path d="M16 0a2 2 0 0 1 2 2v2h-2V2H2v16h14v-2h2v2a2 2 0 0 1-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h14zm-2.815 15L18 10l-4.815-5-1.358 1.41L14.312 9H5v2h9.312l-2.485 2.59L13.185 15z" />
    </svg>
  );
};

export default Logout;
