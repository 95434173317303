import Api from 'services/Api';

type Request = {
  currentPassword: string;
  newPassword: string;
};

type Response = true;

export function changePassword(data: Request): Promise<Response> {
  return Api.getInstance().post('client.changePassword', data);
}
