import React from 'react';

const Exit = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 2a2 2 0 0 1 2 2v2h-2V4H5v16h14v-2h2v2a2 2 0 0 1-2 2H5c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h14zm-2.815 15L21 12l-4.815-5-1.358 1.41L17.312 11H8v2h9.312l-2.485 2.59L16.185 17z" />
    </svg>
  );
};

export default Exit;
